import React from 'react';
import { FaCopy, FaRegPaperPlane } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIdQuiz,
  selectQuizPublicado,
  selectSlugQuiz,
  selectSubdomainQuiz,
} from '../../store/quizSlice';
import { toggleStatusPublicarQuiz } from '../../utils/publicarQuiz';
import { getUrl } from '../../utils/urlResponderQuiz';
import { Container } from './styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { selectDominioStatusCname, selectDominioUrlVisualizarQuiz } from '../../store/dominioSlice';

function Fim() {
  const dispatch = useDispatch();
  const publicado = useSelector(selectQuizPublicado);
  const quizId = useSelector(selectIdQuiz);
  const quizSlug = useSelector(selectSlugQuiz);
  const subdomain = useSelector(selectSubdomainQuiz).toLowerCase();
  const urlVisualizar = getUrl(subdomain, quizSlug);
  const dominioStatusCname = useSelector(selectDominioStatusCname);
  const dominioUrlVisualizarQuiz =
    useSelector(selectDominioUrlVisualizarQuiz) + '/' + quizSlug;

  const showNotification = (text) => {
    toast.dark(text, {
      autoClose: 2000,
      hideProgressBar: true,
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  return (
    <Container className="col-12 col-lg-11 text-center">
      {!publicado ? (
        <>
          <h1 className="pt-5 fw-bold" style={{ color: 'var(--roxo)' }}>
            Parabéns!
          </h1>
          <br />

          <h2>
            Você finalizou a criação e configuração
            <br />
            do seu Quiz.
          </h2>
          <br />
          <br />
          <br />
          <p>
            Para que ele comece a funcionar, clique no botão
            <br /> publicar logo abaixo.
          </p>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-default d-flex align-items-center pe-4 ps-4"
              onClick={() =>
                toggleStatusPublicarQuiz(quizId, publicado, dispatch)
              }
            >
              <FaRegPaperPlane
                className="me-2"
                color="var(--preto)"
                size={14}
              />
              <span>{publicado ? 'Despublicar' : 'Publicar'}</span>
            </button>
          </div>
          <br />
          <br />
          <p className="info">
            Depois de publicado, você poderá voltar e editar o seu quiz
            <br />
            quantas vezes quizer.
          </p>

          <p className="info">
            <span>ATENÇÃO:</span> Lembre-se, que se você quiser mudar o endereço
            do
            <br />
            seu quiz, o endereço antigo não será mais válido.
          </p>
        </>
      ) : (
        <>
          <img
            src="https://assetsus1.quizclass.com.br/assets/images/medallion-burst-animation-unscreen.gif"
            alt="Medalha quiz finalizado"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
          <h1 className="fw-bold" style={{ color: 'var(--roxo)' }}>
            Uhuuu, tudo certo!{' '}
          </h1>
          <h2>Seu Quiz está pronto.</h2>
          <br />

          <div className="d-flex justify-content-center">
            <div
              className="d-flex flex-row align-items-center form-control form-default pt-0 pb-0 pe-5"
              style={{
                position: 'relative',
                backgroundColor: '#E9ECEF',
                maxWidth: 400,
              }}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                title={dominioStatusCname ? dominioUrlVisualizarQuiz : urlVisualizar}
              >
                {dominioStatusCname ? dominioUrlVisualizarQuiz : urlVisualizar}
              </span>

              <CopyToClipboard
                text={dominioStatusCname ? dominioUrlVisualizarQuiz : urlVisualizar}
                onCopy={() => showNotification('Link copiado!')}
              >
                <div
                  className="p-2 border-radius btn"
                  title="Copiar"
                  style={{
                    background: '#D9D9D9',
                    position: 'absolute',
                    top: 5,
                    right: 5,
                  }}
                >
                  <FaCopy size={24} color={'#FFFFFF'} />
                </div>
              </CopyToClipboard>
            </div>
          </div>

          <br />
          <br />
          <br />
          <p className="info">
            <span>ATENÇÃO:</span> Lembre-se, que se você quiser mudar o endereço
            do
            <br /> seu quiz, o endereço antigo não será mais válido.
          </p>
        </>
      )}
    </Container>
  );
}

export default Fim;
