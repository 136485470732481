import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeOpcao,
  selectOpcoesResposta,
  setOpcaoRespostaCorreta,
  selectQuestaoId,
  setOpcoesQuestao,
  setOpcaoImagem,
} from '../../../../store/questaoSlice';
import { selectIdQuiz } from '../../../../store/quizSlice';
import MultiplaEscolha from './MultiplaEscolha';
import MultiplaEscolhaImagem from './MultiplaEscolhaImagem';
import VF from './VF';
import TextoLongo from './TextoLongo';
import RespostaVoz from './RespostaVoz';
import Ordenar from './Ordenar';
import { Container } from './styles';
import {
  deleteOpcao,
  postOpcao,
  putOpcao,
} from '../../../../services/opcaoService';
import UnicaEscolha from './UnicaEscolha';
import TextoCurto from './TextoCurto';
import Data from './Data';
import Numero from './Numero';
import ReordenarItens from '../../../../components/ReordenarItens';
import { putQuestao } from '../../../../services/questaoService';
import { toast } from 'react-toastify';
import { uploadFile } from '../../../../services/uploadService';
import UnicaEscolhaImagem from './UnicaEscolhaImagem';
import MatrizSelecaoUnica from './MatrizSelecaoUnica';
import MatrizSelecaoMultipla from './MatrizSelecaoMultipla';

function Opcoes({ tipoQuestao }) {
  const opcoes = useSelector(selectOpcoesResposta);
  const dispatch = useDispatch();
  const questaoId = useSelector(selectQuestaoId);
  const questao = useSelector((state) => state.questao);
  const quizId = useSelector(selectIdQuiz);

  const saveOpcao = (indexOpcao, opcaoId, dataOpcao = null) => {
    const data = dataOpcao || opcoes[indexOpcao];

    if (!opcaoId) {
      // dispatch(postOpcao(questaoId, data, indexOpcao));
    } else {
      putOpcao(opcaoId, data);
    }
  };

  const saveOpcaoRespostaCorreta = (indexOpcao, resposta_correta) => {
    dispatch(
      setOpcaoRespostaCorreta({
        index: indexOpcao,
        resposta_correta,
      })
    );
    let opcao = [...opcoes][indexOpcao];

    opcao = { ...opcao, resposta_correta };

    if (!opcao.id) {
      // dispatch(postOpcao(questaoId, opcao, indexOpcao));
    } else {
      putOpcao(opcao.id, opcao);
    }
  };

  const excluirOpcao = (indexOpcao, opcaoId) => {
    if (opcaoId) deleteOpcao(opcaoId);

    dispatch(removeOpcao(indexOpcao));
  };

  const setNovaOrdemOpcoes = (novaOrdemOpcoes) => {
    const newOpcoes = novaOrdemOpcoes.map((opcao, index) => {
      return { ...opcao, numero_opcao: index + 1 };
    });

    dispatch(setOpcoesQuestao(newOpcoes));
    putQuestao(questao.id, { ...questao, opcoes: newOpcoes });
  };

  const upload = (e, index) => {
    const file = e.target.files[0];
    if (!file) return;

    let opcao = [...opcoes][index];

    toast.promise(
      uploadFile(quizId, file).then((url) => {
        dispatch(setOpcaoImagem({ index, url }));

        opcao = { ...opcao, imagem: url };
        saveOpcao(index, opcao.id, opcao);
      }),
      {
        pending: 'Carregando imagem...',
        success: {
          render({ data }) {
            return 'Caregada com sucesso!';
          },
        },
        error: {
          render({ data: message }) {
            return message;
          },
        },
      }
    );
  };

  const layoutsTipoQuestao = {
    MULTIPLA_ESCOLHA: (opcao, index) => (
      <MultiplaEscolha
        key={opcao.id}
        opcao={opcao}
        index={index}
        saveOpcao={saveOpcao}
        excluirOpcao={excluirOpcao}
        saveOpcaoRespostaCorreta={saveOpcaoRespostaCorreta}
      />
    ),
    MULTIPLA_ESCOLHA_IMAGEM: (opcao, index) => (
      <MultiplaEscolhaImagem
        key={opcao.id}
        opcao={opcao}
        index={index}
        saveOpcao={saveOpcao}
        excluirOpcao={excluirOpcao}
        saveOpcaoRespostaCorreta={saveOpcaoRespostaCorreta}
        upload={upload}
      />
    ),
    UNICA_ESCOLHA: (opcao, index) => (
      <UnicaEscolha
        key={opcao.id}
        opcao={opcao}
        index={index}
        saveOpcao={saveOpcao}
        excluirOpcao={excluirOpcao}
        saveOpcaoRespostaCorreta={saveOpcaoRespostaCorreta}
      />
    ),
    UNICA_ESCOLHA_IMAGEM: (opcao, index) => (
      <UnicaEscolhaImagem
        key={opcao.id}
        opcao={opcao}
        index={index}
        saveOpcao={saveOpcao}
        excluirOpcao={excluirOpcao}
        saveOpcaoRespostaCorreta={saveOpcaoRespostaCorreta}
        upload={upload}
      />
    ),
    V_F: (opcao, index) => (
      <VF
        key={opcao.id}
        opcao={opcao}
        index={index}
        saveOpcao={saveOpcao}
        excluirOpcao={excluirOpcao}
        saveOpcaoRespostaCorreta={saveOpcaoRespostaCorreta}
      />
    ),
    ABERTA: (opcao, index) => (
      <TextoLongo
        key={opcao.id}
        opcao={opcao}
        index={index}
        saveOpcao={saveOpcao}
        excluirOpcao={excluirOpcao}
      />
    ),
    TEXTO_CURTO: (opcao, index) => (
      <TextoCurto
        key={opcao.id}
        opcao={opcao}
        index={index}
        saveOpcao={saveOpcao}
        excluirOpcao={excluirOpcao}
      />
    ),    
    TEXTO_LONGO: (opcao, index) => (
      <TextoLongo
        key={opcao.id}
        opcao={opcao}
        index={index}
        saveOpcao={saveOpcao}
        excluirOpcao={excluirOpcao}
      />
    ),
    RESPOSTA_VOZ: (opcao, index) => (
      <RespostaVoz
        key={opcao.id}
        opcao={opcao}
        index={index}
        saveOpcao={saveOpcao}
        excluirOpcao={excluirOpcao}
      />
    ),
    ORDENAR: (opcao, index) => (
      <Ordenar
        key={opcao.id}
        opcao={opcao}
        index={index}
        FsaveOpcao={saveOpcao}
        excluirOpcao={excluirOpcao}
      />
    ),
    DATA: (opcao, index) => (
      <Data
        key={opcao.id}
        opcao={opcao}
        index={index}
        saveOpcao={saveOpcao}
        excluirOpcao={excluirOpcao}
      />
    ),
    NUMERO: (opcao, index) => (
      <Numero
        key={opcao.id}
        opcao={opcao}
        index={index}
        saveOpcao={saveOpcao}
        excluirOpcao={excluirOpcao}
      />
    ),
    MATRIZ_SELECAO_UNICA: (opcao, index) => (
      <MatrizSelecaoUnica
        key={opcao.id}
        opcao={opcao}
        index={index}
        saveOpcao={saveOpcao}
        excluirOpcao={excluirOpcao}
      />
    ),
    MATRIZ_SELECAO_MULTIPLA: (opcao, index) => (
      <MatrizSelecaoMultipla
        key={opcao.id}
        opcao={opcao}
        index={index}
        saveOpcao={saveOpcao}
        excluirOpcao={excluirOpcao}
      />
    ),
  };

  if (layoutsTipoQuestao[tipoQuestao] && opcoes.length > 0)
    return (
      <Container className="d-flex flex-column gap-4">
        <ReordenarItens itens={opcoes} setItens={setNovaOrdemOpcoes}>
          {opcoes.map((opcao, index) =>
            layoutsTipoQuestao[tipoQuestao](opcao, index)
          )}
        </ReordenarItens>
      </Container>
    );

  return '';
}

export default Opcoes;
