import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'rdstation',
  initialState: {
    conta_selecionada: '',
    contas: [],
    evento_conversao: ''
  },
  reducers: {
    setContas(state, { payload }) {
      return {...state, contas: payload}
    },
    setContaSelected(state, { payload }) {
      return {...state, conta_selecionada: payload}
    },
    setEvento(state, { payload }) {
      return {...state, evento_conversao: payload}
    }
  },
});

export const { setContas, setContaSelected, setEvento } = slice.actions;

export const selectContasRd = (state) => state.rdstation.contas;
export const contaRdSelecionada = (state) => state.rdstation.conta_selecionada;
export const eventoConversao = (state) => state.rdstation.evento_conversao;

export default slice.reducer;
