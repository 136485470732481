import styled from 'styled-components';

export const Info = styled.p`
  font-style: normal;
  color: #9c9c9c;
  margin: 0;
`;

export const Tipo = styled.button`
  border: 1px solid var(--cinza);

  /* &:hover {
    background-color: var(--cinza);
  } */
`;
