import { Routes, Route, Navigate } from 'react-router-dom';
import Personalizacao from '../pages/Personalizacao';
import Questao from '../pages/Questao';
import Layout from './../layout';
import Apresentacao from './../pages/Apresentacao';
import Integracao from './../pages/Integracao';
import RDStation from './../pages/Integracao/RDStation';
import Resultado from '../pages/Resultado';
import Configuracao from '../pages/Configuracao';
import Compartilhar from '../pages/Compartilhar';
import Qualificacao from '../pages/Qualificacao';
import Fim from '../pages/Fim';
import GoogleTagManager from './../pages/Integracao/GoogleTagManager';
import FacebookPixel from './../pages/Integracao/FacebookPixel';
import GoogleAnalytics from './../pages/Integracao/GoogleAnalytics';
import Zapier from '../pages/Integracao/Zapier';
import ActiveCampaign from '../pages/Integracao/ActiveCampaign';
import EditorFluxos from '../pages/EditorFluxos';

export default function AllRoutes({ quizId }) {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          path="/app/quizzes/:quizId/apresentacao"
          element={<Apresentacao />}
        />
        <Route
          path="/app/quizzes/:quizId/editor-fluxo"
          element={<EditorFluxos />}
        />
        <Route
          path="/app/quizzes/:quizId/questoes/:questaoId"
          element={<Questao />}
        />
        <Route
          path="/app/quizzes/:quizId/resultados/:resultadoId"
          element={<Resultado />}
        />
        <Route
          path="/app/quizzes/:quizId/configuracao"
          element={<Configuracao />}
        />
        <Route
          path="/app/quizzes/:quizId/integracoes"
          element={<Integracao />}
        />
        <Route
          path="/app/quizzes/:quizId/integracoes/rdstation/configura"
          element={<RDStation />}
        />
        <Route
          path="/app/quizzes/:quizId/integracoes/rdstation/new"
          element={<Integracao />}
        />
        <Route
          path="/app/quizzes/:quizId/integracoes/active-campaign"
          element={<ActiveCampaign />}
        />
        <Route
          path="/app/quizzes/:quizId/integracoes/google-tag-manager"
          element={<GoogleTagManager />}
        />
        <Route
          path="/app/quizzes/:quizId/integracoes/facebook-pixel"
          element={<FacebookPixel />}
        />
        <Route
          path="/app/quizzes/:quizId/integracoes/google-analytics"
          element={<GoogleAnalytics />}
        />
        <Route
          path="/app/quizzes/:quizId/integracoes/zapier"
          element={<Zapier />}
        />
        {/* <Route path='/app/quizzes/:quizId/email' element={<h1>email</h1>} /> */}
        <Route
          path="/app/quizzes/:quizId/compartilhar"
          element={<Compartilhar />}
        />
        <Route
          path="/app/quizzes/:quizId/qualificacao"
          element={<Qualificacao />}
        />
        <Route
          path="/app/quizzes/:quizId/template/personalizacao"
          element={<Personalizacao />}
        />
        <Route path="/app/quizzes/:quizId/fim" element={<Fim />} />
      </Route>
      {!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? (
        <Route
          path="*"
          element={
            <Navigate to={`/app/quizzes/${quizId}/apresentacao`} replace />
          }
        />
      ) : (
        <Route
          path="*"
          element={
            <main className="text-center pt-5" style={{ padding: '1rem' }}>
              <h1>Página não encontrada</h1>
              <br />
              <h2>Não conseguimos encontrar esta página</h2>
              <br />
              <p style={{ fontSize: '1rem' }}>
                Verifique atentamente a sua solicitação, caso exista algum erro,
                faça a correção e tente novamente.
              </p>
            </main>
          }
        />
      )}
    </Routes>
  );
}
