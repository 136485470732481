import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Container, Opcao, Footer, ButtonFooter, QtdQuestoes } from './styles';

export default function Questoes() {
  const personalizacao = useSelector((state) => state.personalizacao);

  return (
    <Container
      backgroundImage={personalizacao.background_image_questao}
      backgroundSize={personalizacao.background_size_questao}
    >
      <div className="container content">
        <div className="mb-3">
          <div className="d-flex flex-row align-items-center">
            <div className="d-block w-100">
              <ProgressBar
                className="bg-secondary"
                style={{ borderRadius: 20 }}
              >
                <ProgressBar
                  className="bg-primary"
                  now={45}
                  style={{ borderRadius: 20 }}
                />
              </ProgressBar>
            </div>

            <QtdQuestoes className="text-nowrap ms-2">4/15 35%</QtdQuestoes>
          </div>
        </div>

        <p style={{ fontSize: '1rem' }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
          tristique sem id neque vulputate elementum. Aenean quis arcu libero.
          Nulla sit amet pellentesque nulla. Proin eu semper metus. Donec
          elementum turpis quis sodales placerat. Nam condimentum malesuada
          suscipit. Sed commodo lacinia velit, ornare laoreet velit elementum
          convallis. In justo eros, ultrices et sollicitudin nec, faucibus
          tempor massa.
        </p>

        <Opcao className="bg-secondary text-secondary border-radius">
          <span className="indice-opcao">A</span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Opcao>
        <Opcao className="bg-primary text-primary border-radius">
          <span className="indice-opcao">B</span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Opcao>
        <Opcao className="bg-secondary text-secondary border-radius">
          <span className="indice-opcao">C</span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Opcao>
      </div>

      <Footer className="bg-secondary">
        <div className="container d-flex justify-content-between">
          <ButtonFooter className="btn shadow-default bg-default text-default ps-4 pe-4 ps-md-5 pe-md-5">
            PULAR
          </ButtonFooter>
          <ButtonFooter className="btn shadow-default bg-primary text-primary ps-md-5 pe-md-5">
            CONTINUAR
          </ButtonFooter>
        </div>
      </Footer>
    </Container>
  );
}
