import React from 'react'

export default function Email({campo, value, setValue, quandoObterDados}) {
    return (
        <div>
            <label className="label-nome-campos">{campo.label}: </label>
            <input 
                type="email"  
                name={campo.nome}
                className="form-control form-default mb-2"
                value={value}
                placeholder={quandoObterDados === 'FIM' ? campo.label : ''}
                onChange={e => setValue(e.target.value)}
                required
            />
        </div>
    )
}
