import Header from '@editorjs/header';

export default class MyHeader extends Header {
  /**
   * Return Tool's view
   * @returns {HTMLHeadingElement}
   * @public
   */
  render() {
    const extrawrapper = document.createElement('div');
    extrawrapper.classList.add('content');
    extrawrapper.appendChild(this._element);

    return extrawrapper;
  }

  static get sanitize() {
    return {
      level: {},
      text: {
        i: true,
        b: true,
        u: true,
        span: true,
      },
    };
  }

  save(blockContent) {
    const header = blockContent.getElementsByClassName('ce-header')[0];

    const data = {
      text: header.innerHTML,
      level: Number(header.tagName[header.tagName.length - 1]),
    };

    return data;
  }
}
