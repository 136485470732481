import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 500px;
  padding: 15px;
  border-radius: 10px;
  background-image: ${(props) => `url(${props.backgroundImage}) !important`};
  background-size: ${(props) => `${props.backgroundSize} !important`};
  background-repeat: no-repeat !important;
  background-position: center !important;
`;