import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Tour from 'reactour';
import { isMobile } from 'react-device-detect';

function Onboarding() {
  const navigate = useNavigate();
  const { quizId } = useParams();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const navChangedToApresentacao = useRef(false);

  const handleCloseTour = () => {
    setIsTourOpen(false);
    localStorage.setItem('tour_play', true);
  };

  const steps = [
    {
      action: () => {
        if (!navChangedToApresentacao.current) {
          navigate(`/app/quizzes/${quizId}/apresentacao`);
          navChangedToApresentacao.current = true;
        }
      },
      content:
        'Bem-vindo(a). Esta é a página inicial do seu editor de quiz, siga este breve tour para entender como ele funciona.',
    },
    {
      selector: '.step-1-nomeQuiz',
      content:
        'Aqui é possivel alterar o nome do quiz. Fique atento que uma edição no nome do quiz resultara em um endereço diferente.',
    },
    {
      selector: '.step-2-editorjs',
      content:
        'Aqui está o editor da apresentação, você pode personlizar esté conteúdo adicionando novos blocos, editando textos e muito mais.',
    },
    {
      selector: '.step-3-addQuestao',
      content:
        'Neste botão você pode adicionar novas questões para o seu quiz.',
    },
    {
      selector: '.step-4-addResultado',
      content: 'Aqui você adiciona resultados para o seu quiz.',
    },
    {
      selector: '.step-5-publicar',
      content:
        'Após finalizar as questões, resultados e demais configurações você deve publicar o seu quiz.',
    },
  ];

  useEffect(() => {
    const showTourPlay = localStorage.getItem('tour_play');
    if (!showTourPlay && !isMobile) {
      setIsTourOpen(true);
    }
  }, []);

  return (
    <Tour
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={handleCloseTour}
      disableKeyboardNavigation={['esc']}
      closeWithMask={false}
    />
  );
}

export default Onboarding;
