import React, { useEffect, useRef, memo, useState } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';
import { Container } from './styles';
import ImageTool from '@editorjs/image';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../../services/uploadService';
import {
  selectIdQuiz,
  selectNaturezaQuiz,
  selectQuizFonte,
} from '../../store/quizSlice';
import BlocoNota from './Plugins/BlocoNota';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import BlocoLogicoTune from './BlockTunes/BlocoLogicoTune';
import { toggleShowModalConfigBlocoLogico } from '../../store/showSlice';
import ModalConfigBlocoLogico from '../Modal/ConfigBlocoLogico';
import BlocoCompartilharResultado from './Plugins/BlocoCompartilharResultado';
import BlocoWhatsApp from './Plugins/BlocoWhatsApp';
import BlocoGabarito from './Plugins/BlocoGabarito';
import BlocoRedirecionamento from './Plugins/BlocoRedirecionamento';
import RawHtml from '@editorjs/raw';
import MyHeader from './Plugins/MyHeader';
import FontSize from 'editorjs-inline-font-size-tool';
import Tribute from 'tributejs';
import getCacheUrl from '../../utils/getCacheUrl';

const EditorComponent = ({
  id,
  blocks,
  handleBlocks = () => {},
  mentions = [],
  isPageResultado = false
}) => {
  const ejInstance = useRef();
  const personalizacao = useSelector((state) => state.personalizacao);
  const idQuiz = useSelector(selectIdQuiz);
  const naturezaQuiz = useSelector(selectNaturezaQuiz);
  const dispatch = useDispatch();
  const [currentBlockIndex, setCurrentBlockIndex] = useState(0);
  const fonte = useSelector(selectQuizFonte);

  useEffect(() => {
    if (!ejInstance.current) {
      ejInstance.current = initEditor();
    }

    return async () => {
      if (ejInstance.current) {
        await ejInstance.current.isReady;
        ejInstance.current.destroy();
      }

      ejInstance.current = null;
    };
  }, []);

  const setTributeEmElementosContenteditable = () => {
    const editor = document.getElementById(`editorjs_${id}`);

    const elementosEditaveis = editor.querySelectorAll(
      '[contenteditable="true"]'
    );

    elementosEditaveis.forEach((elemento) => {
      const tribute = new Tribute({
        collection: [
          {
            trigger: '@',
            values: mentions.map((mention) => ({
              key: mention,
              value: mention,
            })),
          },
        ],
      });
      tribute.attach(elemento);
    });

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        // Verifica se há adições de nós na div alvo
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          // Percorre os nós adicionados
          mutation.addedNodes.forEach((addedNode) => {
            // Verifica se o nó adicionado é um elemento HTML
            if (addedNode instanceof HTMLElement) {
              // Procura todos os elementos com contenteditable="true" dentro da div
              const elementosEditaveis = addedNode.querySelectorAll(
                '[contenteditable="true"]'
              );
              // Percorre os elementos encontrados
              elementosEditaveis.forEach((elemento) => {
                // Faça o que precisar com cada elemento editável
                const tribute = new Tribute({
                  collection: [
                    {
                      trigger: '@',
                      values: mentions.map((mention) => ({
                        key: mention,
                        value: mention,
                      })),
                    },
                  ],
                });
                tribute.attach(elemento);
              });
            }
          });
        }
      });
    });

    // Configura a opção para observar adições de nós
    const observerConfig = {
      childList: true,
      subtree: true, // Observa nós em todos os níveis da subárvore
    };

    observer.observe(editor, observerConfig);
  };

  const addListenerBtnEditarBlocoLogico = async () => {
    const currentBlocks = await ejInstance.current?.saver.save();
    const elements = document.getElementsByClassName('btn-editar-logica');
    const blockIndex = ejInstance.current?.blocks.getCurrentBlockIndex();

    if (blockIndex !== '' && blockIndex !== undefined && blockIndex >= 0) {
      const blockId = ejInstance.current?.blocks.getBlockByIndex(blockIndex).id;

      const currentIndex = currentBlocks.blocks
        .map((block) => block.id)
        .indexOf(blockId);

      setCurrentBlockIndex(currentIndex);
    }

    if (elements.length > 0)
      elements[0].onclick = (e) => {
        dispatch(toggleShowModalConfigBlocoLogico());
      };
  };

  const updateBlocoLogico = (blockIndex, questaoId, opcaoId) => {
    let updateBlocoLogico = JSON.parse(JSON.stringify(blocks));
    let blockData = blocks.blocks[blockIndex];
    let blockTune = blockData?.tunes;

    if (blockTune) {
      blockData = {
        ...blockData,
        tunes: {
          ...blockTune,
          blocoLogicoTune: {
            checked: true,
            questaoId: questaoId,
            opcaoId: opcaoId,
          },
        },
      };
    } else {
      blockData = {
        ...blockData,
        tunes: {
          blocoLogicoTune: {
            checked: true,
            questaoId: questaoId,
            opcaoId: opcaoId,
          },
        },
      };
    }

    updateBlocoLogico.blocks[blockIndex] = blockData;
    handleBlocks(updateBlocoLogico);
    ejInstance.current?.blocks.render(updateBlocoLogico);
  };

  const onChange = (api) => {
    (async () => {
      const content = await api.saver.save();
      handleBlocks(content);
    })();
  };

  /**
   * Upload file to the server and return an uploaded image data
   * @param {File} file - file selected from the device or pasted by drag-n-drop
   * @return {Promise.<{success, file: {url}}>}
   */
  async function uploadByFile(file) {
    return uploadFile(idQuiz, file).then((url) => {
      return {
        success: 1,
        file: {
          url: getCacheUrl(url),
        },
      };
    });
  }

  const initEditor = () => {
    const editor = new EditorJS({
      holder: `editorjs_${id}`,
      logLevel: 'ERROR',
      data: blocks,
      onReady: () => {
        // ejInstance.current = editor;
        const target = document.getElementsByClassName('ce-settings')[0];

        let observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.type === 'childList') {
              addListenerBtnEditarBlocoLogico();
            }
          });
        });
        let config = {
          childList: true,
          attributes: true,
          subtree: true,
          characterData: true,
        };

        observer.observe(target, config);
        if (mentions.length) setTributeEmElementosContenteditable();
        // observer.disconnect();
      },
      onChange,
      autofocus: true,
      tools: {
        fontSize: FontSize,
        header: {
          class: MyHeader,
          inlineToolbar: true,
          toolbox: {
            title: 'Titulo',
          },
          tunes: ['alignmentTune', 'blocoLogicoTune'],
        },
        paragraph: {
          class: Paragraph,
          toolbox: {
            title: 'Texto',
          },
          tunes: ['alignmentTune', 'blocoLogicoTune'],
        },
        list: {
          class: List,
          inlineToolbar: true,
          toolbox: {
            title: 'Lista',
          },
          tunes: ['alignmentTune', 'blocoLogicoTune'],
        },
        image: {
          class: ImageTool,
          tunes: ['alignmentTune', 'blocoLogicoTune'],

          toolbox: {
            title: 'Imagem',
          },
          config: {
            uploader: {
              uploadByFile,
            },
            // buttonContent: 'Selecione uma imagem'
          },
        },
        rawHtml: {
          class: RawHtml,
          tunes: ['blocoLogicoTune'],
          toolbox: {
            title: 'Bloco HTML',
          },
          config: {
            placeholder: 'Digite o código html',
          },
        },
        ...(isPageResultado
          ? {
              ...(naturezaQuiz === 'CERTO_ERRADO'
                ? {
                    blocoGabarito: {
                      class: BlocoGabarito,
                      toolbox: {
                        title: 'Gabarito',
                      },
                      tunes: ['alignmentTune', 'blocoLogicoTune'],
                    },
                  }
                : {}),
              ...(naturezaQuiz === 'PONTUACAO' ||
              naturezaQuiz === 'CERTO_ERRADO'
                ? {
                    blocoNota: {
                      class: BlocoNota,
                      toolbox: {
                        title: 'Bloco do tipo nota',
                      },
                      tunes: ['alignmentTune', 'blocoLogicoTune'],
                    },
                  }
                : {}),
              blocoCompartilharResultado: {
                class: BlocoCompartilharResultado,
                tunes: ['alignmentTune', 'blocoLogicoTune'],
              },
              blocoWhatsApp: {
                class: BlocoWhatsApp,
                tunes: ['alignmentTune', 'blocoLogicoTune'],
              },
              blocoRedirecionamento: {
                class: BlocoRedirecionamento,
                tunes: ['alignmentTune', 'blocoLogicoTune'],
              },
            }
          : {}),
        alignmentTune: {
          class: AlignmentTuneTool,
          config: {
            default: 'left',
            blocks: {
              image: 'center',
              blocoNota: 'center',
              blocoCompartilharResultado: 'center',
              blocoWhatsApp: 'center',
              blocoGabarito: 'center',
              blocoRedirecionamento: 'center',
            },
          },
        },
        blocoLogicoTune: {
          class: BlocoLogicoTune,
          config: {
            isPageResultado: isPageResultado,
          },
        },
      },
    });

    return editor;
  };

  return (
    <>
      <Container
        id={`editorjs_${id}`}
        className="step-2-editorjs"
        backgroundPadrao={personalizacao.cor_fundo_padrao}
        textoPadrao={personalizacao.cor_texto_padrao}
        textoSecundario={personalizacao.cor_texto_secundaria}
        backgroundSecundario={personalizacao.cor_fundo_secundaria}
        fonte={fonte}
      ></Container>
      <ModalConfigBlocoLogico
        indexBlockResultado={currentBlockIndex}
        block={blocks.blocks[currentBlockIndex]}
        updateBlocoLogico={updateBlocoLogico}
      />
    </>
  );
};

const Editor = memo(EditorComponent);

export default Editor;
