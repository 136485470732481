import React, { useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';

const DndBackend = isMobile ? TouchBackend : HTML5Backend;

function useDNDProviderElement(props) {
  const backend = useRef(DndBackend);

  if (!props.children) return null;

  return <DndProvider backend={backend.current}>{props.children}</DndProvider>;
}

export default function DragAndDrop(props) {
  const DNDElement = useDNDProviderElement(props);
  return <React.Fragment>{DNDElement}</React.Fragment>;
}
