import React from 'react'

export default function MultipleChoice({campo, value, setValue}) {

    return (
        <div className="form-group text-left mb-2">
            <div className="form-check">
                <input 
                    id={"check-box-"+campo.id}
                    type="checkbox"  
                    className="form-check-input"
                    name={campo.nome}
                    value={value}
                    onChange={e => setValue(e.target.checked)}
                    style={{cursor: 'pointer'}}
                    required
                />
                <label 
                    htmlFor={"check-box-"+campo.id} 
                    className="form-check-label" 
                    style={{cursor: 'pointer'}}
                >
                    <small>{campo.label}</small>
                </label>
            </div>
        </div>
    )
}
