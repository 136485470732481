import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'activeCampaign',
  initialState: {
    conta_selecionada: '',
    contas: [],
    active_campaign_lista_id: '',
  },
  reducers: {
    setActiveCampaign(state, { payload }) {
      return payload;
    },
    setActiveCampaignContaSelected(state, { payload: conta_selecionada }) {
      return { ...state, conta_selecionada };
    },
    setActiveCampaignListaId(state, { payload: active_campaign_lista_id }) {
      return { ...state, active_campaign_lista_id };
    },
  },
});

export const {
  setActiveCampaign,
  setActiveCampaignContaSelected,
  setActiveCampaignListaId,
} = slice.actions;

export const selectContasActiveCampaign = (state) =>
  state.activeCampaign.contas;
export const contaActiveCampaignSelecionada = (state) =>
  state.activeCampaign.conta_selecionada;
export const contaActiveCampaignListaId = (state) =>
  state.activeCampaign.active_campaign_lista_id;

export default slice.reducer;
