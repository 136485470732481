import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShowModalConfirmDeletion,
  toggleShowModalConfirmDeletion,
} from '../../../store/showSlice';

function ConfirmDeletion({
  title,
  textBtnDelete = 'Excluir',
  onClick = () => {},
}) {
  const dispatch = useDispatch();
  const show = useSelector(selectShowModalConfirmDeletion);

  return (
    <Modal
      show={show}
      onHide={() => dispatch(toggleShowModalConfirmDeletion())}
    //   backdrop="static"
    //   keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="container pt-5 d-flex flex-row  justify-content-center gap-4 p-5">
        <Button className="btn btn-secondary col-6" onClick={() => dispatch(toggleShowModalConfirmDeletion())}>
          Cancelar
        </Button>
        <Button  className="btn btn-danger col-6" onClick={onClick}>{textBtnDelete}</Button>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmDeletion;
