import { addQualificacao, setQualificacoes } from '../store/qualificacaoSlice';
import apiQuizClass from './apiQuizClass';

export const getQualificacao = (quizId) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(
        `/qualificacoes-lead?quiz_id=${quizId}`
      );

      dispatch(setQualificacoes(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const postQualificacao = (quiz_id) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.post(`/qualificacoes-lead`, {
        quiz_id,
      });

      dispatch(addQualificacao(response.data.id));
    } catch (error) {
      console.error(error);
      throw 'Erro ao criar qualificação.';
    }
  };
};

export const putQualificacao = async (qualificacaoId, data) => {
  try {
    await apiQuizClass.put(`/qualificacoes-lead/${qualificacaoId}`, data);
  } catch (error) {
    console.error(error);
    throw 'Erro ao salvar.';
  }
};

export const deleteQualificacao = async (qualificacaoId) => {
  try {
    await apiQuizClass.delete(`/qualificacoes-lead/${qualificacaoId}`);
  } catch (error) {
    console.error(error);
    throw 'Erro ao deletar.';
  }
};

