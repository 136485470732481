import apiQuizClass from './apiQuizClass';

export async function getTotalSizeUpload() {
  try {
    const response = await apiQuizClass.get('/planos/recurso/size-upload');
    const { atingiuLimiteUpload, totalSizeUpload } = response.data;
    
    return { atingiuLimiteUpload, totalSizeUpload };
  } catch (e) {
    console.log(e);
  }
}
