import React, { useId, useRef } from 'react';
import { Container } from './styles';
import Draggable from 'react-draggable';
import { useEditor } from '../Context';
import Questao from './Questao';
import Resultado from './Resultado';
import Apresentacao from './Apresentacao';
import { putPosicaoBloco } from '../../../services/editorFluxo';
import { useDispatch } from 'react-redux';
import {
  attContentQuestaoEditorFluxos,
  handleBlocosLogicoEditorFluxo,
  setResultadoNewBlock,
  setSelectedEditableContentEditorFluxo,
} from '../../../store/editorFluxosSlice';
import BlocoLogico from './BlocoLogico';

export default function Box({
  type,
  questao,
  resultado,
  apresentacao,
  blocoLogico,
}) {
  const dispatch = useDispatch();
  const { setArrows, wrapperPosition, onDrag, onStop } = useEditor();

  const boxIdUseId = useId();
  const dropBoxRef = useRef();
  const dragBoxRef = useRef();
  const delta = 6;
  const startX = useRef();
  const startY = useRef();

  const getId = () => {
    switch (type) {
      case 'APRESENTACAO':
        return boxIdUseId;
      case 'QUESTAO':
        return questao.id;
      case 'RESULTADO':
        return resultado.id;
      case 'BLOCO_LOGICO':
        return blocoLogico.id;
      default:
        return boxIdUseId;
    }
  };

  const getDefaultPosition = () => {
    const initialPosition = {
      x: -wrapperPosition.x * (1 / wrapperPosition.scale),
      y: -wrapperPosition.y * (1 / wrapperPosition.scale),
    };

    switch (type) {
      case 'APRESENTACAO':
        return apresentacao.posicao;
      case 'QUESTAO':
        return questao.newBlock ? initialPosition : questao.posicao;
      case 'RESULTADO':
        return resultado.newBlock ? initialPosition : resultado.posicao;
      case 'BLOCO_LOGICO':
        return blocoLogico.newBlock ? initialPosition : blocoLogico.posicao;
    }
  };

  const setNewBlockFalse = () => {
    switch (type) {
      case 'APRESENTACAO':
        break;
      case 'QUESTAO':
        if (questao.newBlock) {
          dispatch(
            attContentQuestaoEditorFluxos({
              id: questao.id,
              pergunta: questao.pergunta,
              opcoes: questao.opcoes,
              newBlock: false,
            })
          );
        }
        break;
      case 'RESULTADO':
        if (resultado.newBlock) {
          dispatch(
            setResultadoNewBlock({
              id: resultado.id,
              newBlock: false,
            })
          );
        }

        break;
      case 'BLOCO_LOGICO':
        if (blocoLogico.newBlock) {
          dispatch(
            handleBlocosLogicoEditorFluxo({
              ...blocoLogico,
              newBlock: false,
            })
          );
        }
        break;
      default:
        break;
    }
  };

  const setSelectedEditableContent = () => {
    let id = getId();

    dispatch(
      setSelectedEditableContentEditorFluxo({
        id,
        type,
        show: true,
      })
    );
  };

  return (
    <Draggable
      ref={dragBoxRef}
      onDrag={(e) => {
        onDrag();
        setArrows((arrows) => [...arrows]);
        dropBoxRef.current.style.zIndex = 1;
      }}
      onStop={(event, data) => {
        // onStop();
        dropBoxRef.current.style.zIndex = 0;

        const diffX = Math.abs(event.pageX - startX.current);
        const diffY = Math.abs(event.pageY - startY.current);
        if (diffX < delta && diffY < delta) {
          // Click!
          setSelectedEditableContent();
        } else {
          const newPosition = { x: data.x, y: data.y };
          setNewBlockFalse();
          putPosicaoBloco(getId(), type, newPosition);
        }
      }}
      onMouseEnter={onDrag}
      onMouseLeave={onStop}
      scale={wrapperPosition.scale}
      onMouseDown={(event) => {
        startX.current = event.pageX;
        startY.current = event.pageY;
      }}
      defaultPosition={getDefaultPosition()}
    >
      <Container
        id={getId()}
        className={`${
          type !== 'BLOCO_LOGICO' ? 'shadow-default border-radius' : ''
        } target-box-editor-fluxos`}
        onMouseEnter={() => {
          onDrag();
        }}
        onMouseLeave={() => {
          onStop();
        }}
        ref={dropBoxRef}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        data-draggable-type={type}
        type={type}
      >
        {type === 'APRESENTACAO' && (
          <Apresentacao
            boxId={getId()}
            dropBoxRef={dropBoxRef}
            dragBoxRef={dragBoxRef}
          />
        )}
        {type === 'QUESTAO' && (
          <Questao
            boxId={getId()}
            dropBoxRef={dropBoxRef}
            dragBoxRef={dragBoxRef}
            questao={questao}
          />
        )}
        {type === 'RESULTADO' && (
          <Resultado
            boxId={getId()}
            dropBoxRef={dropBoxRef}
            dragBoxRef={dragBoxRef}
            resultado={resultado}
          />
        )}
        {type === 'BLOCO_LOGICO' && (
          <BlocoLogico
            boxId={getId()}
            dropBoxRef={dropBoxRef}
            dragBoxRef={dragBoxRef}
            blocoLogico={blocoLogico}
          />
        )}
      </Container>
    </Draggable>
  );
}
