import { Field, Formik } from 'formik';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { putQuizConfigPopover } from '../../../../services/quizService';
import { uploadFile } from '../../../../services/uploadService';
import {
  selectIdQuiz,
  selectQuizPopoverConfig,
  selectSlugQuiz,
} from '../../../../store/quizSlice';
import { LabelUpload } from './styles';
import getUrl from '../../../../utils/getCacheUrl';

// import { Container } from './styles';

function ModalConfigPopover({ show, closeModal }) {
  const dispatch = useDispatch();

  const quizSlug = useSelector(selectSlugQuiz);
  const quizId = useSelector(selectIdQuiz);
  const { cor_texto_padrao } = useSelector((state) => state.personalizacao);
  const popoverConfig = useSelector(selectQuizPopoverConfig);

  const handleImage = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (!file) return;

    toast.promise(
      uploadFile(quizId, file).then((url) => {
        const newUrl = getUrl(url);
        setFieldValue('imagem_icone_popover', newUrl);
      }),
      {
        pending: 'Carregando imagem...',
        success: {
          render({ data }) {
            return 'Caregada com sucesso!';
          },
        },
        error: {
          render({ data: message }) {
            return message;
          },
        },
      }
    );
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Configuração popover</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={popoverConfig}
        onSubmit={(values, { setSubmitting }) => {
          toast.promise(
            dispatch(putQuizConfigPopover(quizId, values)).then(() => {
              setSubmitting(false);
              closeModal();
            }),
            {
              pending: 'Salvando configuração...',
              success: {
                render({ data }) {
                  return 'Configuração salva!';
                },
              },
              error: {
                render({ data }) {
                  setSubmitting(false);
                  return 'Erro ao salvar.';
                },
              },
            }
          );
        }}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="row">
                <div className="clas-12 col-lg-4 d-flex justify-content-center flex-column">
                  <Form.Group className="mb-3">
                    <Form.Label className="d-flex flex-row flex-nowrap gap-2">
                      Cor do botão
                    </Form.Label>
                    <Field
                      type="color"
                      className="form-control form-default"
                      name="cor_botao_popover"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="d-flex flex-row flex-nowrap gap-2">
                      Icone
                    </Form.Label>
                    <Field
                      id="imagem-icone-popover-quiz"
                      className="d-none"
                      type="file"
                      accept="image/*"
                      name="imagem_icone_popover_file"
                      onChange={(e) => handleImage(e, setFieldValue)}
                    />
                    {values.imagem_icone_popover ? (
                      <div
                        className="btn-group col"
                        role="group"
                        aria-label="Basic example"
                        style={{ width: '100%' }}
                      >
                        <LabelUpload
                          className="btn btn-secondary btn-md col-10"
                          htmlFor="imagem-icone-popover-quiz"
                        >
                          Alterar
                        </LabelUpload>
                        <button
                          type="button"
                          className="btn btn-danger btn-md col-2 p-0"
                          onClick={() =>
                            setFieldValue('imagem_icone_popover', '')
                          }
                          title="Remover"
                        >
                          <FaTimes />
                        </button>
                      </div>
                    ) : (
                      <LabelUpload
                        className="btn btn-secondary btn-md col-12"
                        htmlFor="imagem-icone-popover-quiz"
                      >
                        Upload
                      </LabelUpload>
                    )}
                  </Form.Group>
                </div>
                <div className="clas-12 col-lg-8">
                  <div
                    className="border-radius shadow-default border-gray"
                    style={{
                      height: 600,
                      width: '100%',
                      backgroundColor: '#F0F0F0',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `<iframe style="height: 600px;width: 100%;"srcdoc="<div id='quiz-popover' data-quiz-id='${quizSlug}' data-quiz-button-color='${values.cor_botao_popover}' data-quiz-button-close-color='${cor_texto_padrao}' data-quiz-button-text='Launch me' data-quiz-button-icon='${values.imagem_icone_popover}' style='all:unset;'></div> <script src='https://assetsus1.quizclass.com.br/assets/js/quiz-popover.js'></script>" allow="microphone; camera" style="display: block;"></iframe>`,
                    }}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>
                Fechar
              </Button>
              <button
                className="btn btn-default shadow-default ps-4 pe-4"
                type="submit"
                disabled={isSubmitting}
              >
                Salvar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ModalConfigPopover;
