import React from 'react'

export default function MultipleChoice({campo, value, setValue}) {

    return (
        <div>
            <label className="label-nome-campos">{campo.label}: </label>
            <div className="form-group text-left mb-2">
                <div className="d-flex flex-row flex-wrap">
                    {campo.opcoes.map((opcao, index) => (
                        <div key={index} className="form-check">
                            <input 
                                id={"multiple-choice-"+campo.id+index}
                                type="checkbox"  
                                className="form-check-input"
                                name={campo.nome}
                                value={opcao.opcoes}
                                onChange={e => setValue(e.target.value)}
                                style={{cursor: 'pointer'}}
                            />
                            <label 
                                htmlFor={"multiple-choice-"+campo.id+index} 
                                className="form-check-label pr-2" 
                                style={{cursor: 'pointer'}}
                            >
                                {opcao.opcoes}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </div> 
    )
}
