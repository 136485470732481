import React, { useRef } from 'react';
import { Field } from 'formik';
import { useDrag, useDrop } from 'react-dnd';

// import { Container } from './styles';

function Campo({ index, ordemCampo, campoChecked, handleCampos, moveItem }) {
  const ref = useRef();

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: 'ordemCampo',
    item: {
      index,
      ...ordemCampo,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: 'ordemCampo',
      drop: (itemDragged) => {
        const draggedIndex = itemDragged.index;
        const targetIndex = index;

        if (draggedIndex !== targetIndex) {
          moveItem(draggedIndex, targetIndex);
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [moveItem]
  );

  dragRef(dropRef(ref));

  return (
    <li
      ref={ref}
      style={{
        border: isOver ? '1px var(--preto) solid' : 'none',
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        padding: 30,
      }}
      className="form-check pb-2 pt-2 border-radius"
    >
      <label
        className="form-check-label"
        style={{
          cursor: 'pointer',
        }}
      >
        <Field
          className="form-check-input me-1"
          type="checkbox"
          name="checked"
          checked={campoChecked(ordemCampo.campo)}
          onChange={() => handleCampos(ordemCampo.campo)}
        />
        {ordemCampo.campo}
      </label>
    </li>
  );
}

export default Campo;
