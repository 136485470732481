export default class BlocoGabarito {
  static get toolbox() {
    return {
      title: 'Gabarito',
      icon: '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M688 312v-48c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8zm-392 88c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm376 116c-119.3 0-216 96.7-216 216s96.7 216 216 216 216-96.7 216-216-96.7-216-216-216zm107.5 323.5C750.8 868.2 712.6 884 672 884s-78.8-15.8-107.5-44.5C535.8 810.8 520 772.6 520 732s15.8-78.8 44.5-107.5C593.2 595.8 631.4 580 672 580s78.8 15.8 107.5 44.5C808.2 653.2 824 691.4 824 732s-15.8 78.8-44.5 107.5zM761 656h-44.3c-2.6 0-5 1.2-6.5 3.3l-63.5 87.8-23.1-31.9a7.92 7.92 0 0 0-6.5-3.3H573c-6.5 0-10.3 7.4-6.5 12.7l73.8 102.1c3.2 4.4 9.7 4.4 12.9 0l114.2-158c3.9-5.3.1-12.7-6.4-12.7zM440 852H208V148h560v344c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h272c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z"></path></svg>',
    };
  }

  render() {
    // const faCheck = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" color="#0EC120" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style="color: rgb(14, 193, 32);"><path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>';
    // const faTimes = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" color="#AC1515" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style="color: rgb(172, 21, 21);"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg>';

    const container = document.createElement('div');
    // container.style.border = '5px solid #FECD2E';
    container.style.borderTop = 'none';
    container.style.background = '#FFFFFF';
    container.style.color = '#000000';
    container.style.height = '200px';
    container.className = 'border-radius d-flex align-items-center justify-content-center cdx-block';
    container.innerHTML = '<span>Gabarito</span>'
    // container.innerHTML = `
    // <div class='d-flex align-items-center justify-content-center' style="height: 50px;background: #FECD2E;font-weight: 700;"><span>Gabarito</span></div>
    // <div class='container text-center p-3'>
    //   <div class="mb-4" style="font-weight: 700;">
    //     Questão 01<br>
    //     Sua resposta: <span style="font-weight: 400;">V, F ${faCheck}</span>
    //   </div>

    //   <div class="mb-4" style="font-weight: 700;">
    //     Questão 02<br>
    //     Sua resposta: <span style="font-weight: 400;">Teste ${faTimes}</span><br>
    //     Resposta correta: <span style="font-weight: 400;">Aberta 1, ou aberta 2</span>
    //   </div>

    //   <div class="mb-4" style="font-weight: 700;">
    //     Questão 03<br>
    //     Sua resposta: <span style="font-weight: 400;">Sem resposta ${faTimes}</span><br>
    //     Resposta correta: <span style="font-weight: 400;">Sim, ou não</span>
    //   </div>

    //   <div class="mb-4" style="font-weight: 700;">
    //     Questão 03<br>
    //     Sua resposta: <span style="font-weight: 400;">Primeira resposta teste!, Segunda resposta teste!, Terceira resposta teste! ${faCheck}</span>
    //   </div>

    // </div>
    // `;

    return container;
  }

  save(blockContent) {
    return {
      text: blockContent.value,
    };
  }
}
