import React, { useRef } from 'react';
import ConnectPointsWrapper from '../../ConnectPointsWrapper';
import { Container } from './styles';

function Opcao({ opcao, boxId, dropBoxRef, dragBoxRef }) {
  const itemRef = useRef();
  const itemId = opcao.id;

  return (
    <>
      <Container id={itemId} ref={itemRef} className="p-2 m-0 ">
        {opcao.texto}
        <ConnectPointsWrapper
          {...{ boxId, dropBoxRef, dragBoxRef, itemRef, itemId }}
        />
      </Container>
    </>
  );
}

export default Opcao;
