import React from 'react';
import { Link } from 'react-router-dom';

// import { Container } from './styles';

function Zapier() {
  return (
    <>
      <Link to={-1}>voltar</Link>
      <br />
      <br />
      <zapier-app-directory
        app="quiz-class"
        link-target="new-tab"
        theme="light"
        create-without-template="show"
        use-this-zap="show"
      />
    </>
  );
}

export default Zapier;
