import React from 'react';
import { Wrapper } from '../styles';
import Opcao from './Opcao';
import { getTextTipoQuestao } from '../../../../utils/tipoQuestao';
import { Content } from './styles';
import { FaClone } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { setSelectedEditableContentEditorFluxo } from '../../../../store/editorFluxosSlice';
import { duplicarQuestaoEditorFluxo } from '../../../../services/editorFluxo';
// import { Container } from './styles';

function Questao({ questao, boxId, dropBoxRef, dragBoxRef }) {
  const dispatch = useDispatch();
  function tratarPerguntaQuestao(htmlString) {
    // Remover todas as tags HTML
    let semTags = htmlString.replace(/<[^>]*>/g, '');

    // Remover espaços representados por &nbsp;
    let semNbsp = semTags.replace(/&nbsp;/g, '');

    // Remover espaços em branco extras
    let semEspacosExtras = semNbsp.replace(/\s+/g, ' ').trim();

    return semEspacosExtras.slice(0, 50);
  }

  return (
    <Content
      className={`content border-radius ${
        questao.newBlock ? 'animate-pulse' : ''
      }`}
      style={{ border: questao.newBlock ? '4px solid red' : 'none' }}
    >
      <span
        className="btnDuplicarQuestao"
        role="button"
        title="Duplicar questão"
        onClick={(e) => {
          dispatch(
            setSelectedEditableContentEditorFluxo({
              id: null,
              type: null,
              show: false,
            })
          );
          dispatch(duplicarQuestaoEditorFluxo(questao.id));
        }}
      >
        <FaClone size={16} />
      </span>
      <h1 className="title">Questão {getTextTipoQuestao(questao.tipo)}</h1>
      <Wrapper className="border-radius p-3">
        <div className="pergunta">
          {tratarPerguntaQuestao(questao.pergunta)}
        </div>
        {questao.opcoes.map((opcao) => (
          <Opcao
            key={opcao.id}
            opcao={opcao}
            {...{ boxId, dropBoxRef, dragBoxRef }}
          />
        ))}
      </Wrapper>
    </Content>
  );
}

export default Questao;
