import { setApresentacao } from '../store/apresentacaoSlice';
import { setPersonalizacao } from '../store/personalizacaoSlice';
import apiQuizClass from './apiQuizClass';

export const getApresentacao = (quizId) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(
        `/quizzes/${quizId}/apresentacao`
      );
      dispatch(setApresentacao(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const putApresentacao = async (quizId, data) => {
  try {
    await apiQuizClass.put(`/quizzes/${quizId}/apresentacao`, data);
  } catch (error) {
    console.error(error);
    throw 'Erro ao salvar.';
  }
};
