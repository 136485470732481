import styled from 'styled-components';

export const Container = styled.div`
`;

export const ListResultados = styled.ol`
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 0 0 15px;
  .faixa-resultado{
    font-size: 0.7rem;
    text-align: center;
  }
  li {
    list-style: initial;
    margin-bottom: 10px;
    list-style-position: inside;

    &:last-child {
      margin-bottom: 0;
    }
    a{
      display: inline-block;
    }
    a,
    span {
      font-weight: 500;
    }
  }
`;
