import React from 'react';
import { FaTimes } from 'react-icons/fa';

import { Container } from './styles';

function Close({ title = 'Fechar', onClick = () => {} }) {
  return (
    <Container className="btn btn-danger" onClick={onClick} title={title}>
      <FaTimes size={13} color={'white'} />
    </Container>
  );
}

export default Close;
