export default class BlocoWhatsApp {
  constructor({ api, data }) {
    this.api = api;
    this.data = Object.keys(data).length ? data : { telefone: '', texto: '' };
  }

  static get toolbox() {
    return {
      title: 'WhatsApp',
      icon: '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path></svg>',
    };
  }

  telefoneMask(value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1');
  }

  render() {
    const wrapper = document.createElement('div');
    wrapper.className='cdx-block';
    const label = document.createElement('label');

    const button = document.createElement('button');
    button.className = 'btn btn-md d-flex align-items-center';
    button.style.backgroundColor = '#55E065';
    button.innerHTML = `
      <svg class='me-2' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path></svg>
      <span>Fale com um atendente</span>
      <svg class='ms-2' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm-28.9 143.6l75.5 72.4H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h182.6l-75.5 72.4c-9.7 9.3-9.9 24.8-.4 34.3l11 10.9c9.4 9.4 24.6 9.4 33.9 0L404.3 273c9.4-9.4 9.4-24.6 0-33.9L271.6 106.3c-9.4-9.4-24.6-9.4-33.9 0l-11 10.9c-9.5 9.6-9.3 25.1.4 34.4z"></path></svg>
    `;

    const containerConfig = document.createElement('div');
    containerConfig.className = 'row';

    const divInputTelefone = document.createElement('div');
    divInputTelefone.className = 'col-6';

    const labelTelefone = document.createElement('label');
    labelTelefone.textContent = 'Número do celular';

    const inputTelefone = document.createElement('input');
    inputTelefone.setAttribute('name', 'telefone');
    inputTelefone.className = 'form-control';
    inputTelefone.value = this.telefoneMask(this.data.telefone) || '';
    inputTelefone.oninput = (e) => {
      e.target.value = this.telefoneMask(e.target.value);
    };

    divInputTelefone.appendChild(labelTelefone);
    divInputTelefone.appendChild(inputTelefone);

    const divInputTexto = document.createElement('div');
    divInputTexto.className = 'col-6';

    const labelTexto = document.createElement('label');
    labelTexto.textContent = 'Mensagem';

    const inputTexto = document.createElement('input');
    inputTexto.setAttribute('name', 'texto');
    inputTexto.className = 'form-control';
    inputTexto.value = this.data.texto || '';

    divInputTexto.appendChild(labelTexto);
    divInputTexto.appendChild(inputTexto);

    containerConfig.appendChild(divInputTelefone);
    containerConfig.appendChild(divInputTexto);

    label.appendChild(button);
    wrapper.appendChild(label);
    wrapper.appendChild(containerConfig);

    containerConfig.style.display = 'none';

    button.onfocus = () => {
      containerConfig.style.display = '';
    };
    wrapper.addEventListener('focusout', function (e) {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        containerConfig.style.display = 'none';
      }
    });

    return wrapper;
  }

  save(blockContent) {
    const inputs = blockContent.getElementsByTagName('input');

    let data = {
      [inputs[0].name]: inputs[0].value,
      [inputs[1].name]: inputs[1].value,
    };
    let telefone = data.telefone.replace(/[^0-9,]*/g, '');
    data.telefone = telefone;

    return data;
  }
}
