export default class BlocoNota {
  static get toolbox() {
    return {
      title: 'Bloco do tipo nota',
      icon: '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="#000" stroke-width="2" d="M9,18 L9,12 M12,18 L12,13 M15,18 L15,10 M17,3 L21,3 L21,23 L3,23 L3,3 L7,3 M7,1 L17,1 L17,5 L7,5 L7,1 Z"></path></svg>',
    };
  }

  render() {
    const paragraph = document.createElement('div');
    paragraph.className='cdx-block';
    paragraph.innerHTML = 'Você acertou <b>N</b> das <b>N</b> questões.<br/> A nota atingida foi <b>X.XX</b> pontos';
    
    return paragraph;
  }

  save(blockContent) {
    return {
      text: blockContent.value,
    };
  }
}
