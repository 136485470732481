import { Field, Formik, Form } from 'formik';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { TiWarning } from 'react-icons/ti';
import validateUrl from '../../../../utils/validateUrl';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIdQuiz,
  selectQuizWebhook,
  setQuizWebhook,
} from '../../../../store/quizSlice';
import { toast } from 'react-toastify';
import { putQuizWebhook } from '../../../../services/quizService';
import { useState } from 'react';
// import { Container } from './styles';

const ValidateFormSchema = Yup.object().shape({
  webhook: Yup.string()
    // .required('preencha este campo.')
    .test(
      'is-url-valid',
      'URL inválido: um esquema explícito (como https) deve ser fornecido.',
      (value) => validateUrl(value)
    ),
});

function Webhook() {
  const dispatch = useDispatch();
  const quizWebhook = useSelector(selectQuizWebhook);
  const quizId = useSelector(selectIdQuiz);
  const [urlValidated, setUrlValidated] = useState(quizWebhook ? true : false);

  const handleValidateUrl = async (url, setSubmitting, setFieldError) => {
    let status = false;

    await toast.promise(
      fetch(url, {
        method: 'post',
        body: JSON.stringify({
          conversion_identifier: "",
          name: "",
          email: "",
          mobile_phone: "",
          city: "",
          legal_bases: [
            {
              category: "",
              type: "",
              status: ""
            }
          ]
        })
      })
        .then((response) => {
          if (response.ok) {
            setSubmitting(false);
            setUrlValidated(true);
            status = true;
          } else throw new Error('Algo de errado com o enderço informado.');

        })
        .catch((error) => {
          throw 'Algo de errado com o enderço informado.';
        }),
      {
        pending: 'Validando URL...',
        success: 'URL validada com sucesso!',
        error: {
          render({ data }) {
            setFieldError('webhook', 'Algo de errado com o enderço informado.')
            setSubmitting(false);
            return 'Algo de errado com o enderço informado.';
          },
        },
      }
    );

    return status;
  }

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    if (!values.webhook.trim()) return;

    if (!urlValidated) {
      const validateResult = await handleValidateUrl(values.webhook, setSubmitting, setFieldError);
      if (!validateResult) return false;
    }



    await toast.promise(
      putQuizWebhook(quizId, values).then(() => {
        dispatch(setQuizWebhook(values.webhook));
        setSubmitting(false);
      }),
      {
        pending: 'Salvando...',
        success: 'Salvo com sucesso!',
        error: {
          render({ data }) {
            setSubmitting(false);
            return 'Erro ao salvar. Tente novamente';
          },
        },
      }
    );
  };

  return (
    <Formik
      initialValues={{ webhook: quizWebhook }}
      validationSchema={ValidateFormSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, touched, setFieldValue, setFieldError }) => (
        <Form>
          <label>Webhook</label>
          <p>
            Cadastre a URL do seu webhook para que o Quiz Class envie o seu Lead
            para este endereço toda vez que acontecer uma conversão neste quiz.
          </p>

          <Row>
            <Col xs="8" lg="6">
              <div className="input-group">
                <span className="input-group-text">
                  POST
                </span>
                <Field
                  type="url"
                  name="webhook"
                  className="form-default form-control"
                  placeholder="Endereço do seu Webhook"
                  onChange={(e) => {
                    setFieldValue('webhook', e.target.value);
                    setUrlValidated(false)
                  }}
                />

              </div>

              {errors.webhook && touched.webhook ? (
                <div className="text-danger mt-2">
                  <TiWarning className="me-2" />
                  <small className="pt-2">{errors.webhook}</small>
                </div>
              ) : null}
            </Col>
            <Col xs="4" lg="2">
              <button
                type="submit"
                className="btn btn-primary shadow-default col-12"
                disabled={isSubmitting}
              >
                {urlValidated ? 'Cadastrar' : 'Verificar'}
              </button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default Webhook;
