import React from 'react';
import { FaCheck, FaCog, FaLink } from 'react-icons/fa';
import { BsDot } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { postResultado } from '../../../services/resultadoService';
import {
  selectIdQuiz,
  selectNaturezaQuiz,
  selectResultadosQuiz,
  selectTipoResultadoQuiz,
} from '../../../store/quizSlice';
import PlusButton from '../../../components/Buttons/Plus';
import { Container, ListResultados } from './styles';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ModalTiposResultado from '../../../components/Modal/TiposResultado';
import {
  toggleShowModalEditNomeResultado,
  toggleShowModalTiposResultado,
} from '../../../store/showSlice';
import { Badge } from 'react-bootstrap';
import ModalEditNomeResultado from '../../../components/Modal/EditNomeResultado';
import { addBlocosResultadoEditorFluxo } from '../../../store/editorFluxosSlice';

function Resultados({ desktop }) {
  const dispatch = useDispatch();
  const resultados = useSelector(selectResultadosQuiz);
  const idQuiz = useSelector(selectIdQuiz);
  const naturezaQuiz = useSelector(selectNaturezaQuiz);
  const tipoResultado = useSelector(selectTipoResultadoQuiz);
  const { quizId } = useParams();
  const navigate = useNavigate();

  const addResultado = async (type = 'BLOCKS') => {
    const data = await dispatch(postResultado(idQuiz, type));

    if (naturezaQuiz !== 'FLUXO') {
      navigate(`/app/quizzes/${idQuiz}/resultados/${data.novo_resultado.id}`);
    } else {
      dispatch(
        addBlocosResultadoEditorFluxo({
          id: data.novo_resultado.id,
          nome: data.novo_resultado.nome,
          posicao: { x: 0, y: 0 },
          newBlock: true,
          type,
        })
      );
    }
  };

  const showFaixaResultado = () => {
    if (
      tipoResultado === 'SOMATORIO_QUESTOES' &&
      (naturezaQuiz === 'CERTO_ERRADO' || naturezaQuiz === 'PONTUACAO')
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Container className={naturezaQuiz === 'FLUXO' ? 'ps-3' : ''}>
        <div>
          <div className={`d-flex flex-row justify-content-between`}>
            <span>
              <FaCheck /> Resultados{' '}
            </span>
            {naturezaQuiz !== 'PESQUISA' && (
              <div className={desktop ? 'step-4-addResultado' : ''}>
                <PlusButton onClick={() => addResultado('BLOCKS')} />
              </div>
            )}
          </div>

          {naturezaQuiz === 'PONTUACAO' && (
            <button
              className="btn btn-secondary btn-sm col-12 mt-3 text-nowrap"
              onClick={() => dispatch(toggleShowModalTiposResultado())}
            >
              Tipos de resultado
            </button>
          )}
        </div>
        {naturezaQuiz !== 'FLUXO' && (
          <ListResultados>
            {(naturezaQuiz === 'PONTUACAO' || naturezaQuiz === 'PESQUISA') && (
              <li style={{ listStyle: 'none' }}>
                <NavLink
                  to={`/app/quizzes/${quizId}/resultados/default`}
                  className={({ isActive }) => (isActive ? 'active' : '')}
                >
                  <span>resultado padrão</span>
                </NavLink>
              </li>
            )}
            {naturezaQuiz !== 'PESQUISA' &&
              resultados.map((resultado, index) => (
                <li key={index} className="d-flex align-items-center flex-row">
                  <BsDot size={24} />

                  <NavLink
                    to={`/app/quizzes/${quizId}/resultados/${resultado.id}`}
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <div className="text-nowrap">
                      <span
                        className="m-0 d-block"
                        style={{
                          width: 90,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        title={resultado.nome || 'resultado ' + (index + 1)}
                      >
                        {resultado.nome || 'resultado ' + (index + 1)}
                      </span>
                      {showFaixaResultado() && (
                        <Badge
                          bg="light"
                          text="dark"
                          className="shadow-default faixa-resultado"
                        >
                          {resultado.valor_inicial || 0} a{' '}
                          {resultado.valor_final || 0}
                        </Badge>
                      )}
                    </div>
                  </NavLink>

                  <span
                    className="btn btn-sm shadow-none ms-3"
                    onClick={() =>
                      dispatch(
                        toggleShowModalEditNomeResultado({
                          show: true,
                          resultadoId: resultado.id,
                        })
                      )
                    }
                    title="Editar"
                  >
                    <FaCog />
                  </span>
                </li>
              ))}
          </ListResultados>
        )}

        <ModalTiposResultado />
        <ModalEditNomeResultado />
      </Container>
      {naturezaQuiz === 'FLUXO' && (
        <Container className={naturezaQuiz === 'FLUXO' ? 'ps-3' : ''}>
          <div>
            <div className={`d-flex flex-row justify-content-between`}>
              <span>
                <FaLink /> Resultados link
              </span>

              <div className={desktop ? 'step-4-addResultado' : ''}>
                <PlusButton onClick={() => addResultado('LINK')} />
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

export default Resultados;
