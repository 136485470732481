import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'apresentacao',
  initialState: {
    blocks: {},
    configs: {
      botao_iniciar: 'Iniciar',
    },
  },
  reducers: {
    setApresentacao(state, { payload }) {
      return payload;
    },
    setBlocks(state, { payload: newBlocks }) {
      const blocks = JSON.parse(JSON.stringify(newBlocks));

      return { ...state, blocks };
    },
    setTextBotaoIniciar(state, { payload: botao_iniciar }) {
      let newConfig = {
        ...state.configs,
        botao_iniciar,
      };

      return { ...state, configs: newConfig };
    },
  },
});

export const { setApresentacao, setBlocks, setTextBotaoIniciar } =
  slice.actions;

export const selectBlocksApresentacao = (state) => state.apresentacao.blocks;

export const selectConfigsApresentacao = (state) => state.apresentacao.configs;

export const selectTextBotaoIniciar = (state) =>
  state.apresentacao.configs.botao_iniciar;

export default slice.reducer;
