import React, { useState } from 'react';
import validateUrl from '../../../../utils/validateUrl';
import { TiWarning } from 'react-icons/ti';
import { putResultadoLink } from '../../../../services/editorFluxo';
import { toast } from 'react-toastify';

// import { Container } from './styles';

function FormResultadoLink({ resultado }) {
  const [redirectUri, setRedirectUri] = useState(resultado.redirect_uri ?? '');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await toast.promise(putResultadoLink(resultado.id, redirectUri), {
        pending: 'Salvando...',
        success: {
          render({ data }) {
            return 'Salvo com sucesso!';
          },
        },
        error: {
          render({ data: message }) {
            return 'Erro ao salvar.';
          },
        },
      });
    } catch (error) {}

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label>Link de redirecionamento</label>
        <input
          name="redirect_uri"
          className="form-control form-default"
          placeholder="ex.:https://quizclass.com.br"
          value={redirectUri}
          onChange={(e) => setRedirectUri(e.target.value)}
          required
        />
        {!validateUrl(redirectUri) && (
          <div className="text-danger mt-2">
            <TiWarning className="me-2" />
            <small className="pt-2">
              URL inválido: um esquema explícito (como https) deve ser
              fornecido.
            </small>
          </div>
        )}
      </div>
      <button
        className="btn btn-default shadow-default ps-4 pe-4 mt-3"
        type="submit"
        disabled={isSubmitting}
      >
        Salvar
      </button>
    </form>
  );
}

export default FormResultadoLink;
