import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../../../../services/uploadService';
import {
  setBackgroundImageApresentacao,
  setBackgroundImageQuestao,
  setBackgroundImageResultado,
  setBackgroundSizeApresentacao,
  setBackgroundSizeQuestao,
  setBackgroundSizeResultado,
} from '../../../../store/personalizacaoSlice';
import { selectIdQuiz } from '../../../../store/quizSlice';
import { Container, LabelUpload } from './styles';
import { toast } from 'react-toastify';
import {
  FaCompressArrowsAlt,
  FaExpandArrowsAlt,
  FaTimes,
} from 'react-icons/fa';
import getUrl from '../../../../utils/getCacheUrl';

function BackgroundImage({ page, backgroundImage, backgroundSize }) {
  const dispatch = useDispatch();
  const idQuiz = useSelector(selectIdQuiz);
  const iputRef = useRef();

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    toast.promise(
      uploadFile(idQuiz, file).then((url) => {
        const newUrl = getUrl(url);
        switch (page) {
          case 'apresentacao':
            dispatch(setBackgroundImageApresentacao(newUrl));
            break;
          case 'questao':
            dispatch(setBackgroundImageQuestao(newUrl));
            break;
          case 'resultado':
            dispatch(setBackgroundImageResultado(newUrl));
            break;
        }

        !backgroundSize && handleCackgroundSize('cover');
      }),
      {
        pending: 'Carregando imagem...',
        success: {
          render({ data }) {
            return 'Caregada com sucesso!';
          },
        },
        error: {
          render({ data: message }) {
            return message;
          },
        },
      }
    );
  };

  const removerImage = () => {
    iputRef.current.value = '';
    try {
      switch (page) {
        case 'apresentacao':
          dispatch(setBackgroundImageApresentacao(''));
          break;
        case 'questao':
          dispatch(setBackgroundImageQuestao(''));
          break;
        case 'resultado':
          dispatch(setBackgroundImageResultado(''));
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCackgroundSize = async (size) => {
    try {
      switch (page) {
        case 'apresentacao':
          dispatch(setBackgroundSizeApresentacao(size));
          break;
        case 'questao':
          dispatch(setBackgroundSizeQuestao(size));
          break;
        case 'resultado':
          dispatch(setBackgroundSizeResultado(size));
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container className="shadow-default border-radius-4 border-gray">
      <input
        ref={iputRef}
        type="file"
        accept="image/*"
        id={`image-background-${page}`}
        className="d-none"
        onChange={handleImage}
      />

      {backgroundImage ? (
        <div
          className="btn-group col"
          role="group"
          aria-label="Basic example"
          style={{ width: '100%' }}
        >
          <LabelUpload
            className="btn btn-secondary btn-md col-10"
            htmlFor={`image-background-${page}`}
          >
            Alterar
          </LabelUpload>
          <button
            type="button"
            className="btn btn-danger btn-md col-2 p-0"
            onClick={removerImage}
            title="Remover"
          >
            <FaTimes />
          </button>
        </div>
      ) : (
        <LabelUpload
          className="btn btn-secondary btn-md col"
          htmlFor={`image-background-${page}`}
        >
          Upload
        </LabelUpload>
      )}

      <button
        className="btn btn-md pe-3 ps-3"
        onClick={() => handleCackgroundSize('cover')}
        style={{
          background: backgroundSize === 'cover' ? '#DCDCDC' : '#FFFFFF',
        }}
        title="Tela cheia"
      >
        <FaExpandArrowsAlt size={20} />
      </button>

      <button
        className="btn btn-md pe-3 ps-3"
        onClick={() => handleCackgroundSize('contain')}
        style={{
          background: backgroundSize === 'contain' ? '#DCDCDC' : '#FFFFFF',
        }}
        title="Centralizado"
      >
        <FaCompressArrowsAlt size={20} />
      </button>
    </Container>
  );
}

export default BackgroundImage;
