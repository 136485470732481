import React from 'react';
import { Wrapper } from '../styles';

// import { Container } from './styles';

function Resultado({ resultado, boxId, dropBoxRef, dragBoxRef }) {
  return (
    <div
      className={`content border-radius ${
        resultado.newBlock ? 'animate-pulse' : ''
      }`}
      style={{ border: resultado.newBlock ? '4px solid red' : 'none' }}
    >
      <h1 className="title">{resultado.nome}</h1>
      <Wrapper className="border-radius p-3">
        <span style={{ fontWeight: 700 }}>
          Editar {resultado.type === 'LINK' ? 'link' : 'resultado'}
        </span>
      </Wrapper>
    </div>
  );
}

export default Resultado;
