import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Container, Actions } from './styles';
import BtnClose from '../../components/Buttons/Close';
import { selectIdQuiz } from '../../store/quizSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteQualificacao,
  getQualificacao,
  postQualificacao,
  putQualificacao,
} from '../../services/qualificacaoService';
import {
  removeQualificacao,
  selectQualificacoes,
  setQualificacoes,
} from '../../store/qualificacaoSlice';
import { FaPlus } from 'react-icons/fa';
import ModalRespostaQuestaoOpcao from './components/Modal/RespostaQuestaoOpcao';

function Qualificacao() {
  const dispatch = useDispatch();
  const qualificacoes = useSelector(selectQualificacoes);
  const [isLoading, setIsLoading] = useState(true);
  const quizId = useSelector(selectIdQuiz);
  const [showModal, setShowModal] = useState(false);
  const [indexQualificacaoSelectedModal, setIndexQualificacaoSelectedModal] =
    useState('');

  useEffect(() => {
    if (quizId) {
      dispatch(getQualificacao(quizId)).then(() => setIsLoading(false));
    }
  }, [dispatch, quizId]);

  const handleRulesCollection = (e, index) => {
    let newQualificacoes = [...qualificacoes];

    newQualificacoes[index] = {
      ...newQualificacoes[index],
      [e.target.name]: e.target.value,
    };

    dispatch(setQualificacoes(newQualificacoes));
    salvar(newQualificacoes[index].id, newQualificacoes[index]);
  };

  const createQualificacao = () => {
    dispatch(postQualificacao(quizId));
  };

  const salvar = (qualificacaoId, data) => {
    putQualificacao(qualificacaoId, data);
  };

  const remove = (index, id = null) => {
    dispatch(removeQualificacao(index));

    if (id) deleteQualificacao(id);
  };

  return (
    <Container
      className="col-12 col-lg-11"
      style={{ display: isLoading && 'none' }}
    >
      <h2>Qualificação dos Leads</h2>
      <p>
        Nesta parte, você poderá criar regras para dar uma qualificação do seu
        Lead
      </p>

      <br />

      {qualificacoes.map((qualificacao, index) => (
        <div
          key={index}
          className="border-radius shadow-default mb-3 position-relative"
          style={{ border: '1px solid #ccc' }}
        >
          <Actions>
            <BtnClose
              title="Remover regra"
              onClick={() => remove(index, qualificacao.id)}
            />
          </Actions>
          <Row className="p-3">
            <Col xs="12" lg="6">
              <div className="form-group mb-3">
                <label>Regra</label>
                <select
                  name="regra"
                  className="form-control form-select form-default"
                  value={qualificacao.regra || ''}
                  onChange={(e) => handleRulesCollection(e, index)}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  <option value="LEAD_CAPTADO">Lead captado</option>
                  <option value="LEAD_CAPTADO_QUIZ_INICIADO">
                    Lead captado e Quiz Iniciado
                  </option>
                  <option value="LEAD_CAPTADO_QUIZ_FINALIZADO">
                    Lead captado e Quiz Finalizado
                  </option>
                  <option value="LEAD_CAPTADO_RESPOSTA_QUESTAO">
                    Lead captado e Respondeu questão X com valor Y
                  </option>
                </select>
                {qualificacao.regra === 'LEAD_CAPTADO_RESPOSTA_QUESTAO' && (
                  <button
                    className="btn btn-secondary btn-sm col-12 mt-2"
                    onClick={() => {
                      setIndexQualificacaoSelectedModal(index);
                      setShowModal(true);
                    }}
                  >
                    Questão X - Resposta: XPTO
                  </button>
                )}
              </div>
            </Col>

            <Col xs="12" lg="6">
              <div className="form-group mb-3">
                <label>Pontuação</label>
                <select
                  name="valor"
                  className="form-control form-select form-default"
                  value={qualificacao.valor || 0}
                  onChange={(e) => handleRulesCollection(e, index)}
                >
                  <option value={0} disabled>
                    Selecione uma opção
                  </option>
                  <option value={1}>1 estrela</option>
                  <option value={2}>2 estrelas</option>
                  <option value={3}>3 estrelas</option>
                  <option value={4}>4 estrelas</option>
                  <option value={5}>5 estrelas</option>
                </select>
              </div>
            </Col>
          </Row>
        </div>
      ))}

      <button
        className="btn btn-secondary btn-sm shadow-default ps-4 pe-4 mt-3"
        onClick={createQualificacao}
      >
        Adicionar regra <FaPlus />
      </button>

      <ModalRespostaQuestaoOpcao
        show={showModal}
        setShow={setShowModal}
        questaoId={qualificacoes[indexQualificacaoSelectedModal]?.questaoId}
        opcaoId={qualificacoes[indexQualificacaoSelectedModal]?.opcaoId}
        qualificacao={qualificacoes[indexQualificacaoSelectedModal]}
      />
    </Container>
  );
}

export default Qualificacao;
