import styled from 'styled-components';

export const Container = styled.div`
  width: 150px;
  height: 260px;
  background-image: url('https://assetsus1.quizclass.com.br/assets/images/bloco-logico.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
`;

export const OpcaoSim = styled.div`
  position: absolute;
  top: 85px;
  right: 50px;
`;

export const OpcaoNao = styled.div`
  position: absolute;
  bottom: 85px;
  right: 50px;
`;
