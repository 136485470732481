import axios from 'axios';
import { getParamUrl } from '../utils/paramUrl';
import store from '../store/store';
import { setShowModalRestricaoPlano } from '../store/showSlice';
const { dispatch } = store; // direct access to redux store.

const apiQuizClass = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
});

const api_key = getParamUrl('api_key') || localStorage.getItem('api_key');
localStorage.setItem('api_key', api_key);

var pathname = new URL(window.location.href).pathname;
const quiz_id = pathname.split('/')[3];

apiQuizClass.interceptors.request.use(async (config) => {
  config.headers['Content-type'] = `application/json`;
  config.params = {
    api_key,
    quiz_id,
  };

  return config;
});

apiQuizClass.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      dispatch(
        setShowModalRestricaoPlano({
          show: true,
          data: error.response.data
        })
      );
      // error.response.data
    }
    return Promise.reject(error);
  }
);

export default apiQuizClass;
