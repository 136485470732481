import styled from 'styled-components';

export const LogoQuiz = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  position: absolute;
  bottom: 0;
`;
