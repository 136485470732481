import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'show',
  initialState: {
    showToolbar: { show: false, type: '' },
    showModalConfiguracaoDadosUsuario: false,
    showModalConfirmDeletion: false,
    showModalTiposQuestao: { show: false, type: 'CREATE' || 'EDIT' },
    showModalFeedbackQuestao: false,
    showModalRegrasLogicaValor: false,
    showModalTiposResultado: false,
    showModalConfigBlocoLogico: false,
    showModalConfigBlocoLogico: false,
    showModalConfiguracaoResultado: false,
    showModalPreviewDesktop: false,
    showModalPreviewMobile: false,
    showModalEditNomeResultado: { show: false, resultadoId: '' },
    showModalCropImage: false,
    showModalRestricaoPlano: { show: false, data: {} },
    showModalAdicionarCampoPersonalizado: false,
  },
  reducers: {
    toggleShowToolbar(state, { payload: { show, type } }) {
      return { ...state, showToolbar: { show, type } };
    },
    toggleShowModalConfiguracaoDadosUsuario(state) {
      return {
        ...state,
        showModalConfiguracaoDadosUsuario:
          !state.showModalConfiguracaoDadosUsuario,
      };
    },
    toggleShowModalConfirmDeletion(state) {
      return {
        ...state,
        showModalConfirmDeletion: !state.showModalConfirmDeletion,
      };
    },
    toggleShowModalTiposQuestao(state, { payload: { show, type } }) {
      return {
        ...state,
        showModalTiposQuestao: { show, type },
      };
    },
    toggleShowModalFeedbackQuestao(state) {
      return {
        ...state,
        showModalFeedbackQuestao: !state.showModalFeedbackQuestao,
      };
    },
    toggleShowModalRegrasLogicaValor(state) {
      return {
        ...state,
        showModalRegrasLogicaValor: !state.showModalRegrasLogicaValor,
      };
    },
    toggleShowModalTiposResultado(state) {
      return {
        ...state,
        showModalTiposResultado: !state.showModalTiposResultado,
      };
    },
    toggleShowModalConfigBlocoLogico(state) {
      return {
        ...state,
        showModalConfigBlocoLogico: !state.showModalConfigBlocoLogico,
      };
    },
    toggleShowModalConfiguracaoResultado(state) {
      return {
        ...state,
        showModalConfiguracaoResultado: !state.showModalConfiguracaoResultado,
      };
    },
    toggleShowModalPreviewDesktop(state) {
      return {
        ...state,
        showModalPreviewDesktop: !state.showModalPreviewDesktop,
      };
    },
    toggleShowModalPreviewMobile(state) {
      return {
        ...state,
        showModalPreviewMobile: !state.showModalPreviewMobile,
      };
    },
    toggleShowModalEditNomeResultado(
      state,
      { payload: { show, resultadoId } }
    ) {
      return {
        ...state,
        showModalEditNomeResultado: { show, resultadoId },
      };
    },
    toggleShowModalCropImage(state) {
      return {
        ...state,
        showModalCropImage: !state.showModalCropImage,
      };
    },
    setShowModalRestricaoPlano(state, { payload: { show, data } }) {
      return { ...state, showModalRestricaoPlano: { show, data } };
    },
    toggleShowModalAdicionarCampoPersonalizado(state) {
      return {
        ...state,
        showModalAdicionarCampoPersonalizado:
          !state.showModalAdicionarCampoPersonalizado,
      };
    },
  },
});

export const {
  toggleShowToolbar,
  toggleShowModalConfiguracaoDadosUsuario,
  toggleShowModalConfirmDeletion,
  toggleShowModalTiposQuestao,
  toggleShowModalFeedbackQuestao,
  toggleShowModalRegrasLogicaValor,
  toggleShowModalTiposResultado,
  toggleShowModalConfigBlocoLogico,
  toggleShowModalConfiguracaoResultado,
  toggleShowModalPreviewDesktop,
  toggleShowModalPreviewMobile,
  toggleShowModalEditNomeResultado,
  toggleShowModalCropImage,
  setShowModalRestricaoPlano,
  toggleShowModalAdicionarCampoPersonalizado,
} = slice.actions;

export const selectShowToolbar = (state) => state.show.showToolbar;
export const selectShowModalConfiguracaoDadosUsuario = (state) =>
  state.show.showModalConfiguracaoDadosUsuario;
export const selectShowModalConfirmDeletion = (state) =>
  state.show.showModalConfirmDeletion;
export const selectShowModalTiposQuestao = (state) =>
  state.show.showModalTiposQuestao;
export const selectShowModalFeedbackQuestao = (state) =>
  state.show.showModalFeedbackQuestao;
export const selectShowModalRegrasLogicaValor = (state) =>
  state.show.showModalRegrasLogicaValor;
export const selectShowModalTiposResultado = (state) =>
  state.show.showModalTiposResultado;

export const selectShowModalConfigBlocoLogico = (state) =>
  state.show.showModalConfigBlocoLogico;

export const selectShowModalConfiguracaoResultado = (state) =>
  state.show.showModalConfiguracaoResultado;

export const selectShowModalPreviewDesktop = (state) =>
  state.show.showModalPreviewDesktop;

export const selectShowModalPreviewMobile = (state) =>
  state.show.showModalPreviewMobile;

export const selectShowModalEditNomeResultado = (state) =>
  state.show.showModalEditNomeResultado;

export const selectShowModalCropImage = (state) =>
  state.show.showModalCropImage;

export const selectShowModalRestricaoPlano = (state) =>
  state.show.showModalRestricaoPlano;

export const selectShowModalAdicionarCampoPersonalizado = (state) =>
  state.show.showModalAdicionarCampoPersonalizado;

export default slice.reducer;
