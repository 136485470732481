import React from 'react';
import { Container } from './styles';
import EditorFluxosComponent from '../../components/EditorFluxos';
import { EditorProvider } from '../../components/EditorFluxos/Context';

function EditorFluxos() {
  return (
    <Container className="col-12 col-lg-12" style={{ height: '100%' }}>
      <EditorProvider>
        <EditorFluxosComponent />
      </EditorProvider>
    </Container>
  );
}

export default EditorFluxos;
