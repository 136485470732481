import styled from 'styled-components';

export const Container = styled.div`
  p {
    font-size: 1rem;
    font-weight: 600;
  }

  p.info {
    font-style: italic;
    font-weight: 500;
    font-size: 13px;

    color: #918c82;

    span{
      font-weight: 700;

    }
  }
`;
