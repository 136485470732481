import { Provider } from 'react-redux';
import GlobalStyle from './assets/styles/global';
import store from './store/store';
import {BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes'
function App({quizId}) {
  return (

    <Provider store={store}>
      <Router>
        <Routes quizId={quizId}/>
      </Router>
      <GlobalStyle/>
    </Provider>
  );
}

export default App;
