import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getOpcoes } from '../../../services/opcaoService';
import { selectQuestoesQuiz } from '../../../store/quizSlice';
import {
  selectShowModalConfigBlocoLogico,
  toggleShowModalConfigBlocoLogico,
} from '../../../store/showSlice';

function ConfigBlocoLogico({ indexBlockResultado, block, updateBlocoLogico }) {
  const dispatch = useDispatch();
  const show = useSelector(selectShowModalConfigBlocoLogico);
  const questoes = useSelector(selectQuestoesQuiz);
  const [opcoes, setOpcoes] = useState([]);
  const [questaoSelected, setQuestaoSelected] = useState(
    block?.tunes?.blocoLogicoTune?.questaoId || ''
  );
  const [opcaoSelected, setOpcaoSelected] = useState(
    block?.tunes?.blocoLogicoTune?.opcaoId || ''
  );

  const getOpcoesFromQuestao = async () => {
    try {
      const { opcoes: newOpcoes } = await getOpcoes(questaoSelected);
      setOpcoes(newOpcoes);
    } catch (error) {
      console.error(error);
    }
  };

  const save = () => {
    updateBlocoLogico(indexBlockResultado, questaoSelected, opcaoSelected);
    dispatch(toggleShowModalConfigBlocoLogico());
  };

  useEffect(() => {
    if (questaoSelected) getOpcoesFromQuestao();
  }, [questaoSelected]);

  useEffect(() => {
    setQuestaoSelected(block?.tunes?.blocoLogicoTune?.questaoId || '');
    setOpcaoSelected(block?.tunes?.blocoLogicoTune?.opcaoId || '');
  }, [block, indexBlockResultado]);

  return (
    <Modal
      show={show}
      onHide={() => dispatch(toggleShowModalConfigBlocoLogico())}
      size="lg"
      centered
    >
      <Modal.Header style={{ border: 'none' }}>
        <Modal.Title>Configurar bloco lógico</Modal.Title>
      </Modal.Header>

      <Modal.Body className="container d-flex flex-column">
        <div className="form-group mb-3">
          <label>Questão</label>
          <select
            name="questaoId"
            className="form-control form-select form-default"
            value={questaoSelected}
            onChange={(e) => {
              setQuestaoSelected(e.target.value);
              setOpcaoSelected('');
            }}
          >
            <option value="" disabled>
              Selecione uma questão
            </option>
            {questoes.map((questao, index) => (
              <option key={questao.id} value={questao.id}>
                {questao.pergunta
                  ? `${index + 1} - ${questao.pergunta
                      .replace(/<[^>]+>/g, '')
                      .substring(0, 50)}...`
                  : `${index + 1} - Nova questão`}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label>Opção</label>
          <select
            name="opcaoId"
            className="form-control form-select form-default"
            value={opcaoSelected}
            onChange={(e) => {
              setOpcaoSelected(e.target.value);
            }}
          >
            <option value="" disabled>
              Selecione uma opção
            </option>
            {opcoes.map((opcao) => (
              <option key={opcao.id} value={opcao.id}>
                {opcao.texto}
              </option>
            ))}
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => dispatch(toggleShowModalConfigBlocoLogico())}
        >
          Fechar
        </Button>
        <button
          className="btn btn-default shadow-default ps-4 pe-4"
          onClick={save}
        >
          Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfigBlocoLogico;
