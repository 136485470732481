//import { setConfiguracaoForm } from '../store/formDadosUsuarioSlice';
import { setConfiguracao } from '../store/configuracaoSlice';
import {
  setQuiz,
  setQuizPopoverConfig,
  setQuizPublicado,
  toggleQuizShowApresentacao,
} from '../store/quizSlice';
import apiQuizClass from './apiQuizClass';

export const getQuiz = (quizId) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(`/quizzes/${quizId}`);

      const dataQuiz = {
        ...response.data,
        // id: response.data.slug || response.data.id,
      };

      dispatch(setQuiz(dataQuiz));

      return dataQuiz;
    } catch (error) {
      console.error(error);
    }
  };
};

export const putQuizNome = async (quizId, nome) => {
  try {
    await apiQuizClass.put(`/quizzes/${quizId}/nome`, {
      nome,
    });
  } catch (error) {
    console.error(error);
  }
};

export const putQuizConfigPopover = (quizId, configPopover) => {
  return async (dispatch) => {
    try {
      await apiQuizClass.put(
        `/quizzes/${quizId}/config/popover`,
        configPopover
      );
      dispatch(setQuizPopoverConfig(configPopover));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

export const putQuizFonte = async (quizId, fonte) => {
  try {
    await apiQuizClass.put(`/quizzes/${quizId}/fonte`, {
      fonte,
    });
  } catch (error) {
    console.error(error);
  }
};
export const putQuizShowLogo = async (quizId, show_logo_quizclass) => {
  try {
    await apiQuizClass.put(`/quizzes/${quizId}/show-logo`, {
      show_logo_quizclass,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const putQuizSubdominio = async (quizId, subdominio) => {
  try {
    await apiQuizClass.put(`/quizzes/${quizId}/subdominio`, {
      subdominio,
    });
  } catch (error) {
    console.error(error);
  }
};

export const putQuizPublicado = (quizId, publicado) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.put(`/quizzes/${quizId}/publicado`, {
        publicado,
      });

      if (!response.data.publicado) {
        throw {
          message: 'Este quiz não está apto para ser publicado.',
          details: response.data.details,
        };
      }

      dispatch(setQuizPublicado(publicado));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

export const getConfiguracaoQuiz = (quizId) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(
        `/quizzes/${quizId}/configuracao`
      );
      dispatch(setConfiguracao(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const putConfiguracaoQuiz = async (quizId, data) => {
  try {
    await apiQuizClass.put(`/quizzes/${quizId}/configuracao`, data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const putReordenaQuestoes = async (idQuiz, questoes) => {
  try {
    await apiQuizClass.put(`/quizzes/${idQuiz}/reordena-questoes`, questoes);
  } catch (error) {
    console.error(error);
    throw 'Erro ao salvar.';
  }
};

export const putQuizIntegracoes = async (quizId, data) => {
  try {
    await apiQuizClass.put(`/quizzes/${quizId}/configuracao/integracoes`, data);
  } catch (error) {
    console.error(error);
    throw 'Erro ao salvar.';
  }
};

export const getValidacaoAcessoIntegracoes = async (quizId) => {
  try {
    const response = await apiQuizClass.get(
      `/quizzes/${quizId}/configuracao/integracoes/validar-acesso`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const putQuizWebhook = async (quizId, data) => {
  try {
    await apiQuizClass.put(`/quizzes/${quizId}/configuracao/webhook`, data);
  } catch (error) {
    console.error(error);
    throw 'Erro ao salvar.';
  }
};

export const putQuizShowApresentacao = (quizId, show_apresentacao) => {
  return async (dispatch) => {
    try {
      await apiQuizClass.put(`/quizzes/${quizId}/config/show-apresentacao`, {
        show_apresentacao,
      });
      dispatch(toggleQuizShowApresentacao());
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};
