import { Field } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import Campos from './Campos';
import ModalAdicionarCampoPersonalizado from '../../../components/Modal/AdicionarCampoPersonalizado';
import { toggleShowModalAdicionarCampoPersonalizado } from '../../../store/showSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TiWarning } from 'react-icons/ti';
import { selectQuizShowApresentacao } from '../../../store/quizSlice';

function DadosUsuario({ values, setFieldValue, errors, touched }) {
  const dispatch = useDispatch();
  const showApresentacao = useSelector(selectQuizShowApresentacao);

  return (
    <>
      <div className="form-group mb-4">
        <label>
          <li className="config-item">
            Gostaria de obter alguns dados das pessoas que vão responder o seu
            quiz?
          </li>
        </label>
        <div
          className="m-2"
          role="group"
          aria-labelledby="radio-group-obter-dados-respondente"
        >
          <Form.Check
            inline
            label="Sim"
            name="obter_dados_respondente"
            type="radio"
            checked={values.obter_dados_respondente}
            onChange={(e) =>
              setFieldValue('obter_dados_respondente', e.target.checked)
            }
            id="obter-dados-respondente-sim"
          />
          <Form.Check
            inline
            label="Não"
            name="obter_dados_respondente"
            type="radio"
            checked={!values.obter_dados_respondente}
            onChange={(e) =>
              setFieldValue('obter_dados_respondente', !e.target.checked)
            }
            id="obter-dados-respondente-nao"
          />
        </div>
      </div>
      <ol className="sub-config">
        {values.obter_dados_respondente && (
          <div>
            <div className="form-group mb-4">
              <label>
                <li className="sub-config-item">Quais dados?</li>
              </label>
              <br />
              <small>
                * Clique e arraste, caso desejar alterar a ordem dos campos no
                seu formulário.
              </small>
              <div className="m-2">
                <Campos configuracao={values} setFieldValue={setFieldValue} />
              </div>
              <button
                className="btn btn-secondary btn-sm"
                type="button"
                onClick={() =>
                  dispatch(toggleShowModalAdicionarCampoPersonalizado())
                }
              >
                Adicionar campo
              </button>
              <ModalAdicionarCampoPersonalizado />
            </div>

            <div className="form-group mb-4">
              <label>
                <li className="sub-config-item">
                  Quando deve ser solicitados estes dados ao respondente?
                </li>
              </label>
              <div
                className="m-2 d-flex flex-column gap-2"
                role="group"
                aria-labelledby="radio-group-quando-obter-dados"
              >
                <Form.Check
                  inline
                  label="No começo do teste, antes de começar a responder às questões."
                  name="quando_obter_dados"
                  type="radio"
                  checked={values.quando_obter_dados === 'INICIO'}
                  onChange={(e) =>
                    setFieldValue(
                      'quando_obter_dados',
                      e.target.checked && 'INICIO'
                    )
                  }
                  id="quando-obter-dados-INICIO"
                  disabled={!showApresentacao}
                />

                <Form.Check
                  inline
                  label="No fim do quiz, como condição para que seja mostrada a quantidade de acertos ou nota."
                  name="quando_obter_dados"
                  type="radio"
                  checked={values.quando_obter_dados === 'FIM'}
                  onChange={(e) =>
                    setFieldValue(
                      'quando_obter_dados',
                      e.target.checked && 'FIM'
                    )
                  }
                  id="quando-obter-dados-FIM"
                />
              </div>
            </div>

            <div
              className="form-group mb-3"
              style={{
                opacity:
                  values.quando_obter_dados === 'INICIO' && values.obter_email
                    ? 1
                    : 0.4,
                pointerEvents:
                  (values.quando_obter_dados !== 'INICIO' ||
                    !values.obter_email) &&
                  'none',
              }}
            >
              <label>
                <li className="sub-config-item">
                  Limitar quantidade de respostas uma vez por e-mail?
                </li>
              </label>
              <br />
              <small>* Resposta fica limitada uma vez por e-mail.</small>

              <div
                className="m-2"
                role="group"
                aria-labelledby="radio-group-obter-dados-respondente"
              >
                <Form.Check
                  inline
                  label="Sim"
                  name="pode_responder_apenas_uma_vez"
                  type="radio"
                  checked={values.pode_responder_apenas_uma_vez}
                  onChange={(e) =>
                    setFieldValue(
                      'pode_responder_apenas_uma_vez',
                      e.target.checked
                    )
                  }
                  id="pode_responder_apenas_uma_vez_SIM"
                />
                <Form.Check
                  inline
                  label="Não"
                  name="pode_responder_apenas_uma_vez"
                  type="radio"
                  checked={!values.pode_responder_apenas_uma_vez}
                  onChange={(e) =>
                    setFieldValue(
                      'pode_responder_apenas_uma_vez',
                      !e.target.checked
                    )
                  }
                  id="pode_responder_apenas_uma_vez_NAO"
                />
              </div>
            </div>

            <div className="form-group mb-4">
              <label>
                <li className="sub-config-item">
                  Endereço política de privacidade
                </li>
              </label>

              <Field
                type="url"
                // pattern="https?:\/\/*"
                className="form-control form-default"
                name="url_politica_de_privacidade"
                value={values.url_politica_de_privacidade || ''}
                placeholder="ex.:https://quizclass.com.br/politica-privacidade"
                onChange={(e) =>
                  setFieldValue('url_politica_de_privacidade', e.target.value)
                }
              />
              {errors.url_politica_de_privacidade &&
              touched.url_politica_de_privacidade ? (
                <div className="text-danger mt-2">
                  <TiWarning className="me-2" />
                  <small className="pt-2">
                    {errors.url_politica_de_privacidade}
                  </small>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </ol>
    </>
  );
}

export default DadosUsuario;
