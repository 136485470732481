import React from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Container } from './styles';

export default function Apresentacao() {
  const personalizacao = useSelector((state) => state.personalizacao);

  return (
    <Container
      className="pt-5 pb-5"
      backgroundImage={personalizacao.background_image_apresentacao}
      backgroundSize={personalizacao.background_size_apresentacao}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-6 mb-4">
            <div className="wrapper">
              <h2 className="fw-bold" style={{ fontSize: '1.067rem' }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </h2>
            </div>

            <div className="wrapper">
              <p className="m-0" style={{ fontSize: '0.95rem' }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>

            <div className="wrapper">
              <ul>
                <li className="mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </li>
                <li className="mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </li>
                <li className="mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </li>
                <li className="mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <Form className="bg-secondary text-secondary border-radius p-3">
              <h2 className="mb-3">Formulário exemplo:</h2>

              <Form.Group className="mb-3">
                <Form.Label>Seu nome:</Form.Label>
                <input
                  name="nome"
                  type="text"
                  className="form-control form-default"
                  placeholder="Nome"
                  readOnly
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Seu e-mail:</Form.Label>
                <input
                  name="email"
                  type="text"
                  className="form-control form-default"
                  placeholder="E-mail"
                  readOnly
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label
                  className="form-check-label d-flex align-items-center flex-wrap"
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <input
                    className="form-check-input m-0 me-1"
                    type="checkbox"
                    name="communications"
                    data-privacy="true"
                    checked
                    readOnly
                  />
                  <span style={{ fontSize: '0.8em' }}>
                    Eu concordo em receber comunicações.
                  </span>
                </Form.Label>
              </Form.Group>

              <div className="text-left">
                <p style={{ fontSize: '0.8em' }}>
                  <input
                    type="hidden"
                    data-privacy="true"
                    name="privacy_policy"
                    value="1"
                  />
                  <input
                    type="hidden"
                    data-privacy="true"
                    name="terms_of_use"
                    value="1"
                  />
                  Ao informar meus dados, eu concordo com a
                  <a
                    href="https://quizclass.com.br/politica-privacidade"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    <strong style={{fontSize: '0.8rem'}}>Política de Privacidade</strong>
                  </a>{' '}
                  e com os
                  <a
                    href="https://quizclass.com.br/termos-de-uso"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    <strong style={{fontSize: '0.8rem'}}>Termos de Uso</strong>
                  </a>
                  .
                </p>
              </div>

              <button
                type="button"
                className="btn bg-primary text-primary col-12"
              >
                Iniciar Quiz
              </button>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
}
