import React, { useState } from 'react';
import { FaTrash } from 'react-icons/fa';

// import { Container } from './styles';

function FormCondicao({
  index,
  questoesAnteriores,
  removeCondicao,
  handleChange,
  condicao,
}) {
  function tratarPerguntaQuestao(htmlString) {
    // Remover todas as tags HTML
    let semTags = htmlString.replace(/<[^>]*>/g, '');

    // Remover espaços representados por &nbsp;
    let semNbsp = semTags.replace(/&nbsp;/g, '');

    // Remover espaços em branco extras
    let semEspacosExtras = semNbsp.replace(/\s+/g, ' ').trim();

    return semEspacosExtras.slice(0, 50);
  }

  return (
    <>
      {index > 0 && (
        <div className="row">
          <div className="col-10 text-center">
            <select
              className="my-4"
              name="conector"
              onChange={(e) => handleChange(e, index)}
              value={condicao.conector}
            >
              <option value={'OU'}>OU</option>
              <option value={'E'}>E</option>
            </select>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-5">
          <label>Questão</label>
          <select
            name="questao_id"
            className="form-control form-default form-select mb-3"
            required
            value={condicao.questao_id}
            onChange={(e) => {
              handleChange(e, index);
            }}
          >
            <option value="" disabled>
              Selecione uma questão
            </option>
            {questoesAnteriores.map((questao, idx) => (
              <option key={questao.id + idx} value={questao.id}>
                {tratarPerguntaQuestao(questao.pergunta)}
              </option>
            ))}
          </select>
        </div>
        <div className="col-5">
          <label>Opções</label>
          <select
            name="opcao_id"
            onChange={(e) => handleChange(e, index)}
            className="form-control form-default form-select mb-3"
            required
            value={condicao.opcao_id}
          >
            <option value="" disabled>
              Selecione uma opção
            </option>
            {questoesAnteriores
              .find((questao) => questao.id === condicao.questao_id)
              ?.opcoes.map((opcao) => (
                <option key={opcao.id} value={opcao.id}>
                  {opcao.texto}
                </option>
              ))}
          </select>
        </div>
        {index > 0 && (
          <div className="col-2 d-flex align-items-end">
            <button
              className="btn btn-danger mb-3"
              onClick={() => removeCondicao(index)}
              title="Remover condição"
              style={{ height: 50, width: '100%' }}
            >
              <FaTrash size={16} />
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default FormCondicao;
