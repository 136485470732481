import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;

  .content {
    padding: 1rem 1rem;
  }
  .title {
    font-size: 1rem;
    font-weight: 600;
  }
  ${({ type }) =>
    type !== 'BLOCO_LOGICO' &&
    css`
      user-select: none;
      background-color: #fff;
      width: 214px;
    `}
`;

export const Wrapper = styled.div`
  background-color: var(--cinza);
  word-break: break-word;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .pergunta {
    font-size: 1rem;
    font-weight: 600;
  }
`;
