import styled from 'styled-components';

export const CardForm = styled.div`
  /* max-width: 526px; */
  background: ${(props) =>
    props.quandoObterDados !== 'FIM' ? 'var(--amarelo)' : 'none !important'};
  h2 {
    font-weight: bold;
    font-size: 1.333em;
    line-height: 24px;
  }
  .card-footer {
    border: none;
    background: none;
  }
  .check-contato-email {
    label {
      cursor: pointer;
      font-weight: normal;
      /* font-size: 12px; */
    }
    input {
      cursor: pointer;
    }
  }
  ${(props) =>
    props.quandoObterDados === 'FIM' &&
    `
        border: none !important;
        
        .label-nome-campos{
            visibility: hidden;
        }
        
    `};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;

  padding: 3px 3px 0 0;
`;

export const TextoBotaoInput = styled.input`
  background: none;
  text-align: center;
  border: none;
  font-weight: 600;
  color: ${(props) => props.textoPrimario};
  
  &:focus {
    border: 1px ${(props) => props.textoSecundario} solid;
  }
`;
