import { setActiveCampaign } from '../store/activeCampaignSlice';
import apiQuizClass from './apiQuizClass';

export const getActiveCampaignCampos = (quizId) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(
        `/active-campaign?quiz_id=${quizId}`
      );
      dispatch(setActiveCampaign(response.data));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

export const getListasContato = async (activeCampaignId) => {
  try {
    const response = await apiQuizClass.get(
      `/active-campaign/${activeCampaignId}/lista-contatos`
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postActiveCampaignIntegracao = async (
  quizId,
  active_campaign_id,
  active_campaign_lista_id
) => {
  try {
    await apiQuizClass.post(`/active-campaign/${active_campaign_id}`, {
      quiz_id: quizId,
      active_campaign_lista_id
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteActiveCampaignIntegracao = async (quizId) => {
  try {
    await apiQuizClass.delete(`/active-campaign/${quizId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
