import {
  addBlocosQuestaoEditorFluxo,
  setBlocosEditorFluxo,
  setProximoBlocoApresentacao,
  setProximoBlocoBlocoLogico,
  setProximoBlocoOpcao,
} from '../store/editorFluxosSlice';
import apiQuizClass from './apiQuizClass';

export const getBlocosEditorFluxo = () => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(`/editor-fluxo/blocos`);

      dispatch(setBlocosEditorFluxo(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const putApresentacaoProximoBloco = (proximoBlocoQuestaoId) => {
  return async (dispatch) => {
    try {
      await apiQuizClass.put(`/editor-fluxo/apresentacao/proximo-bloco`, {
        proximo_bloco: proximoBlocoQuestaoId,
      });

      dispatch(
        setProximoBlocoApresentacao({
          proximo_bloco: proximoBlocoQuestaoId,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
};

export const putOpcaoProximoBloco = (
  questaoId,
  opcaoId,
  proximo_bloco,
  proximo_bloco_type
) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.put(
        `/editor-fluxo/opcoes/${opcaoId}`,
        {
          proximo_bloco,
        }
      );

      dispatch(
        setProximoBlocoOpcao({
          questaoId: response.data.questao_id,
          opcaoId,
          proximo_bloco,
          proximo_bloco_type,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
};

export const getNewBlocoQuestaoEditorFluxo = (questaoId) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(
        `/editor-fluxo/questoes/${questaoId}`
      );
      dispatch(addBlocosQuestaoEditorFluxo(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const duplicarQuestaoEditorFluxo = (questaoId) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.post(
        `/editor-fluxo/questoes/${questaoId}/duplicar`
      );

      dispatch(addBlocosQuestaoEditorFluxo(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const putPosicaoBloco = async (id, type, posicao) => {
  try {
    await apiQuizClass.put(`/editor-fluxo/posicao`, {
      id,
      type,
      posicao,
    });
  } catch (error) {
    console.error(error);
  }
};

export const putResultadoLink = async (resultadoId, redirect_uri) => {
  try {
    await apiQuizClass.put(`/editor-fluxo/resultados/${resultadoId}`, {
      redirect_uri,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postBlocoLogico = async (quiz_id) => {
  try {
    const response = await apiQuizClass.post(`/editor-fluxo/blocos-logicos`, {
      quiz_id,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const putBlocoLogicoProximoBloco = (
  bloco_logico_id,
  opcao_type,
  proximo_bloco_id,
  proximo_bloco_type
) => {
  return async (dispatch) => {
    try {
      await apiQuizClass.put(
        `/editor-fluxo/blocos-logicos/${bloco_logico_id}`,
        {
          opcao_type,
          proximo_bloco_id,
          proximo_bloco_type,
        }
      );

      dispatch(
        setProximoBlocoBlocoLogico({
          bloco_logico_id,
          opcao_type,
          proximo_bloco_id,
          proximo_bloco_type,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteBlocoLogico = async (bloco_logico_id) => {
  try {
    await apiQuizClass.delete(
      `/editor-fluxo/blocos-logicos/${bloco_logico_id}`
    );
  } catch (error) {
    console.error(error);
  }
};

export const getBlocosAnterioresBlocoLogico = async (bloco_logico_id) => {
  try {
    const { data } = await apiQuizClass.get(
      `/editor-fluxo/blocos-logicos/${bloco_logico_id}/blocos-anteriores`
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};
export const putBlocoLogicoCondicoes = async (bloco_logico_id, condicoes) => {
  try {
    await apiQuizClass.put(
      `/editor-fluxo/blocos-logicos/${bloco_logico_id}/condicao`,
      { condicoes }
    );
  } catch (error) {
    console.error(error);
  }
};
