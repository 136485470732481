import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { Col, Form as FormBootstrap,  Row } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";

import { ContainerEnunciado, ContainerForm } from './../styles';
import { selectIdQuiz, setIntegracaoRDConfigurado } from './../../../store/quizSlice';
import { selectContasRd, contaRdSelecionada, eventoConversao, setEvento, setContaSelected } from './../../../store/RDStationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getContas, saveConfigRd, removeConfigRd } from './../../../services/apiRdStation'
import { toast } from 'react-toastify';

function RDStation() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const idQuiz = useSelector(selectIdQuiz);
    const contas = useSelector(selectContasRd);
    const conta = useSelector(contaRdSelecionada)
    const evento = useSelector(eventoConversao)

    useEffect(() => {
        if (idQuiz) dispatch(getContas(idQuiz));
    }, [dispatch, idQuiz])

  return (
      <>
        <Link to={-1}>voltar</Link>
        <br/><br/>
        <ContainerEnunciado>
            <div>
                <img src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/integracao/rd_icon.webp" alt='RD Station icon' />
            </div>
            <div>
                <h2>Configurar integração com a RD Station Marketing</h2>
                <p>Envie os leads gerados pelo seu quiz para sua conta da RD Station Marketing e assim alimentar suas campanhas de marketing.</p>
            </div>
        </ContainerEnunciado>
        <ContainerForm>
            
        <Formik
            enableReinitialize={true}
            initialValues={{
                conta,
                evento,
            }}
            onSubmit={async (values) => {
                //await new Promise((r) => setTimeout(r, 500));
                const response = await saveConfigRd(idQuiz, values)
                if(response) {
                    dispatch(setContaSelected(values.conta))
                    dispatch(setIntegracaoRDConfigurado(true))
                    navigate(`/app/quizzes/${idQuiz}/integracoes`)
                    toast.success('Configuração com a RD realizada com sucesso!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                } else {
                    toast.error('Falha ao salvar configuração com a RD.', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }
            }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Row>
                            <Col xs="auto">
                                    
                                    <Field as="select" aria-label="Default select example" name="conta" className='form-default form-select'>
                                        <option>Selecione uma conta para vincular ao Quiz</option>
                                        {contas.map( (conta) => (
                                            <option value={conta.id} key={conta.id}>{conta.nome}</option>
                                        ))}
                                    </Field>
                                    
                                    <FormBootstrap.Text>
                                        Caso ainda não tenha uma conta, clique no botão "Cadastrar nova conta da RD"
                                    </FormBootstrap.Text>
                            </Col>
                            <Col xs="auto">
                                <a href={process.env.REACT_APP_BASE_URL_BACKEND+"/app/minha-conta/integracao/rd-station/new?callback="+process.env.REACT_APP_BASE_URL_FRONT+`/app/quizzes/${idQuiz}/integracoes/rdstation/configura`} className="btn btn-primary shadow-default">
                                    Cadastrar nova conta da RD
                                    </a>
                            </Col>
                        </Row>
            
                            <FormBootstrap.Group className="mb-3 mt-4" controlId="formBasicEmail">
                                <FormBootstrap.Label>Nome do evento de conversão</FormBootstrap.Label>
                                <Field name="evento" className="form-default text-muted form-control" />
                                <FormBootstrap.Text className="text-muted">
                                    Ao enviar um lead, enviaremos com o evento de conversão configurado neste campo.
                                </FormBootstrap.Text>
                            </FormBootstrap.Group>
            
                            <button type="submit" className='btn btn-default shadow-default' disabled={isSubmitting}>Configurar integração</button>
                            
                </Form>
            )}
        </Formik>

        <br></br>
        <hr></hr>
        <br></br>
        {conta ?                                       
        <button className='btn btn-sm btn-secondary' onClick={async e => {
            const  response = await removeConfigRd(idQuiz)
            if(response) {
                dispatch(setEvento(''))
                dispatch(setContaSelected(''))
                dispatch(setIntegracaoRDConfigurado(false))
                navigate(`/app/quizzes/${idQuiz}/integracoes`)
                toast.success('Configuração com a RD removida deste quiz!', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
            } else {
                toast.error('Erro ao remover integração.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
            }
            
        }
        }>Remover integração com a RD</button>
        : '' }
        
     </ContainerForm>
         
      </>
  );
}

export default RDStation;