import React, { useState } from 'react';

import EditButton from '../../../../components/Buttons/Edit';
import ModalConfigPopover from '../ModalConfigPopover';
import { Container, ContainerOpcaoIncorporar } from './styles';
import {
  selectQuizPopoverConfig,
  selectSlugQuiz,
} from '../../../../store/quizSlice';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';

function Incorporar({ urlVisualizar }) {
  const [showModalConfigPopover, setShowModalConfigPopover] = useState(false);
  const popoverConfig = useSelector(selectQuizPopoverConfig);
  const { cor_texto_padrao } = useSelector((state) => state.personalizacao);
  const quizSlug = useSelector(selectSlugQuiz);
  const incorporarIframe = `<iframe style="border-radius: 4px;" width="100%" height="600" src="${urlVisualizar}" title="Quiz" frameborder="0" sandbox="allow-same-origin allow-scripts allow-forms allow-popups" allow="camera ${urlVisualizar}; microphone ${urlVisualizar}"></iframe>`;
  const incorporarPopover = `<div id='quiz-popover' data-quiz-id="${quizSlug}" data-quiz-button-color="${popoverConfig.cor_botao_popover}" data-quiz-button-close-color="${cor_texto_padrao}" data-quiz-button-text="Launch me" data-quiz-button-icon="${popoverConfig.imagem_icone_popover}" style="all:unset;"></div> <script src="https://assetsus1.quizclass.com.br/assets/js/quiz-popover.js"></script>`;
  const [opcaoIncorporarSelected, setOpcaoIncorporarSelected] =
    useState('IFRAME');

  const showNotification = (text) => {
    toast.dark(text, {
      autoClose: 2000,
      hideProgressBar: true,
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  return (
    <Container className='mt-3'>
      <label>Incorporar ao meu site</label>
      <div className="d-flex flex-lg-row flex-column gap-3 mb-3">
        <div className="d-flex gap-3">
          <ContainerOpcaoIncorporar
            className="shadow-default border-radius p-1 d-flex flex-column align-items-center justify-content-center"
            onClick={() => setOpcaoIncorporarSelected('IFRAME')}
            active={opcaoIncorporarSelected === 'IFRAME'}
          >
            <div
              className="p-1"
              style={{
                height: 80,
                width: 80,
                border: '0.5px solid #C4C4C4',
              }}
            >
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  background: 'var(--roxo)',
                }}
              ></div>
            </div>
            <small className="text-nowrap mt-1">Toda a página</small>
          </ContainerOpcaoIncorporar>

          <div>
            <ContainerOpcaoIncorporar
              className="shadow-default border-radius p-1 d-flex flex-column align-items-center justify-content-center"
              onClick={() => setOpcaoIncorporarSelected('POPOVER')}
              active={opcaoIncorporarSelected === 'POPOVER'}
            >
              <div
                className="p-1 position-relative"
                style={{
                  height: 80,
                  width: 80,
                  border: '0.5px solid #C4C4C4',
                }}
              >
                <div
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: '50%',
                    background: 'var(--roxo)',
                    position: 'absolute',
                    bottom: 5,
                    right: 5,
                  }}
                ></div>
                <div
                  style={{
                    width: 18,
                    height: 26,
                    background: 'var(--roxo)',
                    position: 'absolute',
                    bottom: 12,
                    right: 12,
                  }}
                ></div>
              </div>
              <small className="text-nowrap mt-1">Popover</small>
            </ContainerOpcaoIncorporar>
            {opcaoIncorporarSelected === 'POPOVER' && (
              <button
                className="btn btn-secondary btn-sm mt-2"
                onClick={() => setShowModalConfigPopover(true)}
                style={{ fontSize: 12, width: '100%' }}
              >
                Personalizar
              </button>
            )}
          </div>
        </div>

        <div style={{ position: 'relative', width: '100%', height: 120 }}>
          <Form.Control
            className="form-control pe-5"
            as="textarea"
            onChange={(e) => {}}
            value={
              opcaoIncorporarSelected === 'IFRAME'
                ? incorporarIframe
                : incorporarPopover
            }
            readOnly
            style={{ resize: 'none', height: '100%', overflow: 'hidden' }}
          />

          <CopyToClipboard
            text={
              opcaoIncorporarSelected === 'IFRAME'
                ? incorporarIframe
                : incorporarPopover
            }
            onCopy={() => showNotification('Copiado!')}
          >
            <div
              className="p-2 border-radius btn"
              title="Copiar"
              style={{
                background: '#D9D9D9',
                position: 'absolute',
                top: 5,
                right: 5,
              }}
            >
              <FaCopy size={24} color={'#FFFFFF'} />
            </div>
          </CopyToClipboard>
        </div>
      </div>

      <ModalConfigPopover
        show={showModalConfigPopover}
        closeModal={() => setShowModalConfigPopover(false)}
      />
    </Container>
  );
}

export default Incorporar;
