import React, { useRef, useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShowModalCropImage,
  toggleShowModalCropImage,
} from '../../../store/showSlice';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { uploadFile } from '../../../services/uploadService';
import { selectIdQuiz } from '../../../store/quizSlice';
import { toast } from 'react-toastify';
import { putMetaConfig } from '../../../services/metaConfigService';
import { setMetaConfigImage } from '../../../store/metaConfigSlice';
import getUrl from '../../../utils/getCacheUrl';

function CropImage() {
  const dispatch = useDispatch();
  const show = useSelector(selectShowModalCropImage);
  const metaConfig = useSelector((state) => state.metaConfig);
  const quizId = useSelector(selectIdQuiz);
  const [crop, setCrop] = useState({
    unit: 'px',
    x: 0,
    y: 0,
    width: 600,
    height: 315,
  });
  const [src, setSrc] = useState(null);
  const image = useRef(null);

  useEffect(() => {
    setCrop({
      unit: 'px',
      x: 0,
      y: 0,
      width: 600,
      height: 315,
    });
    setSrc(null);
  }, [show]);

  const save = (imgUrl) => {
    toast.promise(
      putMetaConfig(quizId, { ...metaConfig, image: imgUrl }).then(() => {
        dispatch(setMetaConfigImage(imgUrl));
        dispatch(toggleShowModalCropImage());
      }),
      {
        pending: 'Salvando...',
        success: 'Salvo!',
        error: 'Erro ao salvar.',
      }
    );
  };

  const selectImage = (event) => {
    const newSrc = URL.createObjectURL(event.target.files[0]);
    setSrc(newSrc);
  };

  const cropImageNow = async () => {
    if (!src) return dispatch(toggleShowModalCropImage());

    const canvas = document.createElement('canvas');
    const scaleX = image.current.naturalWidth / image.current.width;
    const scaleY = image.current.naturalHeight / image.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob((blob) => {
      const type = blob.type.split('/');
      toast.promise(
        uploadFile(
          quizId,
          blob,
          'image',
          'meta-image.' + type[type.length - 1] || 'png'
        ).then((url) => {
          const newUrl = getUrl(url);
          save(newUrl);
        }),
        {
          pending: 'Carregando imagem...',
          success: 'Imagem carregada!',
          error: 'Erro ao carregar imagem.',
        }
      );
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => dispatch(toggleShowModalCropImage())}
      dialogClassName="modal-90w"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Imagem para Redes Sociais (meta)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <center>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              selectImage(e);
            }}
          />
          <br />
          <br />
          <br />
          <div>
            <ReactCrop
              crop={crop}
              onChange={(c) => setCrop(c)}
              aspect={1.91 / 1}
              minWidth={600}
              minHeight={315}
              maxWidth={1200}
              maxHeight={630}
              keepSelection={true}
            >
              <img
                ref={image}
                src={src}
              />
            </ReactCrop>
          </div>
          <br />
          <br />
        </center>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => dispatch(toggleShowModalCropImage())}
        >
          Fechar
        </Button>
        <button
          className="btn btn-default shadow-default ps-4 pe-4"
          onClick={cropImageNow}
        >
          Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default CropImage;
