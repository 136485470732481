import React from 'react';
import { BsFillDiagram2Fill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { selectIdQuiz } from '../../../store/quizSlice';
import PlusButton from '../../../components/Buttons/Plus';
import { Container } from './styles';
import { addBlocosLogicoEditorFluxo } from '../../../store/editorFluxosSlice';
import { postBlocoLogico } from '../../../services/editorFluxo';

function BlocoLogico() {
  const dispatch = useDispatch();
  const idQuiz = useSelector(selectIdQuiz);

  const addBlocoLogico = async () => {
    try {
      const response = await postBlocoLogico(idQuiz);

      dispatch(
        addBlocosLogicoEditorFluxo({
          ...response.data,
          newBlock: true,
        })
      );
    } catch (error) {}
  };

  return (
    <Container className='ps-3'>
      <div>
        <div className={`d-flex flex-row justify-content-between`}>
          <span>
            <BsFillDiagram2Fill /> Bloco lógico
          </span>

          <div>
            <PlusButton onClick={() => addBlocoLogico()} />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default BlocoLogico;
