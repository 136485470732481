import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'personalizacao',
  initialState: {
    cor_fundo_padrao: '',
    cor_fundo_primaria: '',
    cor_fundo_secundaria: '',
    cor_texto_padrao: '',
    cor_texto_primaria: '',
    cor_texto_secundaria: '',
    background_image_apresentacao: '',
    background_image_questao: '',
    background_image_resultado: '',
    background_size_apresentacao: '',
    background_size_questao: '',
    background_size_resultado: '',
  },
  reducers: {
    setPersonalizacao(state, { payload }) {
      return { ...state, ...payload };
    },
    setCorFundoPadrao(state, { payload }) {
      return { ...state, cor_fundo_padrao: payload };
    },
    setCorFundoPrimaria(state, { payload }) {
      return { ...state, cor_fundo_primaria: payload };
    },
    setCorFundoSecundaria(state, { payload }) {
      return { ...state, cor_fundo_secundaria: payload };
    },
    setCortextoPadrao(state, { payload }) {
      return { ...state, cor_texto_padrao: payload };
    },
    setCortextoPrimaria(state, { payload }) {
      return { ...state, cor_texto_primaria: payload };
    },
    setCortextoSecundaria(state, { payload }) {
      return { ...state, cor_texto_secundaria: payload };
    },
    setBackgroundImageApresentacao(
      state,
      { payload: background_image_apresentacao }
    ) {
      return { ...state, background_image_apresentacao };
    },
    setBackgroundImageQuestao(state, { payload: background_image_questao }) {
      return { ...state, background_image_questao };
    },
    setBackgroundImageResultado(
      state,
      { payload: background_image_resultado }
    ) {
      return { ...state, background_image_resultado };
    },
    setBackgroundSizeApresentacao(
      state,
      { payload: background_size_apresentacao }
    ) {
      return { ...state, background_size_apresentacao };
    },
    setBackgroundSizeQuestao(state, { payload: background_size_questao }) {
      return { ...state, background_size_questao };
    },
    setBackgroundSizeResultado(state, { payload: background_size_resultado }) {
      return { ...state, background_size_resultado };
    },
  },
});

export const {
  setPersonalizacao,
  setCorFundoPadrao,
  setCorFundoPrimaria,
  setCorFundoSecundaria,
  setCortextoPadrao,
  setCortextoPrimaria,
  setCortextoSecundaria,
  setBackgroundImageApresentacao,
  setBackgroundImageQuestao,
  setBackgroundImageResultado,
  setBackgroundSizeApresentacao,
  setBackgroundSizeQuestao,
  setBackgroundSizeResultado,
} = slice.actions;

export default slice.reducer;
