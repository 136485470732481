import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { Container, Gabarito } from './styles';

export default function Resultado() {
  const personalizacao = useSelector((state) => state.personalizacao);

  return (
    <Container
      className="pt-5 pb-5"
      backgroundImage={personalizacao.background_image_resultado}
      backgroundSize={personalizacao.background_size_resultado}
    >
      <div className="container">
        <div className="wrapper">
          <h2 className="text-center fw-bold">Você finalizou o teste!</h2>
        </div>
        <div className="wrapper text-center">
          <img
            className="border-radius"
            src="https://quizclass.s3-sa-east-1.amazonaws.com/assets/images/quiz-finalizado.png"
            alt="Imagem exemplo preview"
            style={{ maxWidth: '100%', objectFit: 'contain' }}
          />
        </div>
        <div className="wrapper text-center">
          <p className="m-0 fw-bold">
            Você acertou <strong>2</strong> das <strong>4</strong> questões.
            <br />+ <strong>2.00</strong> pontos
          </p>
        </div>

        <div className="wrapper text-center">
          <Gabarito className="bg-secondary text-secondary d-flex flex-column align-items-center justify-content-center p-3">
            <h2>Gabarito</h2>
            <div className="container text-center p-3">
              <div className="item mb-4">
                Questão 1
                <p className="mb-1">
                  Sua resposta:{' '}
                  <span>
                    (V) (F) <FaCheckCircle color="#0EC120" size={20} />
                  </span>
                </p>
              </div>

              <div className="item mb-4">
                Questão 2
                <p className="mb-1">
                  Sua resposta:{' '}
                  <span>
                    Sem resposta <FaTimesCircle color="#AC1515" size={20} />
                  </span>
                </p>
                <p className="fw-bold">
                  Resposta correta:{' '}
                  <span className="fw-normal">Sim, ou não</span>
                </p>
              </div>

              <div className="item mb-4">
                Questão 3
                <p className="mb-1">
                  Sua resposta:{' '}
                  <span>
                    Teste <FaTimesCircle color="#AC1515" size={20} />
                  </span>
                </p>
                <p className="fw-bold">
                  Resposta correta:{' '}
                  <span className="fw-normal">Aberta 1, ou aberta 2</span>
                </p>
              </div>

              <div className="item mb-4">
                Questão 4
                <p className="mb-1">
                  Sua resposta:{' '}
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}
                    <FaCheckCircle color="#0EC120" size={20} />
                  </span>
                </p>
              </div>
            </div>
          </Gabarito>
        </div>
      </div>
    </Container>
  );
}
