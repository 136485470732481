import React, { useCallback } from 'react';
import DragAndDrop from '../../../../components/DragAndDrop';
import Campo from './Campo';
import produce from 'immer';

// import { Container } from './styles';

function Campos({ configuracao, setFieldValue }) {
  const campoChecked = (campo) => {
    if (campo == 'Nome' && configuracao.obter_nome) {
      return true;
    }
    if (campo == 'E-mail' && configuracao.obter_email) {
      return true;
    }
    if (campo == 'Telefone' && configuracao.obter_telefone) {
      return true;
    }
    if (campo == 'Empresa' && configuracao.obter_empresa) {
      return true;
    }
    if (campo == 'CNPJ' && configuracao.obter_cnpj) {
      return true;
    }
    if (campo == 'CPF' && configuracao.obter_cpf) {
      return true;
    }
    if (campo == 'Cidade' && configuracao.obter_cidade) {
      return true;
    }
    for (const campoSelecionado of configuracao.campos_personalizados_selecionados) {
      if (campoSelecionado.nome === campo) return true;
    }
    return false;
  };

  const removeCampoPersonalizado = (campo) => {
    setFieldValue(
      'campos_personalizados_selecionados',
      configuracao.campos_personalizados_selecionados.filter(
        (campoSelecionado, index) => campoSelecionado.nome !== campo
      )
    );
  };

  const addCampoPersonalizado = (campo) => {
    for (const campoPersonalizado of configuracao.campos_personalizados) {
      if (campoPersonalizado.nome === campo) {
        return setFieldValue('campos_personalizados_selecionados', [
          ...configuracao.campos_personalizados_selecionados,
          campoPersonalizado,
        ]);
      }
    }
  };

  const handleCampos = (campo) => {
    const isChecked = campoChecked(campo);

    if (campo == 'Nome') {
      return setFieldValue('obter_nome', !isChecked);
    }
    if (campo == 'E-mail') {
      return setFieldValue('obter_email', !isChecked);
    }
    if (campo == 'Telefone') {
      return setFieldValue('obter_telefone', !isChecked);
    }
    if (campo == 'Empresa') {
      return setFieldValue('obter_empresa', !isChecked);
    }
    if (campo == 'CNPJ') {
      return setFieldValue('obter_cnpj', !isChecked);
    }
    if (campo == 'CPF') {
      return setFieldValue('obter_cpf', !isChecked);
    }
    if (campo == 'Cidade') {
      return setFieldValue('obter_cidade', !isChecked);
    }

    if (isChecked) {
      removeCampoPersonalizado(campo);
    } else {
      addCampoPersonalizado(campo);
    }
  };

  const moveItem = useCallback(
    (from, to) => {
      const newOrdemCampos = produce(configuracao.ordem_campos, (draft) => {
        const dragged = draft[from];
        draft.splice(from, 1);
        draft.splice(to, 0, dragged);
      });
      setFieldValue('ordem_campos', newOrdemCampos);
    },
    [configuracao.ordem_campos]
  );

  return (
    <ol
      className="form-check d-flex flex-row flex-wrap"
      role="group"
      aria-labelledby="checkbox-group"
      style={{
        gap: '0px 10px',
      }}
    >
      <DragAndDrop>
        {configuracao?.ordem_campos.map((ordemCampo, index) => (
          <Campo
            key={index}
            index={index}
            ordemCampo={ordemCampo}
            campoChecked={campoChecked}
            handleCampos={handleCampos}
            moveItem={moveItem}
          />
        ))}
      </DragAndDrop>
    </ol>
  );
}

export default Campos;
