import React, { useEffect, useState } from 'react';
import Xarrow from 'react-xarrows';
import { BtnDeleteRow, Container } from './styles.js';
import Draggable from './Draggable';
import { useDispatch, useSelector } from 'react-redux';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useEditor } from './Context/index.js';
import { FaTrash } from 'react-icons/fa';
import OffcanvasContent from './OffcanvasContent/index.js';
import { getBlocosEditorFluxo } from '../../services/editorFluxo.js';
import { selectIdQuiz } from '../../store/quizSlice.js';

export default function Editor() {
  const dispatch = useDispatch();
  const idQuiz = useSelector(selectIdQuiz);
  const [loading, setLoading] = useState(true);
  const { apresentacao, questoes, resultados, blocos_logicos } = useSelector(
    (state) => state.editorFluxos
  );

  const {
    setWrapperPosition,
    dragConnectPoint,
    arrows,
    isMoveable,
    updateXarrow,
    deleteArrow,
    setNewArrows,
    wrapperPosition,
  } = useEditor();

  useEffect(() => {
    (async function () {
      try {
        await Promise.all([
          dispatch(getBlocosEditorFluxo()),
          // dispatch(getQuestoes()),
          // dispatch(getResultados()),
        ]);
      } catch (error) {}
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    let newArrows = [];

    if (apresentacao.proximo_bloco) {
      newArrows = [
        ...newArrows,
        {
          start: idQuiz,
          end: apresentacao.proximo_bloco,
          typeStart: 'APRESENTACAO',
        },
      ];
    }

    questoes.forEach((questao) => {
      questao.opcoes.forEach((opcao) => {
        if (opcao.resultado_proximo_bloco) {
          newArrows = [
            ...newArrows,
            {
              start: opcao.id,
              end: opcao.resultado_proximo_bloco,
              typeStart: 'QUESTAO',
            },
          ];
        } else if (opcao.questao_proximo_bloco) {
          newArrows = [
            ...newArrows,
            {
              start: opcao.id,
              end: opcao.questao_proximo_bloco,
              typeStart: 'QUESTAO',
            },
          ];
        } else if (opcao.bloco_logico_proximo_bloco) {
          newArrows = [
            ...newArrows,
            {
              start: opcao.id,
              end: opcao.bloco_logico_proximo_bloco,
              typeStart: 'QUESTAO',
            },
          ];
        }
      });
    });

    blocos_logicos.forEach((blocoLogico) => {
      if (blocoLogico.opcao_sim_questao_proximo_bloco) {
        newArrows = [
          ...newArrows,
          {
            start: 'BLOCO_LOGICO_SIM_' + blocoLogico.id,
            end: blocoLogico.opcao_sim_questao_proximo_bloco,
            typeStart: 'BLOCO_LOGICO',
          },
        ];
      } else if (blocoLogico.opcao_sim_resultado_proximo_bloco) {
        newArrows = [
          ...newArrows,
          {
            start: 'BLOCO_LOGICO_SIM_' + blocoLogico.id,
            end: blocoLogico.opcao_sim_resultado_proximo_bloco,
            typeStart: 'BLOCO_LOGICO',
          },
        ];
      }

      if (blocoLogico.opcao_nao_questao_proximo_bloco) {
        newArrows = [
          ...newArrows,
          {
            start: 'BLOCO_LOGICO_NAO_' + blocoLogico.id,
            end: blocoLogico.opcao_nao_questao_proximo_bloco,
            typeStart: 'BLOCO_LOGICO',
          },
        ];
      } else if (blocoLogico.opcao_nao_resultado_proximo_bloco) {
        newArrows = [
          ...newArrows,
          {
            start: 'BLOCO_LOGICO_NAO_' + blocoLogico.id,
            end: blocoLogico.opcao_nao_resultado_proximo_bloco,
            typeStart: 'BLOCO_LOGICO',
          },
        ];
      } else if (blocoLogico.opcao_nao_bloco_logico_proximo_bloco_fluxo) {
        newArrows = [
          ...newArrows,
          {
            start: 'BLOCO_LOGICO_NAO_' + blocoLogico.id,
            end: blocoLogico.opcao_nao_bloco_logico_proximo_bloco_fluxo,
            typeStart: 'BLOCO_LOGICO',
          },
        ];
      }
    });
    setNewArrows(newArrows);
  }, [
    idQuiz,
    apresentacao,
    questoes,
    resultados,
    setNewArrows,
    blocos_logicos,
  ]);

  return loading ? (
    <div>Carregando...</div>
  ) : (
    <>
      <Container>
        <TransformWrapper
          initialScale={1}
          // disabled={isMoveable.current}
          panning={{ disabled: isMoveable.current }}
          pinch={{ disabled: isMoveable.current }}
          doubleClick={{ disabled: isMoveable.current }}
          wheel={{ step: 0.05, disabled: false }}
          minScale={0.1}
          maxScale={1}
          limitToBounds={false}
          onPanning={(ref) => {
            updateXarrow();
            const { positionX, positionY, scale } = ref.instance.transformState;
            setWrapperPosition({
              x: positionX,
              y: positionY,
              scale,
            });
          }}
          onZoom={(ref) => {
            updateXarrow();
            const { positionX, positionY, scale } = ref.instance.transformState;
            setWrapperPosition({
              x: positionX,
              y: positionY,
              scale,
            });
          }}
          onTransformed={(ref) => {
            updateXarrow();
            const { positionX, positionY, scale } = ref.instance.transformState;
            setWrapperPosition({
              x: positionX,
              y: positionY,
              scale,
            });
          }}
        >
          <TransformComponent
            contentClass="main"
            wrapperStyle={{
              height: '100%',
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <Draggable type="APRESENTACAO" apresentacao={apresentacao} />
            {questoes.map((questao) => (
              <Draggable key={questao.id} questao={questao} type="QUESTAO" />
            ))}

            {blocos_logicos.map((blocoLogico) => (
              <Draggable
                key={blocoLogico.id}
                blocoLogico={blocoLogico}
                type="BLOCO_LOGICO"
              />
            ))}

            {resultados.map((resultado) => (
              <Draggable
                key={resultado.id}
                resultado={resultado}
                type="RESULTADO"
              />
            ))}
          </TransformComponent>
        </TransformWrapper>

        {arrows.map((ar) => (
          <Xarrow
            key={ar.start + '-.' + ar.end}
            start={ar.start}
            end={ar.end}
            // color="var(--cinza)"
            // showHead={false}
            strokeWidth={3 * wrapperPosition.scale}
            labels={
              <BtnDeleteRow
                type="button"
                className="btn"
                onClick={() => deleteArrow(ar.start, ar.typeStart)}
                title="Remover"
                scale={wrapperPosition.scale}
              >
                <FaTrash size={16} />
              </BtnDeleteRow>
            }
          />
        ))}

        {dragConnectPoint.isDragging ? (
          <Xarrow
            start={dragConnectPoint.start}
            end={dragConnectPoint.end}
            // color="var(--cinza)"
            showHead={false}
            strokeWidth={3 * wrapperPosition.scale}
          />
        ) : null}
      </Container>
      <OffcanvasContent />
    </>
  );
}
