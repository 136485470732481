import styled from 'styled-components';

export const Container = styled.div`
  .container-configs-personalizacao,
  .container-preview-personalizacao {
    border-radius: 10px;
    border: 1px solid #D1D1D1;
    padding: 1rem;
  }
`;

export const PreviewPersonalizacao = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  border: 1px solid #d1d1d1;
  border-radius: 10px;
  height: 350px;
`;
