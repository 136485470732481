import React from 'react';
import { useDispatch } from 'react-redux';
import { setOpcaoTexto } from '../../../../../store/questaoSlice';
import { Wrapper } from '../styles';

import { FaTrash } from 'react-icons/fa';

function TextoLongo({ opcao, index, saveOpcao, excluirOpcao }) {
  const dispatch = useDispatch();

  return (
    <Wrapper className="bg-default border-radius">
      <textarea
        type="text"
        className="form-control form-default"
        placeholder="Opção de resposta"
        value={opcao.texto}
        onChange={(e) =>
          dispatch(setOpcaoTexto({ index, texto: e.target.value }))
        }
        onBlur={() => saveOpcao(index, opcao.id)}
      />
      <button
        className="btn flex-center-default"
        onClick={() => excluirOpcao(index, opcao.id)}
        title="Excluir opção"
      >
        <FaTrash color="#FC6D5D" size={17} />
      </button>
    </Wrapper>
  );
}

export default TextoLongo;
