import React, { Children, useCallback } from 'react';
import DragAndDrop from '../DragAndDrop';
import produce from 'immer';
import Item from './Item';

function ReordenarItens({
  children,
  itens = [],
  setItens = () => {},
  left = 0,
}) {
  const moveItem = useCallback(
    (from, to) => {
      const newOrdemItens = produce(itens, (draft) => {
        const dragged = draft[from];
        draft.splice(from, 1);
        draft.splice(to, 0, dragged);
      });
      setItens(newOrdemItens);
    },
    [itens]
  );

  return (
    <DragAndDrop>
      {Children.map(children, (child, index) => (
        <Item
          key={index}
          moveItem={moveItem}
          index={index}
          item={itens[index]}
          left={left}
        >
          {child}
        </Item>
      ))}
    </DragAndDrop>
  );
}

export default ReordenarItens;
