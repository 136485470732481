import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShowModalConfiguracaoDadosUsuario,
  toggleShowModalConfiguracaoDadosUsuario,
} from '../../../store/showSlice';
import Campos from './Campos';
import {  Formik } from 'formik';
import { putConfiguracaoFormulario } from '../../../services/configFormDadosUsuarioService';
import { selectIdQuiz } from '../../../store/quizSlice';
import { toast } from 'react-toastify';

// import { Container } from './styles';

function ConfiguracaoDadosUsuario() {
  const dispatch = useDispatch();
  const show = useSelector(selectShowModalConfiguracaoDadosUsuario);
  const configuracao = useSelector((state) => state.formDadosUsuario);
  const idQuiz = useSelector(selectIdQuiz);

  return (
    <Modal
      show={show}
      onHide={() => dispatch(toggleShowModalConfiguracaoDadosUsuario())}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Editar formulário
        </Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={configuracao}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(putConfiguracaoFormulario(idQuiz, values)).then(() => {
            setSubmitting(false);
            toast.success('Configuração salva!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            dispatch(toggleShowModalConfiguracaoDadosUsuario());
          });
        }}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="container pt-5 d-flex flex-column">
              <div className="form-group mb-4">
                <label className='fw-normal'>
                  * Clique e arraste, caso desejar alterar a ordem dos campos no
                  seu formulário.
                </label>

                <div className="mt-2">
                  <Campos configuracao={values} setFieldValue={setFieldValue} />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() =>
                  dispatch(toggleShowModalConfiguracaoDadosUsuario())
                }
              >
                Fechar
              </Button>
              <button
                className="btn btn-default shadow-default ps-4 pe-4"
                type="submit"
                disabled={isSubmitting}
              >
                Salvar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ConfiguracaoDadosUsuario;
