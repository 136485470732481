import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';

const opcaoDefault = {
  id: null,
  numero_opcao: null,
  resposta_correta: false,
  texto: '',
  valor: '',
  imagem: null,
  active: true,
  resultados_correlacionados: [],
};

export const slice = createSlice({
  name: 'questao',
  initialState: {
    id: null,
    pergunta: '',
    // arquivos_questao: { arquivos_resposta: [], arquivos_explicacao: [] },
    explicacao_resposta: '',
    mostrar_explicacao: true,
    numero_questao: 1,
    obrigatoria: true,
    gatilho: false,
    opcoes: [],
    tempo: 'NONE',
    tipo: 'MULTIPLA_ESCOLHA',
    true_or_false_caracteres: ['V', 'F'],
    valor: 1,
    som_resposta_correta: true,
    som_resposta_errada: true,
    mensagem_resposta_correta: 'Correto!',
    mensagem_resposta_errada: 'Errou!',
    matriz_perguntas: [
      { id: Math.floor(Math.random() * Date.now()), pergunta: '' },
    ],
  },
  reducers: {
    setQuestao(state, { payload }) {
      return payload;
    },
    setOpcoesQuestao(state, { payload: opcoes }) {
      return { ...state, opcoes };
    },
    setTipoQuestao(state, { payload: tipo }) {
      return { ...state, tipo };
    },
    setPergunta(state, { payload: pergunta }) {
      return { ...state, pergunta };
    },
    setExplicacaoResposta(state, { payload: explicacao_resposta }) {
      return { ...state, explicacao_resposta };
    },
    addOpcao(state, { payload: id }) {
      let opcoes = [...state.opcoes];

      opcoes.forEach((opcao, index) => {
        opcoes.numero_opcao = index + 1;
      });

      return {
        ...state,
        opcoes: [
          ...opcoes,
          { ...opcaoDefault, id, numero_opcao: opcoes.length + 1 },
        ],
      };
    },
    setOpcaoRespostaCorreta(state, { payload: { index, resposta_correta } }) {
      const opcoes = produce(state.opcoes, (draft) => {
        draft[index].resposta_correta = resposta_correta;
      });

      return { ...state, opcoes };
    },
    setOpcaoTexto(state, { payload }) {
      const { index, texto } = payload;

      const opcoes = produce(state.opcoes, (draft) => {
        draft[index].texto = texto;
      });

      return { ...state, opcoes };
    },
    setOpcaoImagem(state, { payload: { index, url } }) {
      const opcoes = produce(state.opcoes, (draft) => {
        draft[index].imagem = url;
      });

      return { ...state, opcoes };
    },
    setOpcaoValor(state, { payload: { index, valor } }) {
      const opcoes = produce(state.opcoes, (draft) => {
        draft[index].valor = valor;
      });

      return { ...state, opcoes };
    },
    setTrueOrFalseCaracteres(state, { payload: { trueOrFalse, texto } }) {
      let true_or_false_caracteres = [...state.true_or_false_caracteres];

      if (trueOrFalse) {
        true_or_false_caracteres[0] = texto;
      } else {
        true_or_false_caracteres[1] = texto;
      }
      return { ...state, true_or_false_caracteres };
    },
    setOpcaoId(state, { payload: { index, id } }) {
      const opcoes = produce(state.opcoes, (draft) => {
        draft[index].id = id;
      });

      return { ...state, opcoes };
    },
    removeOpcao(state, { payload: index }) {
      const opcoes = produce(state.opcoes, (draft) => {
        draft.splice(index, 1);

        draft.forEach((opcao, indexOpcao) => {
          draft[indexOpcao].numero_opcao = indexOpcao + 1;
        });
      });

      return {
        ...state,
        opcoes,
      };
    },
    setQuestaoFeedback(
      state,
      {
        payload: {
          som_resposta_correta,
          som_resposta_errada,
          mensagem_resposta_correta,
          mensagem_resposta_errada,
        },
      }
    ) {
      return {
        ...state,
        som_resposta_correta,
        som_resposta_errada,
        mensagem_resposta_correta,
        mensagem_resposta_errada,
      };
    },
    addPergunta(state, { payload: { id, pergunta } }) {
      let matriz_perguntas = [...state.matriz_perguntas];

      matriz_perguntas.push({ id, pergunta });

      return {
        ...state,
        matriz_perguntas,
      };
    },
    removePergunta(state, { payload: id }) {
      let matriz_perguntas = [...state.matriz_perguntas];
      const indexRemove = matriz_perguntas
        .map((matriz) => matriz.id)
        .indexOf(id);

      matriz_perguntas.splice(indexRemove, 1);

      return {
        ...state,
        matriz_perguntas,
      };
    },
    setTextoMatrizPergunta(state, { payload: { id, pergunta } }) {
      let matriz_perguntas = [...state.matriz_perguntas].map((matriz) =>
        matriz.id === id ? { ...matriz, pergunta } : matriz
      );

      return {
        ...state,
        matriz_perguntas,
      };
    },
  },
});

export const {
  setQuestao,
  setPergunta,
  setExplicacaoResposta,
  addOpcao,
  setOpcaoRespostaCorreta,
  setOpcaoTexto,
  setOpcaoImagem,
  setOpcaoValor,
  setTrueOrFalseCaracteres,
  setOpcaoId,
  removeOpcao,
  setQuestaoFeedback,
  setTipoQuestao,
  setOpcoesQuestao,
  addPergunta,
  setTextoMatrizPergunta,
  removePergunta
} = slice.actions;

export const selectOpcoesResposta = (state) => state.questao.opcoes;
export const selectQuestaoId = (state) => state.questao.id;
export const selectQuestaoTipo = (state) => state.questao.tipo;
export const selectQuestaoFeedback = (state) => {
  return {
    som_resposta_correta: state.questao.som_resposta_correta,
    som_resposta_errada: state.questao.som_resposta_errada,
    mensagem_resposta_correta: state.questao.mensagem_resposta_correta,
    mensagem_resposta_errada: state.questao.mensagem_resposta_errada,
  };
};

export default slice.reducer;
