import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Questao from '../../Questao';
import { useDispatch, useSelector } from 'react-redux';
import {
  attContentQuestaoEditorFluxos,
  attResultadoEditorFluxos,
  selectEditorFluxoSelectedEditableContent,
  setSelectedEditableContentEditorFluxo,
} from '../../../store/editorFluxosSlice';
import Resultado from '../../Resultado';
import Apresentacao from '../../Apresentacao';
import { selectResultadosQuiz } from '../../../store/quizSlice';
import BlocoLogico from './BlocoLogico';

export default function OffcanvasContent() {
  const dispatch = useDispatch();
  const questao = useSelector((state) => state.questao);
  const resultados = useSelector(selectResultadosQuiz);

  const selectedEditableContent = useSelector(
    selectEditorFluxoSelectedEditableContent
  );

  const handleClose = () => {
    switch (selectedEditableContent.type) {
      case 'APRESENTACAO':
        break;
      case 'QUESTAO':
        dispatch(
          attContentQuestaoEditorFluxos({
            id: questao.id,
            pergunta: questao.pergunta,
            opcoes: questao.opcoes,
          })
        );
        // dispatch(setQuestaoEditorFluxos(questao));
        break;
      case 'RESULTADO':
        const resultado = resultados.find(
          (resultadoEdit) => resultadoEdit.id === selectedEditableContent.id
        );

        if (resultado)
          dispatch(
            attResultadoEditorFluxos({
              id: selectedEditableContent.id,
              newState: { nome: resultado.nome },
            })
          );
        break;
      default:
        break;
    }

    dispatch(
      setSelectedEditableContentEditorFluxo({
        id: null,
        type: null,
        show: false,
      })
    );
  };

  const pages = {
    APRESENTACAO: <Apresentacao />,
    QUESTAO: <Questao questaoId={selectedEditableContent.id} />,
    RESULTADO: <Resultado resultadoId={selectedEditableContent.id} />,
    BLOCO_LOGICO: <BlocoLogico blocoLogicoId={selectedEditableContent.id} />,
  };

  return (
    <Offcanvas
      show={selectedEditableContent.show}
      onHide={handleClose}
      placement="end"
      className="offcanvas-editor-fluxo"
    >
      <Offcanvas.Header closeButton>
        {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
      </Offcanvas.Header>
      <Offcanvas.Body>
        {selectedEditableContent.type && pages[selectedEditableContent.type]
          ? pages[selectedEditableContent.type]
          : ''}
      </Offcanvas.Body>
    </Offcanvas>
  );
}
