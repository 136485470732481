import { setPersonalizacao } from '../store/personalizacaoSlice';
import apiQuizClass from './apiQuizClass';

export const getPersonalziacao = (quizId) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(
        `/quizzes/${quizId}/personalizacao`
      );
      dispatch(setPersonalizacao(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const putPersonalizacao = async (quizId, data) => {
  try {
    await apiQuizClass.put(`/quizzes/${quizId}/personalizacao`, data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
