import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root{
    --amarelo: #FFE132;
    --azul: #0047FF;
    --preto: #4C4637;
    --offWhite: #f8f8f8;
    --primary: #0047FF;
    --secondary: #FFE132;
    --danger: #EC1C1C;
    --success: #58CC02;
    --primary-light: #305fb8;
    --cinza: #E3DDCB;
    --roxo: #8A64F7;
    /* --toastify-toast-min-height: 34px;
    --toastify-toast-max-height: 35px; */
    --ck-z-default: 1000;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-size: 15px;
  }

  html, body, #root-quiz-editor {
    height: 100%;
    .modal-90w{
      width: 90vw;
      max-width: none!important;
    }
    .modal-80h{
      min-height: 80vh;
    }
  } 

  body {
    font-family: 'Montserrat', sans-serif;
    color: var(--preto);
    -webkit-font-smoothing: antialiased !important;
    .tribute-container {
      z-index: 100;
      ul {
        padding: 0 !important;
        border-radius: 2px;
        box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
        border: 1px solid hsl(0, 0%, 75%);
        li {
          cursor: pointer;
          padding: 10px 5px;
          background-color: #FFFF;
          &.highlight{
            background-color: #2977ff;
            color: #ffffff;
          }
        }
      }
    }
  }

  html{
    font-size: 93.75%; //15px

    @media (max-width: 1080px) {
      font-size: 93.75%; //15px
    }

    @media (max-width: 720px) {
      font-size: 87.5%; //14px
    }
  }

  a{
    color: inherit;
    text-decoration: none;
    &:hover{
      color: inherit;
    }
  }

  li {
    list-style: none;
  }

  [disabled]{
    opacity: 0.6;
    cursor: not-allowed !important;
  }

  .btn {
    font-weight: 600;
    vertical-align: center !important;
    font-size: 1rem;
    border: none;
    padding: 15px;
    border-radius: 4px;
    transition: 0.2s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    cursor: pointer;

    &:hover{
      filter: brightness(0.9);
    }
  }

  .btn-default{
    height: 50px;
    background: var(--amarelo);
    color: var(--preto);
  }

  .btn-primary{
    height: 50px;
    background: var(--azul);
    color: white;
  }

  .btn-sm {
    padding: 10px;
    height: 36px !important;
    line-height: 1 !important;
  }

  .btn-md {
    padding: 10px 20px;
    height: 40px !important;
  }

  .btn-lg {
    padding: 10px 20px;
    height: 56px !important;
  }

  .btn-secondary{
    background: var(--cinza);
    color: var(--preto);
  }

  .btn-danger{
    background: var(--danger);
  }

  .shadow-default {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .shadow-default:hover {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  }

  .align-items-center {
    display: flex;
    align-items: center;
  }

  .align-self-center{
    align-self: center;
  }

  .border-radius-right {
    border-radius: 0 5px 5px 0;
  }

  .border-radius-left {
    border-radius: 5px 0 0 5px;
  }

  .border-radius {
    border-radius: 5px;
  }

  .border-radius-4 {
    border-radius: 4px;
  }

  .border-radius-10 {
    border-radius: 10px;
  }

  h1 {
    font-size: 2.667rem;
  }
  h2 {
    font-size: 1.467rem;
    font-weight: bold;
    margin-bottom: 0;
  }
  h4 {
    font-size: 1rem;
    font-weight: bold;
  }
  p {
    font-size: 0.8rem;
  }
  label {
    font-weight: bold;
    margin-bottom: 0.2rem;
  }

  hr {
    border: 1px solid #DCD9D2;
  }

  .pb-2 {
    padding-bottom: 20px;
  }

  .pb-3 {
    padding-bottom: 30px;
  }
  .form-control {
    .border-dark {
      border: 1px solid var(--preto);
    }
  }
  .border-dark {
    border: 1px solid $darkPrimary;
  }
  .border-gray{
    border: 1px solid #D1D1D1;
  }

  .form-control:focus {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid var(--amarelo);
    box-sizing: border-box;
  }

  .form-default {
    height: 50px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .shadow-default {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .shadow-default:hover {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  }

  .bg-default{
    background-color: var(--amarelo);
  }

  .bg-gray{
    background-color: var(--cinza);
  }

  .animate-pulse{
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes pulse {
      0%, 100% {
        opacity: 1;
      }
      50% {
        opacity: .5;
      }
    }
  }

  .height-20{
    height: 20px !important;
  }

  .offcanvas-editor-fluxo{
    width: 70vw;
    @media (max-width: 991px) {
      width: 100vw;
    }
  }

`;
