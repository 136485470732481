import styled from 'styled-components';

export const Container = styled.div``;
export const Breadcrumb = styled.div`
  margin-bottom: -1px;
  display: flex;
  .item {
    padding: 19px 40px;
    border-radius: 10px 10px 0 0;
    transition: 0.2s;
    cursor: pointer;
    @media (max-width: 991px) {
      padding: 0.5rem 1rem;
    }
  }
`;

export const ContentContainer = styled.div`
  padding: 21px 17px;
  border-radius: 0 10px 10px 10px;
`;

export const Content = styled.div`
  ul,
  ol {
    padding-left: 0px;
  }
  ul li {
    list-style: initial;
    list-style-position: inside;
  }
  ol li {
    list-style: decimal;
    list-style-position: inside;
  }

  background-color: ${({ backgroundPadrao }) => backgroundPadrao} !important;
  color: ${({ textoPadrao }) => textoPadrao} !important;

  .bg-default {
    background-color: ${({ backgroundPadrao }) => backgroundPadrao} !important;
  }
  .bg-primary {
    background-color: ${({ backgroundPrimario }) =>
      backgroundPrimario} !important;
  }
  .bg-secondary {
    background-color: ${({ backgroundSecundario }) =>
      backgroundSecundario} !important;
  }

  .text-default {
    color: ${({ textoPadrao }) => textoPadrao} !important;
  }
  .text-primary {
    color: ${({ textoPrimario }) => textoPrimario} !important;
  }
  .text-secondary {
    color: ${({ textoSecundario }) => textoSecundario} !important;
  }

  .form-control:focus {
    border: 1px solid ${({ backgroundPrimario }) => backgroundPrimario} !important;
  }

  .wrapper {
    padding: 6px 0;
    margin: 0 0 10px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: normal;
    }

    b,
    span,
    i,
    u {
      font-size: inherit;
    }
  }
`;
