import React from 'react';
import { useSelector } from 'react-redux';
import BackgroundImage from './BackgroundImage';
import ColorWrapper from './ColorWrapper';
// import { Container } from './styles';

function Configs() {
  const personalizacao = useSelector((state) => state.personalizacao);

  return (
    <>
      <h4>Selecione as cores do template</h4>
      <br />
      <label className="">Cores base</label>
      <ColorWrapper
        cor="base"
        corFundo={personalizacao.cor_fundo_padrao}
        corTexto={personalizacao.cor_texto_padrao}
      />
      <br />
      <label className="">Cores principais</label>
      <ColorWrapper
        cor="principal"
        corFundo={personalizacao.cor_fundo_primaria}
        corTexto={personalizacao.cor_texto_primaria}
      />
      <br />
      <label className="">Cores secundárias</label>
      <ColorWrapper
        cor="secundaria"
        corFundo={personalizacao.cor_fundo_secundaria}
        corTexto={personalizacao.cor_texto_secundaria}
      />

      <hr />

      <h4>Selecione as imagens de background do template</h4>
      <br />
      <label>Apresentação</label>
      <BackgroundImage
        page="apresentacao"
        backgroundImage={personalizacao.background_image_apresentacao}
        backgroundSize={personalizacao.background_size_apresentacao}
      />
      <br />
      <label>Questões</label>
      <BackgroundImage
        page="questao"
        backgroundImage={personalizacao.background_image_questao}
        backgroundSize={personalizacao.background_size_questao}
      />
      <br />
      <label>Resultados</label>
      <BackgroundImage
        page="resultado"
        backgroundImage={personalizacao.background_image_resultado}
        backgroundSize={personalizacao.background_size_resultado}
      />
    </>
  );
}

export default Configs;
