export default class BlocoRedirecionamento {
  static get toolbox() {
    return {
      title: 'Botão',
      icon: '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg>',
    };
  }

  constructor({ api, data }) {
    this.api = api;
    this.data = Object.keys(data).length ? data : { textoBotao: 'Continuar', url: '' };
  }

  render() {
    const wrapper = document.createElement('div');
    wrapper.className='cdx-block';
    const button = document.createElement('button');
    button.className = 'btn btn-primary p-0';
    // button.textContent = 'Continuar';

    const input = document.createElement('input');
    input.setAttribute('name', 'textoBotao');
    input.className = 'form-control';
    input.style =
      'background: none;text-align: center;border: none;font-weight: 600;';
    input.style.height = '100%';
    input.style.width = '100%';
    input.value = this.data.textoBotao || 'Continuar';    

    const labelUrl = document.createElement('label');
    labelUrl.textContent = 'Endereço de destino';

    const inputUrl = document.createElement('input');
    inputUrl.setAttribute('name', 'url');
    inputUrl.className = 'form-control';
    inputUrl.placeholder = 'ex.:https://quizclass.com.br';
    inputUrl.value = this.data.url || '';    

    const divInputUrl = document.createElement('div');

    divInputUrl.appendChild(labelUrl);
    divInputUrl.appendChild(inputUrl);
    divInputUrl.style.display = 'none';

    button.appendChild(input);
    wrapper.appendChild(button);
    wrapper.appendChild(divInputUrl);

    input.onfocus = e => {
      divInputUrl.style.display = '';

    }

    wrapper.addEventListener('focusout', function (e) {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        divInputUrl.style.display = 'none';
      }
    });

    return wrapper;
  }

  save(blockContent) {
    const inputs = [...blockContent.getElementsByTagName('input')];

    let data = {};

    inputs.forEach((input) => {
      data = { ...data, [input.name]: input.value };
    });

    return data;
  }
}
