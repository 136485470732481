import styled from 'styled-components';

export const Container = styled.div`
  ol.config {
    counter-reset: item;
    li.config-item {
      &:before {
        content: counters(item, '.') '. ';
        counter-increment: item;
      }
    }

    ol.sub-config {
      counter-reset: subItem;
      li.sub-config-item {
        &:before {
          content: counters(item, '.') '.' counters(subItem, '.') '. ';
          counter-increment: subItem;
        }
      }
    }
  }

  input[type='radio'] {
    visibility: hidden;
    position: absolute;
  }

  input[type='radio'] + label {
    font-weight: normal;
    cursor: pointer;

    &:before {
      height: 12px;
      width: 12px;
      margin-right: 10px;
      content: ' ';
      display: inline-block;
      vertical-align: baseline;
      border: none;
      background: var(--cinza);
      border-radius: 50%;
    }
  }
  input[type='radio']:checked + label {
    font-weight: bold;
    &:before {
      background-color: var(--amarelo);
    }
  }
`;
