import apiQuizClass from './apiQuizClass';
import apiVimeo from './apiVimeo';
import { getTotalSizeUpload } from './planoAcesso';
import { Upload as tusUpload } from 'tus-js-client';

export const uploadFile = async (quizId, file, type = 'image', name = null) => {
  if (type === 'audio') {
    const { atingiuLimiteUpload } = await getTotalSizeUpload();
    if (atingiuLimiteUpload) throw 'atingiu limite de upload!';
  }

  try {
    const formData = new FormData();
    if (name) {
      formData.append('file', file, name);
    } else {
      formData.append('file', file);
    }
    formData.append('quiz_id', quizId);
    formData.append('type', type);

    const response = await apiQuizClass.post(`/quizzes/upload`, formData);

    return response.data.url;
  } catch (error) {
    console.log(error);
    throw 'Erro, tente novamente!';
  }
};

export const uploadVideo = async (file) => {
  const { atingiuLimiteUpload } = await getTotalSizeUpload();

  if (atingiuLimiteUpload) {
    throw 'atingiu limite de upload!';
  }

  let responseVimeo = null;

  try {
    const upload = {
      upload: {
        approach: 'tus',
        size: file.size,
      },
      name: file.name,
    };
    responseVimeo = await apiVimeo.post('/me/videos', JSON.stringify(upload));
  } catch (e) {
    throw 'Erro no upload!';
  }

  return new Promise(async (resolve, reject) => {
    let uploadEndPoint = responseVimeo.data.upload.upload_link;

    const uploader = new tusUpload(file, {
      uploadUrl: uploadEndPoint,
      retryDelays: [0, 1000, 3000, 5000],
      uploadSize: file.size,
      onError: function (error) {
        console.log('Failed because: ' + error);
        return reject(error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        var percentComplete = (bytesUploaded / bytesTotal) * 100;
        console.log(percentComplete.toFixed(2) + '%');
        console.log(percentComplete + '%');
      },
      onSuccess: function () {
        saveNewFileUploaded(
          responseVimeo.data.link,
          'video',
          'VIMEO',
          file.size
        );
        return resolve(responseVimeo.data.link);
      },
    });

    uploader.start();
  });
};

export async function saveNewFileUploaded(url, type, provider, size) {
  const data = {
    url,
    type,
    provider,
    size,
  };

  apiQuizClass.post('file-uploaded', data);
}
