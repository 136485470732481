import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'qualificacao',
  initialState: {
    qualificacoes: [
      { id: '', regra: '', valor: 0, questaoId: null, opcaoId: null },
    ],
  },
  reducers: {
    setQualificacoes(state, { payload: qualificacoes }) {
      return { ...state, qualificacoes };
    },
    addQualificacao(state, { payload: id }) {
      let newQualificacao = [...state.qualificacoes];

      newQualificacao.push({
        id,
        regra: '',
        valor: 0,
        questaoId: null,
        opcaoId: null,
      });

      return { ...state, qualificacoes: newQualificacao };
    },
    removeQualificacao(state, { payload: index }) {
      let removeQualificacao = [...state.qualificacoes];

      removeQualificacao = removeQualificacao.filter(
        (qualificacao, qualificacaoIndx) => qualificacaoIndx !== index
      );

      return { ...state, qualificacoes: removeQualificacao };
    },
  },
});

export const { setQualificacoes, addQualificacao, removeQualificacao } =
  slice.actions;

export const selectQualificacoes = (state) => state.qualificacao.qualificacoes;

export default slice.reducer;
