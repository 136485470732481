import { addOpcao, setOpcaoId } from '../store/questaoSlice';
import apiQuizClass from './apiQuizClass';

export const getOpcoes = async (questaoId) => {
  try {
    const response = await apiQuizClass.get(`/opcoes/${questaoId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const postOpcao = (questaoId) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.post(`/opcoes/${questaoId}`);

      dispatch(addOpcao(response.data.id));
    } catch (error) {
      console.error(error);
    }
  };
};

export const putOpcao = async (opcaoId, data) => {
  try {
    await apiQuizClass.put(`/opcoes/${opcaoId}`, data);
  } catch (error) {
    console.error(error);
  }
};

export const deleteOpcao = async (opcaoId) => {
  try {
    await apiQuizClass.delete(`/opcoes/${opcaoId}`);
  } catch (error) {
    console.error(error);
  }
};
