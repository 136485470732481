import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDominioStatusCname,
  selectDominioUrlVisualizarQuiz,
} from '../../../../store/dominioSlice';
import {
  selectSlugQuiz,
  selectSubdomainQuiz,
} from '../../../../store/quizSlice';
import {
  selectShowModalPreviewDesktop,
  toggleShowModalPreviewDesktop,
} from '../../../../store/showSlice';
import { getUrl } from '../../../../utils/urlResponderQuiz';

// import { Container } from './styles';

function Desktop() {
  const dispatch = useDispatch();
  const show = useSelector(selectShowModalPreviewDesktop);
  const quizSlug = useSelector(selectSlugQuiz);
  const subdomain = useSelector(selectSubdomainQuiz).toLowerCase();
  const refModalBody = useRef(null);
  const urlVisualizar = getUrl(subdomain, quizSlug);
  const dominioStatusCname = useSelector(selectDominioStatusCname);
  const dominioUrlVisualizarQuiz =
    useSelector(selectDominioUrlVisualizarQuiz) + '/' + quizSlug;

  useEffect(() => {
    if (refModalBody.current?.clientHeight)
      refModalBody.current.innerHTML = `
        <iframe
            width="100%"
            height="${refModalBody.current.clientHeight}"
            src="${
              dominioStatusCname ? dominioUrlVisualizarQuiz : urlVisualizar
            }?preview=true"
            title="Quiz"
            frameBorder="0"
            sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
            allow="camera ${
              dominioStatusCname ? dominioUrlVisualizarQuiz : urlVisualizar
            }; microphone ${
        dominioStatusCname ? dominioUrlVisualizarQuiz : urlVisualizar
      }"
        ></iframe>
    `;
  }, [refModalBody.current, show]);

  return (
    <Modal
      show={show}
      onHide={() => dispatch(toggleShowModalPreviewDesktop())}
      dialogClassName="modal-90w"
      contentClassName="modal-80h"
    >
      <Modal.Header closeButton>
        <Modal.Title>Preview Desktop</Modal.Title>
      </Modal.Header>
      <Modal.Body ref={refModalBody}></Modal.Body>
    </Modal>
  );
}

export default Desktop;
