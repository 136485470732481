import React from 'react';
import { AiOutlineBgColors } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { putApresentacao } from '../../../services/apresentacaoService';
import {
  selectIdQuiz,
  selectQuizShowApresentacao,
} from '../../../store/quizSlice';
import { FaRegSave } from 'react-icons/fa';
import { putQuizShowApresentacao } from '../../../services/quizService';

export function ToolbarConfiguracoesVisuais({ fonte, handleFonte }) {
  const dispatch = useDispatch();
  const idQuiz = useSelector(selectIdQuiz);
  const apresentacaoState = useSelector((state) => state.apresentacao);
  const showApresentacao = useSelector(selectQuizShowApresentacao);

  const saveApresentacao = async () => {
    toast.promise(putApresentacao(idQuiz, apresentacaoState), {
      pending: 'Salvando...',
      success: 'Salvo com sucesso!',
      error: 'Erro ao Salvar.',
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const toggleShowApresentacao = () => {
    dispatch(putQuizShowApresentacao(idQuiz, !showApresentacao));
  };

  return (
    <>
      <span>Configurações visuais: </span>
      <Link
        to={`/app/quizzes/${idQuiz}/template/personalizacao`}
        className="btn btn-sm btn-secondary toolbar-item text-nowrap"
      >
        <AiOutlineBgColors color="var(--preto)" size={20} /> Personalizar cores
      </Link>

      <button
        className="btn btn-sm btn-secondary toolbar-item"
        onClick={() => saveApresentacao()}
      >
        <FaRegSave /> Salvar
      </button>

      <div >
        <select
          className="form-control form-select"
          value={fonte}
          onChange={handleFonte}
        >
          <option value="" disabled>
            Fontes
          </option>
          <option value="Montserrat">Montserrat</option>
          <option value="Source Sans Pro">Source Sans Pro</option>
          <option value="Roboto Condensed">Roboto Condensed</option>
          <option value="Oswald">Oswald</option>
          <option value="Slabo 27px">Slabo 27px</option>
          <option value="Lato">Lato</option>
          <option value="Open sans">Open sans</option>
          <option value="Roboto">Roboto</option>
        </select>
      </div>

      <button
        className="btn btn-secondary btn-md d-flex align-items-center step-5-publicar text-nowrap ms-auto"
        onClick={toggleShowApresentacao}
      >
        <span>
          {showApresentacao ? 'Remover apresentação' : 'Mostrar apresentação'}
        </span>
      </button>
    </>
  );
}
