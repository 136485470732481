import { Field, Formik } from 'formik';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { putResultadoNome } from '../../../services/resultadoService';
import { selectResultadosQuiz } from '../../../store/quizSlice';
import {
  selectShowModalEditNomeResultado,
  toggleShowModalEditNomeResultado,
} from '../../../store/showSlice';

function EditNomeResultado() {
  const dispatch = useDispatch();
  const { show, resultadoId } = useSelector(selectShowModalEditNomeResultado);
  const resultados = useSelector(selectResultadosQuiz);
  const resultado = resultados.filter(
    (resultadoEdit) => resultadoEdit.id === resultadoId
  )[0];

  return (
    <Modal
      show={show}
      onHide={() =>
        dispatch(
          toggleShowModalEditNomeResultado({ show: false, resultadoId: '' })
        )
      }
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Resultado</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={resultado}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(putResultadoNome(resultadoId, values.nome)).then(() => {
            setSubmitting(false);
            toast.success('Configuração salva!');
            dispatch(
              dispatch(
                toggleShowModalEditNomeResultado({
                  show: false,
                  resultadoId: '',
                })
              )
            );
          });
        }}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="container d-flex flex-column">
              <div className="form-group mb-3">
                <label>Nome do resultado</label>
                <Field
                  className="form-control form-default"
                  name="nome"
                  maxLength="20"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() =>
                  dispatch(
                    toggleShowModalEditNomeResultado({
                      show: false,
                      resultadoId: '',
                    })
                  )
                }
              >
                Fechar
              </Button>
              <button
                className="btn btn-default shadow-default ps-4 pe-4"
                type="submit"
                disabled={isSubmitting}
              >
                Salvar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default EditNomeResultado;
