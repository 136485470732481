import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDominioStatusCname,
  selectDominioUrlVisualizarQuiz,
} from '../../../../store/dominioSlice';
import {
  selectSlugQuiz,
  selectSubdomainQuiz,
} from '../../../../store/quizSlice';
import {
  selectShowModalPreviewMobile,
  toggleShowModalPreviewMobile,
} from '../../../../store/showSlice';
import { getUrl } from '../../../../utils/urlResponderQuiz';

// import { Container } from './styles';

function Mobile() {
  const dispatch = useDispatch();
  const show = useSelector(selectShowModalPreviewMobile);
  const quizSlug = useSelector(selectSlugQuiz);
  const subdomain = useSelector(selectSubdomainQuiz).toLowerCase();
  const urlVisualizar = getUrl(subdomain, quizSlug);
  const dominioStatusCname = useSelector(selectDominioStatusCname);
  const dominioUrlVisualizarQuiz =
    useSelector(selectDominioUrlVisualizarQuiz) + '/' + quizSlug;

  return (
    <Modal
      size="md"
      show={show}
      onHide={() => dispatch(toggleShowModalPreviewMobile())}
    >
      <Modal.Header closeButton>
        <Modal.Title>Preview Mobile</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <iframe
          className="border-radius"
          width="393"
          height="851"
          src={`${
            dominioStatusCname ? dominioUrlVisualizarQuiz : urlVisualizar
          }?preview=true`}
          title="Quiz"
          frameBorder="0"
          sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
          allow={`camera ${
            dominioStatusCname ? dominioUrlVisualizarQuiz : urlVisualizar
          }; microphone ${
            dominioStatusCname ? dominioUrlVisualizarQuiz : urlVisualizar
          }`}
        ></iframe>
      </Modal.Body>
    </Modal>
  );
}

export default Mobile;
