import React from 'react';
import PlusButton from '../../../components/Buttons/Plus';
import { Container, ListQuestoes } from './styles';
import { NavLink, useParams } from 'react-router-dom';
import {
  selectIdQuiz,
  selectNaturezaQuiz,
  selectQuestoesQuiz,
  setQuizQuestoes,
} from '../../../store/quizSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toggleShowModalTiposQuestao } from '../../../store/showSlice';
import ModalTiposQuestao from '../../../components/Modal/TiposQuestao';
import { FaList } from 'react-icons/fa';
import ReordenarItens from '../../../components/ReordenarItens';
import { putReordenaQuestoes } from '../../../services/quizService';

function Questoes({ desktop }) {
  const dispatch = useDispatch();
  const questoes = useSelector(selectQuestoesQuiz);
  const idQuiz = useSelector(selectIdQuiz);
  const naturezaQuiz = useSelector(selectNaturezaQuiz);
  const { quizId } = useParams();

  const addQuestao = () => {
    dispatch(toggleShowModalTiposQuestao({ show: true, type: 'CREATE' }));
  };

  const setNovaOrdemQuestoes = (novaOrdemQuestoes) => {
    const newQuestoes = novaOrdemQuestoes.map((questao, index) => {
      return { ...questao, numero_questao: index + 1 };
    });

    dispatch(setQuizQuestoes(newQuestoes));
    putReordenaQuestoes(idQuiz, newQuestoes);
  };

  const tratarTextQuestao = (pergunta, index) => {
    return `${index + 1} - ${pergunta
      .replace(/<[^>]+>/g, '')
      .substring(0, 10)}...`;
  };

  return (
    <Container className={naturezaQuiz === 'FLUXO' ? 'ps-3' : ''}>
      <div className="d-flex flex-row justify-content-between">
        <span>
          <FaList /> Questões{' '}
        </span>
        <div className={desktop ? 'step-3-addQuestao' : ''}>
          <PlusButton onClick={addQuestao} />
        </div>
      </div>
      {naturezaQuiz !== 'FLUXO' && (
        <ListQuestoes className="pe-5 pe-lg-0">
          <ReordenarItens
            itens={questoes}
            setItens={setNovaOrdemQuestoes}
            left={5}
          >
            {questoes.map((questao, index) => (
              <li key={index} className="ms-3">
                <NavLink
                  to={`/app/quizzes/${quizId}/questoes/${questao.id}`}
                  className={({ isActive }) => (isActive ? 'active' : '')}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        [
                          'MATRIZ_SELECAO_UNICA',
                          'MATRIZ_SELECAO_MULTIPLA',
                        ].includes(questao.tipo) &&
                        questao.matrizPerguntas?.length &&
                        questao.matrizPerguntas[0]?.pergunta
                          ? tratarTextQuestao(
                              questao.matrizPerguntas[0].pergunta,
                              index
                            )
                          : questao.pergunta
                          ? tratarTextQuestao(questao.pergunta, index)
                          : `${index + 1} - Nova questão`,
                    }}
                  ></span>
                </NavLink>
              </li>
            ))}
          </ReordenarItens>
        </ListQuestoes>
      )}

      <ModalTiposQuestao />
    </Container>
  );
}

export default Questoes;
