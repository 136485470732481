import { Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { getListasContato } from '../../../../../services/apiActiveCampaign';

// import { Container } from './styles';

function ListasDeContatos({
  active_campaign_lista_id,
  contaActiveCampaignSelecionadaId,
}) {
  const [listas, setListas] = useState([]);

  useEffect(() => {
    const getListas = async () => {
      try {
        const data = await getListasContato(contaActiveCampaignSelecionadaId);
        let newListas = [];
        data.lists.forEach((lista) => {
          newListas.push({ id: lista.id, name: lista.name });
        });
        setListas(newListas);
      } catch (error) {
        console.log(error);
      }
    };

    if (contaActiveCampaignSelecionadaId) getListas();
  }, [contaActiveCampaignSelecionadaId]);

  return (
    <Form.Group className="mb-3">
      <Form.Label>Escolha uma lista de contatos</Form.Label>
      <Field
        as="select"
        name="active_campaign_lista_id"
        className="form-default form-select"
        disabled={!listas.length}
        value={active_campaign_lista_id}
        required
      >
        <option>Selecione uma lista para vincular ao Quiz</option>
        {listas.map((lista) => (
          <option value={lista.id} key={lista.id}>
            {lista.name}
          </option>
        ))}
      </Field>
    </Form.Group>
  );
}

export default ListasDeContatos;
