import styled from 'styled-components';

export const Container = styled.div``;

export const Actions = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  padding: 3px;
  width: 100%;
`;
