const tiposQuestao = {
  MULTIPLA_ESCOLHA: 'Múltipla escolha',
  UNICA_ESCOLHA: 'Única escolha',
  UNICA_ESCOLHA_IMAGEM: 'Única escolha por imagem',
  MULTIPLA_ESCOLHA_IMAGEM: 'Múltipla escolha por imagem',
  V_F: 'V ou F',
  RESPOSTA_VOZ: 'Reconhecimento de voz',
  ABERTA: 'Aberta',
  TEXTO_LONGO: 'Texto longo',
  TEXTO_CURTO: 'Texto curto',
  DATA: 'Data',
  NUMERO: 'Número',
  ORDENAR: 'Ordenar',
  MATRIZ_SELECAO_UNICA: 'Escala de seleção única',
  MATRIZ_SELECAO_MULTIPLA: 'Escala de seleção múltipla',
  NPS: 'Nota (NPS)',
};

export const getTextTipoQuestao = (tipoQuestao) => {
  return tiposQuestao[tipoQuestao];
};
