import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';
import { putPersonalizacao } from '../../services/personalizacaoService';
import { selectIdQuiz } from '../../store/quizSlice';
import { Col, Row } from 'react-bootstrap';
import Configs from '../../components/Personalizacao/Configs';
import Preview from '../../components/Personalizacao/Preview';
import { toggleShowToolbar } from '../../store/showSlice';
import { setPersonalizacao } from '../../store/personalizacaoSlice';

function Personalizacao() {
  const dispatch = useDispatch();
  const quizId = useSelector(selectIdQuiz);
  const personalizacao = useSelector((state) => state.personalizacao);
  const isFirstRender = useRef(true);
  const isSetPrevState = useRef(false);
  const prevPersonalizacaoRef = useRef(null);
  useEffect(() => {
    prevPersonalizacaoRef.current = personalizacao;
  },[]);
  const prevPersonalizacaoState =
    prevPersonalizacaoRef.current ?? personalizacao;

  const savePersonalizacao = async () => {
    try {
      await putPersonalizacao(quizId, personalizacao);
    } catch (error) {
      if (error.response.status === 403) {
        isSetPrevState.current = true;
        dispatch(setPersonalizacao(prevPersonalizacaoState));
      }
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (!isSetPrevState.current) {
      savePersonalizacao();
    } else isSetPrevState.current = false;
  }, [personalizacao]);

  useEffect(() => {
    dispatch(toggleShowToolbar({ show: true, type: 'PERSONALIZACAO' }));
    return () => {
      dispatch(toggleShowToolbar({ show: false, type: '' }));
    };
  }, []);

  return (
    <Container className="container">
      <Row>
        <Col
          className="shadow-default container-configs-personalizacao bg-white"
          lg="3"
        >
          <Configs />
        </Col>

        <Col className="p-0 pt-3 pt-lg-0 ps-lg-2" lg="9">
          <Preview />
        </Col>
      </Row>
    </Container>
  );
}

export default Personalizacao;
