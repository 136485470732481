import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import audioIcon from './icons/audio.svg';
import Command from '@ckeditor/ckeditor5-core/src/command';

export default class Audio extends Plugin {
  init() {
    const editor = this.editor;

    editor.commands.add('audioUpload', new AudioUploadCommand(editor));

    editor.ui.componentFactory.add('audioUpload', (locale) => {
      const command = editor.commands.get('audioUpload');

      const view = new ButtonView(locale);

      view.set({
        label: 'Inserir áudio',
        icon: audioIcon,
        tooltip: true,
      });

      view.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');

      editor.listenTo(view, 'execute', () => editor.execute('audioUpload'));

      return view;
    });
  }
}

class AudioUploadCommand extends Command {
  execute() {}
}
