import React, { useState } from 'react';

import { Container, Breadcrumb, ContentContainer, Content } from './styles';
import Apresentacao from './Apresentacao';
import Questao from './Questao';
import Resultado from './Resultado';
import { useSelector } from 'react-redux';

function Preview() {
  const personalizacao = useSelector((state) => state.personalizacao);
  const [pagePreview, setPagePreview] = useState('APRESENTACAO');

  return (
    <Container>
      <Breadcrumb>
        <div
          className={
            pagePreview === 'APRESENTACAO'
              ? 'item border-gray bg-white border-bottom-0'
              : 'item border-gray bg-gray'
          }
          onClick={() => setPagePreview('APRESENTACAO')}
        >
          Apresentação
        </div>
        <div
          className={
            pagePreview === 'QUESTAO'
              ? 'item border-gray bg-white border-bottom-0'
              : 'item border-gray bg-gray'
          }
          onClick={() => setPagePreview('QUESTAO')}
        >
          Questão
        </div>
        <div
          className={
            pagePreview === 'RESULTADO'
              ? 'item border-gray bg-white border-bottom-0'
              : 'item border-gray bg-gray'
          }
          onClick={() => setPagePreview('RESULTADO')}
        >
          Resultado
        </div>
      </Breadcrumb>

      <ContentContainer className="border-gray shadow-default bg-white">
        <p>Pré-visualização da personalização do seu quiz.</p>
        <Content
          className="border-radius-10 border-gray shadow-default"
          backgroundPadrao={personalizacao.cor_fundo_padrao}
          backgroundPrimario={personalizacao.cor_fundo_primaria}
          backgroundSecundario={personalizacao.cor_fundo_secundaria}
          textoPadrao={personalizacao.cor_texto_padrao}
          textoPrimario={personalizacao.cor_texto_primaria}
          textoSecundario={personalizacao.cor_texto_secundaria}
          backgroundImageApresentacao={
            personalizacao.background_image_apresentacao
          }
          backgroundImageQuestao={personalizacao.background_image_questao}
          backgroundImageResultado={personalizacao.background_image_resultado}
          backgroundSizeApresentacao={
            personalizacao.background_size_apresentacao
          }
          backgroundSizeQuestao={personalizacao.background_size_questao}
          backgroundSizeResultado={personalizacao.background_size_resultado}
        >
          {pagePreview === 'APRESENTACAO' ? (
            <Apresentacao />
          ) : pagePreview === 'QUESTAO' ? (
            <Questao />
          ) : (
            <Resultado />
          )}
        </Content>
      </ContentContainer>
    </Container>
  );
}

export default Preview;
