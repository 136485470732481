import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'metaConfig',
  initialState: {
    titulo: '',
    descricao: '',
    image: '',
  },
  reducers: {
    setMetaConfig(state, { payload }) {
      return payload;
    },
    setMetaConfigTitulo(state, { payload: titulo }) {
      return { ...state, titulo };
    },
    setMetaConfigDescricao(state, { payload: descricao }) {
      return { ...state, descricao };
    },
    setMetaConfigImage(state, { payload: image }) {
      return { ...state, image };
    },
  },
});

export const {
  setMetaConfig,
  setMetaConfigTitulo,
  setMetaConfigDescricao,
  setMetaConfigImage,
} = slice.actions;

export default slice.reducer;
