import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import videoIcon from './icons/video.svg';
import Command from '@ckeditor/ckeditor5-core/src/command';

export default class Video extends Plugin {
  init() {
    const editor = this.editor;

    editor.commands.add('videoUpload', new VideoUploadCommand(editor));

    editor.ui.componentFactory.add('videoUpload', (locale) => {
      const command = editor.commands.get('videoUpload');

      const view = new ButtonView(locale);

      view.set({
        label: 'Inserir vídeo',
        icon: videoIcon,
        tooltip: true,
      });

      view.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');

      editor.listenTo(view, 'execute', () => editor.execute('videoUpload'));

      return view;
    });
  }
}

class VideoUploadCommand extends Command {
  execute() {
    // const editor = this.editor;
    // const model = this.editor.model;
    // model.change((writer) => {
    //   const videoElement = writer.createElement('media', {
    //     url: 'https://vimeo.com/692994500',
    //   });
    //   model.insertContent(videoElement, model.document.selection);
    // });
    // editor.editing.view.focus();
  }
}
