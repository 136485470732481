import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import {
  FaChevronLeft,
  FaDesktop,
  FaMobileAlt,
  FaRegPaperPlane,
} from 'react-icons/fa';
import {
  Container,
  Header,
  HeaderLeft,
  HeaderRight,
  Main,
  Content,
  NomeQuiz,
} from './styles';
import { Toolbar } from '../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectNomeQuiz,
  setQuizNome,
  selectQuizPublicado,
  selectNaturezaQuiz,
} from '../store/quizSlice';
import { getQuiz, putQuizNome } from '../services/quizService';
import { getPersonalziacao } from '../services/personalizacaoService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  selectShowToolbar,
  toggleShowModalPreviewDesktop,
  toggleShowModalPreviewMobile,
} from '../store/showSlice';
import Aside from './Aside';
import ShowMenu991Width from './ShowMenu991Width';
import ModalPreviewResponderQuizDesktop from '../components/Modal/PreviewResponderQuiz/Desktop';
import ModalPreviewResponderQuizMobile from '../components/Modal/PreviewResponderQuiz/Mobile';
import { isMobile } from 'react-device-detect';
import { toggleStatusPublicarQuiz } from '../utils/publicarQuiz';
import ModalRestricaoPlano from '../components/Modal/RestricaoPlano';
import Onboarding from './Onboarding';
import { getDominio } from '../services/dominioService';

function Layout() {
  const dispatch = useDispatch();
  const quizNome = useSelector(selectNomeQuiz);
  const quizNatureza = useSelector(selectNaturezaQuiz);
  const { show: showToolbar } = useSelector(selectShowToolbar);
  const { quizId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const publicado = useSelector(selectQuizPublicado);
  const contentContainerRef = useRef(null);
  const [widthContent, setWidthContent] = useState(0);

  const saveNomeQuiz = () => {
    putQuizNome(quizId, quizNome);
  };

  const saveStatusPublicado = () => {
    toggleStatusPublicarQuiz(quizId, publicado, dispatch);
  };

  useEffect(() => {
    const getInitialConfig = async () => {
      try {
        const [quizData] = await Promise.all([
          dispatch(getQuiz(quizId)),
          dispatch(getPersonalziacao(quizId)),
          dispatch(getDominio()),
        ]);

        document.title = quizData.nome + ' | Quiz Class';

        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    };

    getInitialConfig();
  }, [quizId, dispatch]);

  useEffect(() => {
    if (isMobile)
      toast.warn(
        'Para ter uma melhor experiência neste Editor de Quiz, acesse via computador desktop.',
        { autoClose: false, closeOnClick: false }
      );
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = contentContainerRef.current?.offsetWidth;

      setWidthContent(newWidth);
    };
    updateWindowDimensions();

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <Container>
      <ToastContainer />

      <ShowMenu991Width />

      <Onboarding />

      <Header className="d-none d-lg-flex">
        <HeaderLeft>
          <a
            className="btn-voltar"
            href={`${process.env.REACT_APP_BASE_URL_BACKEND}/app/admin/quiz`}
          >
            <FaChevronLeft />
            <img
              src="https://assetsus1.quizclass.com.br/assets/images/logoquizclass.svg"
              alt="Logo QuizClass"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          </a>

          <strong>
            Quiz:{' '}
            <NomeQuiz
              className="step-1-nomeQuiz"
              value={quizNome}
              onChange={(e) => dispatch(setQuizNome(e.target.value))}
              maxLength="100"
              onBlur={saveNomeQuiz}
            />
          </strong>
        </HeaderLeft>

        <HeaderRight>
          <span>Visualzar quiz</span>
          <button
            className="btn btn-sm btn-secondary btn-device"
            onClick={() => dispatch(toggleShowModalPreviewDesktop())}
          >
            <FaDesktop color="var(--preto)" size={24} />
          </button>

          <button
            className="btn btn-sm btn-secondary btn-device"
            style={{ marginRight: 15 }}
            onClick={() => dispatch(toggleShowModalPreviewMobile())}
          >
            <FaMobileAlt color="var(--preto)" size={24} />
          </button>

          <button
            className="btn btn-secondary btn-md d-flex align-items-center step-5-publicar"
            onClick={saveStatusPublicado}
          >
            <FaRegPaperPlane className="me-2" color="var(--preto)" size={14} />
            <span>{publicado ? 'Despublicar' : 'Publicar'}</span>
          </button>
        </HeaderRight>
      </Header>

      <Aside className="d-none d-lg-flex" />

      <Main widthContent={widthContent}>
        {quizNatureza !== 'FLUXO' && <Toolbar />}
        <Content
          ref={contentContainerRef}
          showToolbar={quizNatureza === 'FLUXO' ? false : showToolbar}
        >
          {isLoading ? <div>Carregando...</div> : <Outlet />}
        </Content>
      </Main>

      <ModalPreviewResponderQuizDesktop />
      <ModalPreviewResponderQuizMobile />
      <ModalRestricaoPlano />
    </Container>
  );
}

export default Layout;
