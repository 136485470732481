import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getConfiguracaoQuiz,
  putConfiguracaoQuiz,
} from '../../services/quizService';
import { setConfiguracao } from '../../store/configuracaoSlice';
import { selectIdQuiz, selectNaturezaQuiz } from '../../store/quizSlice';
import DadosUsuario from './DadosUsuario';
import { Container } from './styles';
import * as Yup from 'yup';
import validateUrl from '../../utils/validateUrl';
import { TiWarning } from 'react-icons/ti';

const ValidateFormSchema = Yup.object().shape({
  url_callback: Yup.string()
    // .required('preencha este campo.')
    .test(
      'is-url-valid',
      'URL inválido: um esquema explícito (como https) deve ser fornecido.',
      (value) => validateUrl(value)
    ),
  url_politica_de_privacidade: Yup.string()
    // .required('preencha este campo.')
    .test(
      'is-url-valid',
      'URL inválido: um esquema explícito (como https) deve ser fornecido.',
      (value) => validateUrl(value)
    ),
});

function Configuracao() {
  const dispatch = useDispatch();
  const idQuiz = useSelector(selectIdQuiz);
  const configuracao = useSelector((state) => state.configuracao);
  const [isLoading, setIsLoading] = useState(true);
  const natureza = useSelector(selectNaturezaQuiz);

  useEffect(() => {
    if (idQuiz) {
      dispatch(getConfiguracaoQuiz(idQuiz)).then(() => setIsLoading(false));
    }
  }, [dispatch, idQuiz]);

  const save = (data, setSubmitting) => {
    toast.promise(
      putConfiguracaoQuiz(idQuiz, data).then(() => {
        dispatch(setConfiguracao(data));
        setSubmitting(false);
      }),
      {
        pending: 'Salvando...',
        success: 'Configuração salva!',
        error: {
          render({ data }) {
            setSubmitting(false);
            return 'Erro ao salvar Configuração.';
          },
        },
      }
    );
  };

  return (
    <Container className="col-11">
      <h2>Vamos configurar nosso quiz</h2>
      <p className="mb-4">
        Aqui vamos efetuar algumas configurações muito importantes, caso tenha
        alguma dúvida{' '}
        <Link to="#">
          <strong style={{ fontSize: '0.8rem' }}>
            clique aqui e assita um tutorial
          </strong>
        </Link>
        .
      </p>
      {isLoading ? (
        <div>Carregando...</div>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{ ...configuracao }}
          onSubmit={(values, { setSubmitting }) => {
            save(values, setSubmitting);
          }}
          validationSchema={ValidateFormSchema}
        >
          {({
            values,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <ol className="config p-0">
                <DadosUsuario
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />

                <Form.Group className="mb-3">
                  <Form.Label>
                    <li className="config-item">
                      No fim do Quiz, deve ser mostrado o resultado?
                    </li>
                  </Form.Label>
                  <div
                    className="m-2"
                    role="group"
                    aria-labelledby="radio-group-mostrar-resultado"
                  >
                    <Form.Check
                      inline
                      label="Sim"
                      name="mostrar_nota"
                      type="radio"
                      checked={values.mostrar_nota === 'MOSTRAR'}
                      onChange={(e) =>
                        setFieldValue(
                          'mostrar_nota',
                          e.target.checked && 'MOSTRAR'
                        )
                      }
                      id="input-mostrar-nota-MOSTRAR"
                    />
                    <Form.Check
                      inline
                      label="Não, após minha conferência irei enviar as notas"
                      name="mostrar_nota"
                      type="radio"
                      checked={values.mostrar_nota === 'CONFERENCIA'}
                      onChange={(e) =>
                        setFieldValue(
                          'mostrar_nota',
                          e.target.checked && 'CONFERENCIA'
                        )
                      }
                      id="input-mostrar-nota-CONFERENCIA"
                    />
                    <Form.Check
                      inline
                      label=" Não, Quero que envie a nota para o e-mail cadastrado"
                      name="mostrar_nota"
                      type="radio"
                      checked={values.mostrar_nota === 'ENVIAR_EMAIL'}
                      onChange={(e) =>
                        setFieldValue(
                          'mostrar_nota',
                          e.target.checked && 'ENVIAR_EMAIL'
                        )
                      }
                      id="input-mostrar-nota-ENVIAR_EMAIL"
                    />

                    <br />
                    <Form.Check
                      inline
                      label="Não mostrar resultado, e não enviar por e-mail"
                      name="mostrar_nota"
                      type="radio"
                      checked={values.mostrar_nota === 'NAO_MOSTRAR'}
                      onChange={(e) => {
                        setFieldValue(
                          'mostrar_nota',
                          e.target.checked && 'NAO_MOSTRAR'
                        );
                      }}
                      id="input-mostrar-nota-NAO_MOSTRAR"
                    />
                  </div>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    <li className="config-item">
                      Gostaria de definir um tempo máximo para responder ao
                      Quiz?
                    </li>
                  </Form.Label>
                  <div
                    className="m-2"
                    role="group"
                    aria-labelledby="radio-group-definir-tempo-resposta"
                  >
                    <Form.Check
                      inline
                      label="Sim"
                      name="definir_tempo_resposta"
                      type="radio"
                      checked={values.definir_tempo_resposta}
                      onChange={(e) =>
                        setFieldValue(
                          'definir_tempo_resposta',
                          e.target.checked
                        )
                      }
                      id={`definir-tempo-resposta-sim`}
                    />
                    <Form.Check
                      inline
                      label="Não"
                      name="definir_tempo_resposta"
                      type="radio"
                      checked={!values.definir_tempo_resposta}
                      onChange={(e) =>
                        setFieldValue(
                          'definir_tempo_resposta',
                          !e.target.checked
                        )
                      }
                      id={`definir-tempo-resposta-nao`}
                    />
                  </div>
                </Form.Group>
                <ol className="sub-config">
                  {values.definir_tempo_resposta && (
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <li className="sub-config-item">
                          Qual é o tempo máximo (Informe em minutos)?
                        </li>
                      </Form.Label>
                      <Field
                        className="form-control form-default"
                        type="number"
                        min="1"
                        name="tempo"
                        value={values.tempo || 1}
                        style={{
                          width: 130,
                        }}
                      />
                    </Form.Group>
                  )}
                </ol>

                {natureza === 'CERTO_ERRADO' && (
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <li className="config-item">
                        Mostrar se acertou ou errou ao fim de cada questão?
                      </li>
                    </Form.Label>
                    <div
                      className="m-2"
                      role="group"
                      aria-labelledby="radio-group-mostrar-correcao"
                    >
                      <Form.Check
                        inline
                        label="Sim"
                        name="mostrar_correcao"
                        type="radio"
                        checked={values.mostrar_correcao}
                        onChange={(e) =>
                          setFieldValue('mostrar_correcao', e.target.checked)
                        }
                        id={`mostrar-correcao-sim`}
                      />
                      <Form.Check
                        inline
                        label="Não"
                        name="mostrar_correcao"
                        type="radio"
                        checked={!values.mostrar_correcao}
                        onChange={(e) =>
                          setFieldValue('mostrar_correcao', !e.target.checked)
                        }
                        id={`mostrar-correcao-nao`}
                      />
                    </div>
                  </Form.Group>
                )}

                <Form.Group className="mb-3">
                  <Form.Label className="m-0">
                    <li className="config-item">
                      Passar para a próxima questão automaticamente ao escolher
                      uma opção?
                    </li>
                  </Form.Label>
                  <br />
                  <small>
                    * Esta configuração se aplica apenas em questões do tipo
                    única escolha.
                  </small>
                  <div
                    className="m-2"
                    role="group"
                    aria-labelledby="radio-group-proxima-questao"
                  >
                    <Form.Check
                      inline
                      label="Sim"
                      name="passar_para_proxima_questao_automaticamente"
                      type="radio"
                      checked={
                        values.passar_para_proxima_questao_automaticamente
                      }
                      onChange={(e) =>
                        setFieldValue(
                          'passar_para_proxima_questao_automaticamente',
                          e.target.checked
                        )
                      }
                      id={`passar_para_proxima_questao_automaticamente-sim`}
                    />
                    <Form.Check
                      inline
                      label="Não"
                      name="passar_para_proxima_questao_automaticamente"
                      type="radio"
                      checked={
                        !values.passar_para_proxima_questao_automaticamente
                      }
                      onChange={(e) =>
                        setFieldValue(
                          'passar_para_proxima_questao_automaticamente',
                          !e.target.checked
                        )
                      }
                      id={`passar_para_proxima_questao_automaticamente-nao`}
                    />
                  </div>
                </Form.Group>
                {natureza !== 'FLUXO' && (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label className="m-0">
                        <li className="config-item">Pode voltar questões?</li>
                      </Form.Label>

                      <div
                        className="m-2"
                        role="group"
                        aria-labelledby="radio-group-proxima-questao"
                      >
                        <Form.Check
                          inline
                          label="Sim"
                          name="pode_voltar_questao"
                          type="radio"
                          checked={values.pode_voltar_questao}
                          onChange={(e) =>
                            setFieldValue(
                              'pode_voltar_questao',
                              e.target.checked
                            )
                          }
                          id={`pode_voltar_questao-sim`}
                        />
                        <Form.Check
                          inline
                          label="Não"
                          name="pode_voltar_questao"
                          type="radio"
                          checked={!values.pode_voltar_questao}
                          onChange={(e) =>
                            setFieldValue(
                              'pode_voltar_questao',
                              !e.target.checked
                            )
                          }
                          id={`pode_voltar_questao-nao`}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="m-0">
                        <li className="config-item">
                          Mostrar questões aleatoriamente?
                        </li>
                      </Form.Label>

                      <div
                        className="m-2"
                        role="group"
                        aria-labelledby="radio-group-mostra-aleatoria"
                      >
                        <Form.Check
                          inline
                          label="Sim"
                          name="mostra_aleatoria"
                          type="radio"
                          checked={values.mostra_aleatoria}
                          onChange={(e) =>
                            setFieldValue('mostra_aleatoria', e.target.checked)
                          }
                          id={`mostra_aleatoria-sim`}
                        />
                        <Form.Check
                          inline
                          label="Não"
                          name="mostra_aleatoria"
                          type="radio"
                          checked={!values.mostra_aleatoria}
                          onChange={(e) =>
                            setFieldValue('mostra_aleatoria', !e.target.checked)
                          }
                          id={`mostra_aleatoria-nao`}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>
                        <li className="config-item">
                          Ao finalizar o Quiz, deseja encaminhar o usuário para
                          um outro destino?
                        </li>
                      </Form.Label>
                      <div
                        className="m-2"
                        role="group"
                        aria-labelledby="radio-group-redirecionar-usuario"
                      >
                        <Form.Check
                          inline
                          label="Sim"
                          name="redirecionar_usuario"
                          type="radio"
                          checked={values.redirecionar_usuario}
                          onChange={(e) =>
                            setFieldValue(
                              'redirecionar_usuario',
                              e.target.checked
                            )
                          }
                          id={`redirecionar-usuario-sim`}
                        />
                        <Form.Check
                          inline
                          label="Não"
                          name="redirecionar_usuario"
                          type="radio"
                          checked={!values.redirecionar_usuario}
                          onChange={(e) =>
                            setFieldValue(
                              'redirecionar_usuario',
                              !e.target.checked
                            )
                          }
                          id={`redirecionar-usuario-nao`}
                        />
                      </div>
                    </Form.Group>

                    <ol className="sub-config">
                      {values.redirecionar_usuario && (
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <li className="sub-config-item">
                              Endereço de destino?
                            </li>
                          </Form.Label>
                          <Field
                            type="url"
                            // pattern="https?:\/\/*"
                            className="form-control form-default"
                            name="url_callback"
                            value={values.url_callback || ''}
                            placeholder="ex.:https://quizclass.com.br"
                            onChange={(e) =>
                              setFieldValue('url_callback', e.target.value)
                            }
                          />
                          {errors.url_callback && touched.url_callback ? (
                            <div className="text-danger mt-2">
                              <TiWarning className="me-2" />
                              <small className="pt-2">
                                {errors.url_callback}
                              </small>
                            </div>
                          ) : null}
                        </Form.Group>
                      )}
                    </ol>
                  </>
                )}

                <button
                  className="btn btn-default shadow-default ps-4 pe-4 mt-3"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Salvar
                </button>
              </ol>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
}

export default Configuracao;
