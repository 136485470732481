
export default async function consulta(campo, setResponse, campoDependente) {

    let url = campo.endpoint_fonte_externa;

    
    if(campo.depende_outro_campo){
        url = url.split('{value}');
        url = url.join(campoDependente);
    }

    fetch(url)
    .then(res => res.json())
    .then(
        (result) => {
                setResponse(result)
        },
        (error) => {
            console.log(error)
        }
    )
}
