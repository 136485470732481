import styled from 'styled-components';

export const Container = styled.div`
  height: 550px;
  position: relative;
  border-radius: 10px;
  background-image: ${(props) => `url(${props.backgroundImage}) !important`};
  background-size: ${(props) => `${props.backgroundSize} !important`};
  background-repeat: no-repeat !important;
  background-position: center !important;

  .content {
    padding: 15px;
    overflow: auto;
    max-height: 480px;
    padding-bottom: 70px;
  }
`;

export const Opcao = styled.div`
  padding: 15px 30px;
  cursor: pointer;
  & + & {
    margin-top: 20px;
  }

  .indice-opcao {
    min-width: 25px;
    min-height: 25px;
    font-size: 1.333em;
    font-weight: 700;
    padding-right: 25px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  padding: 10px 0;

  position: absolute;
  bottom: 0;
`;

export const ButtonFooter = styled.button``;

export const QtdQuestoes = styled.span`
  font-size: 0.92em;
  font-weight: 700;
`;
