import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'dominio',
  initialState: {
    url_visualizar_quiz: '',
    subdominio: '',
    status_cname: false,
  },
  reducers: {
    setDominio(state, { payload }) {
      return payload;
    },
  },
});

export const { setDominio } = slice.actions;

export const selectDominioUrlVisualizarQuiz = (state) =>
  state.dominio.url_visualizar_quiz?.toLowerCase();

export const selectDominioStatusCname = (state) => state.dominio.status_cname;

export default slice.reducer;
