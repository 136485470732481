import { setConfiguracaoForm } from '../store/formDadosUsuarioSlice';
import apiQuizClass from './apiQuizClass';

export const getConfiguracaoFormulario = (quizId) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(
        `/quizzes/${quizId}/configuracao/formulario`
      );
      dispatch(setConfiguracaoForm(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const putConfiguracaoFormulario = (quizId, configuracao) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.put(
        `/quizzes/${quizId}/configuracao/formulario`,
        configuracao
      );
      dispatch(setConfiguracaoForm(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};
