import { configureStore } from '@reduxjs/toolkit';
import apresentacaoReducer from './apresentacaoSlice';
import quizReducer from './quizSlice';
import personalizacaoReducer from './personalizacaoSlice';
import showReducer from './showSlice';
import rdstationReducer from './RDStationSlice';
import formDadosUsuarioReducer from './formDadosUsuarioSlice';
import questaoReducer from './questaoSlice';
import resultadoReducer from './resultadoSlice';
import configuracaoReducer from './configuracaoSlice';
import qualificacaoReducer from './qualificacaoSlice';
import metaConfigReducer from './metaConfigSlice';
import activeCampaignReducer from './activeCampaignSlice';
import dominioReducer from './dominioSlice';
import editorFluxosReducer from './editorFluxosSlice';

export default configureStore({
  reducer: {
    quiz: quizReducer,
    apresentacao: apresentacaoReducer,
    personalizacao: personalizacaoReducer,
    show: showReducer,
    rdstation: rdstationReducer,
    formDadosUsuario: formDadosUsuarioReducer,
    questao: questaoReducer,
    resultado: resultadoReducer,
    configuracao: configuracaoReducer,
    qualificacao: qualificacaoReducer,
    metaConfig: metaConfigReducer,
    activeCampaign: activeCampaignReducer,
    dominio: dominioReducer,
    editorFluxos: editorFluxosReducer,
  },
});
