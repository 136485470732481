import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  div.drag-and-drop-item {
    opacity: 0;
    transition: 0.3s;
    padding: 0.5rem !important;
    position: absolute;
    top: 50%;
    left: ${({ left }) => left + 'px'};
    transform: translate(-50%, -50%);
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
  &:hover {
    div.drag-and-drop-item {
      opacity: 1;
    }
  }
`;
