import styled from 'styled-components';

export const ContainerEnunciado = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  img {
    margin-top: 5px;
    width: 40px;
  }
`;

export const IntegracoesDisponiveis = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 30px;
  gap: 20px;
  pointer-events: ${(props) => props.disabled && "none"};
  
  @media (max-width: 991px) {
    /* align-items: center;
    justify-content: space-between; */
  }
`;

export const IntegracaoItem = styled.div`
  width: 150px;
  height: 150px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  /* margin: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h6 {
    font-size: 15px;
    padding: 10px 15px 0px 15px;
    text-align: center;
  }
  &:first-child {
    margin-left: 0;
  }

  :hover {
    cursor: pointer;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    transition: 0.7s;
  }

  a {
    text-align: center;
  }

  border: ${(props) => (props.selected ? '1px solid var(--azul)' : 'none')};
`;

export const ContainerForm = styled.div`
  width: 80%;
`;
