import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { postQuestao, putQuestao } from '../../../services/questaoService';
import {
  selectIdQuiz,
  selectNaturezaQuiz,
  selectResultadosQuiz,
  setQuizResultadoFaixa,
} from '../../../store/quizSlice';
import {
  selectShowModalTiposQuestao,
  toggleShowModalTiposQuestao,
} from '../../../store/showSlice';
import { Info, Tipo } from './styles';
import {
  FaCheckSquare,
  FaRegImage,
  FaCheck,
  FaTimes,
  FaMicrophone,
  FaRegCalendarAlt,
} from 'react-icons/fa';
import { MdRadioButtonChecked } from 'react-icons/md';
import { FcCheckmark } from 'react-icons/fc';
import { BiText } from 'react-icons/bi';
import { selectQuestaoTipo, setTipoQuestao } from '../../../store/questaoSlice';
import { getTextTipoQuestao } from '../../../utils/tipoQuestao';
import { useNavigate } from 'react-router-dom';
import { addBlocosQuestaoEditorFluxo } from '../../../store/editorFluxosSlice';
import { getNewBlocoQuestaoEditorFluxo } from '../../../services/editorFluxo';

function TiposQuestao() {
  const dispatch = useDispatch();
  const { show, type } = useSelector(selectShowModalTiposQuestao);
  const idQuiz = useSelector(selectIdQuiz);
  const tiposQuestao = useSelector(selectQuestaoTipo);
  const questao = useSelector((state) => state.questao);
  const naturezaQuiz = useSelector(selectNaturezaQuiz);
  const navigate = useNavigate();
  const resultados = useSelector(selectResultadosQuiz);

  const handleQuestao = (tipoQuestao) => {
    if (type === 'CREATE') {
      dispatch(postQuestao(idQuiz, tipoQuestao)).then((data) => {
        if (naturezaQuiz !== 'FLUXO') {
          navigate(`/app/quizzes/${idQuiz}/questoes/${data.id}`);
        } else {
          dispatch(getNewBlocoQuestaoEditorFluxo(data.id));
        }
        dispatch(toggleShowModalTiposQuestao({ show: false, type: '' }));

        if (resultados.length === 1 && data.resultado)
          dispatch(
            setQuizResultadoFaixa({
              resultadoId: data.resultado.id,
              valor_inicial: data.resultado.valor_inicial,
              valor_final: data.resultado.valor_final,
            })
          );
      });
    } else if (type === 'EDIT') {
      putQuestao(questao.id, { ...questao, tipo: tipoQuestao }).then(() => {
        dispatch(setTipoQuestao(tipoQuestao));
        dispatch(toggleShowModalTiposQuestao({ show: false, type: '' }));
      });
    }
  };

  const getTextQuizNatureza = () => {
    if (naturezaQuiz === 'CERTO_ERRADO') {
      return 'avaliação';
    }

    if (naturezaQuiz === 'PONTUACAO') {
      return 'pontuação';
    }

    if (naturezaQuiz === 'PERSONALIDADE') {
      return 'personalidade';
    }

    if (naturezaQuiz === 'PESQUISA') {
      return 'pesquisa';
    }

    if (naturezaQuiz === 'FLUXO') {
      return 'fluxo';
    }
  };

  return (
    <Modal
      show={show}
      onHide={() =>
        dispatch(toggleShowModalTiposQuestao({ show: false, type: '' }))
      }
      size="lg"
      centered
    >
      <Modal.Header style={{ border: 'none' }} closeButton>
        <Modal.Title>
          Tipos de questões disponíveis para quiz do tipo{' '}
          {getTextQuizNatureza()}
          <br />
          <Info>
            Selecione um tipo de resposta para{' '}
            {type === 'CREATE' ? 'criar' : 'editar'} sua questão
          </Info>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          <div className="col-12 col-lg-4 mb-3">
            <label className="mb-2">Escolhas</label>
            <div>
              {!['PONTUACAO', 'FLUXO'].includes(naturezaQuiz) && (
                <>
                  <Tipo
                    isActive={
                      tiposQuestao === 'MULTIPLA_ESCOLHA' && type === 'EDIT'
                    }
                    onClick={() => handleQuestao('MULTIPLA_ESCOLHA')}
                  >
                    <FaCheckSquare /> {getTextTipoQuestao('MULTIPLA_ESCOLHA')}
                  </Tipo>
                  <Tipo
                    isActive={
                      tiposQuestao === 'MULTIPLA_ESCOLHA_IMAGEM' &&
                      type === 'EDIT'
                    }
                    onClick={() => handleQuestao('MULTIPLA_ESCOLHA_IMAGEM')}
                  >
                    <FaRegImage />{' '}
                    {getTextTipoQuestao('MULTIPLA_ESCOLHA_IMAGEM')}
                  </Tipo>
                </>
              )}

              <Tipo
                isActive={tiposQuestao === 'UNICA_ESCOLHA' && type === 'EDIT'}
                onClick={() => handleQuestao('UNICA_ESCOLHA')}
              >
                <MdRadioButtonChecked /> {getTextTipoQuestao('UNICA_ESCOLHA')}
              </Tipo>

              <Tipo
                isActive={
                  tiposQuestao === 'UNICA_ESCOLHA_IMAGEM' && type === 'EDIT'
                }
                onClick={() => handleQuestao('UNICA_ESCOLHA_IMAGEM')}
              >
                <FaRegImage /> {getTextTipoQuestao('UNICA_ESCOLHA_IMAGEM')}
              </Tipo>

              {!['PERSONALIDADE', 'PONTUACAO', 'FLUXO'].includes(
                naturezaQuiz
              ) && (
                <Tipo
                  isActive={tiposQuestao === 'V_F' && type === 'EDIT'}
                  className="d-flex flex-row align-items-center gap-2"
                  onClick={() => handleQuestao('V_F')}
                >
                  <div
                    className="border-radius d-flex flex-column"
                    style={{
                      background: 'var(--preto)',
                      color: 'var(--offWhite)',
                      padding: 2,
                      width: 13,
                    }}
                  >
                    <FaCheck size={10} />
                    <FaTimes size={10} />
                  </div>{' '}
                  {getTextTipoQuestao('V_F')}
                </Tipo>
              )}
            </div>
          </div>
          {!['PERSONALIDADE', 'PONTUACAO', 'FLUXO'].includes(naturezaQuiz) && (
            <>
              <div className="col-12 col-lg-4 mb-3">
                <label className="mb-2">Avançados [PRO]</label>
                <div>
                  <Tipo
                    isActive={
                      tiposQuestao === 'RESPOSTA_VOZ' && type === 'EDIT'
                    }
                    onClick={() => handleQuestao('RESPOSTA_VOZ')}
                  >
                    <FaMicrophone /> {getTextTipoQuestao('RESPOSTA_VOZ')}
                  </Tipo>
                  {/* <Tipo
                    isActive={tiposQuestao === 'DATA' && type === 'EDIT'}
                    // onClick={() => handleQuestao('DATA')}
                    disabled
                  >
                    <FaRegCalendarAlt /> {getTextTipoQuestao('DATA')}
                  </Tipo> */}
                </div>
              </div>

              <div className="col-12 col-lg-4 mb-3">
                {/* <label className="mb-2">Número</label>
                <div>
                  <Tipo
                    isActive={tiposQuestao === 'NUMERO' && type === 'EDIT'}
                    // onClick={() => handleQuestao('NUMERO')}
                    disabled
                  >
                    {getTextTipoQuestao('NUMERO')}
                  </Tipo>
                </div> */}

                <label className="mb-2">Aberta</label>
                <div>
                  <Tipo
                    isActive={tiposQuestao === 'TEXTO_CURTO' && type === 'EDIT'}
                    onClick={() => handleQuestao('TEXTO_CURTO')}
                  >
                    <BiText /> {getTextTipoQuestao('TEXTO_CURTO')}
                  </Tipo>
                  <Tipo
                    isActive={tiposQuestao === 'TEXTO_LONGO' && type === 'EDIT'}
                    onClick={() => handleQuestao('TEXTO_LONGO')}
                  >
                    <BiText /> {getTextTipoQuestao('TEXTO_LONGO')}
                  </Tipo>
                </div>
              </div>

              {/* <div className="col-12 col-lg-4 mb-3">
                <label className="mb-2">Conteúdo [PRO]</label>
                <div>
                  <Tipo disabled>
                    Inserir uma tela com conteúdo estático (texto, imagem,
                    botão, ou vídeo)
                  </Tipo>
                </div>
              </div> */}
            </>
          )}

          {naturezaQuiz === 'PESQUISA' && (
            <>
              <div className="col-12 col-lg-4 mb-3">
                <label className="mb-2">Matríz [PRO]</label>
                <div>
                  <Tipo
                    isActive={
                      tiposQuestao === 'MATRIZ_SELECAO_UNICA' && type === 'EDIT'
                    }
                    onClick={() => handleQuestao('MATRIZ_SELECAO_UNICA')}
                  >
                    Escala de seleção única
                  </Tipo>
                  <Tipo
                    isActive={
                      tiposQuestao === 'MATRIZ_SELECAO_MULTIPLA' &&
                      type === 'EDIT'
                    }
                    onClick={() => handleQuestao('MATRIZ_SELECAO_MULTIPLA')}
                  >
                    Escala de seleção múltipla
                  </Tipo>
                </div>
              </div>
              <div className="col-12 col-lg-4 mb-3">
                <label className="mb-2">Avaliação [PRO]</label>
                <div>
                  <Tipo
                    isActive={tiposQuestao === 'NPS' && type === 'EDIT'}
                    onClick={() => handleQuestao('NPS')}
                  >
                    Nota (NPS)
                  </Tipo>
                  {/* <Tipo disabled>Estrelas</Tipo>
                  <Tipo disabled>Smiley</Tipo>
                  <Tipo disabled>Gostei e Não gostei</Tipo> */}
                </div>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default TiposQuestao;
