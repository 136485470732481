import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOpcaoTexto,
  setOpcaoValor,
} from '../../../../../store/questaoSlice';
import { selectNaturezaQuiz } from '../../../../../store/quizSlice';
import { Wrapper } from '../styles';
import { FaTrash } from 'react-icons/fa';
import Checkbox from '../../../../../components/Checkbox';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BiImageAdd } from 'react-icons/bi';

function UnicaEscolha({
  opcao,
  index,
  saveOpcao,
  excluirOpcao,
  saveOpcaoRespostaCorreta,
  upload,
}) {
  const natureza = useSelector(selectNaturezaQuiz);
  const dispatch = useDispatch();

  return (
    <Wrapper
      key={opcao.id + '_' + index}
      className={`${
        opcao.resposta_correta ? 'bg-default' : 'bg-gray'
      } border-radius`}
    >
      {natureza === 'CERTO_ERRADO' && (
        <Checkbox
          checked={opcao.resposta_correta}
          onChange={(e) => saveOpcaoRespostaCorreta(index, e.target.checked)}
        />
      )}

      <div>
        <input
          type="file"
          accept="image/*"
          id={`image-upload-opcao_${opcao.id + '_' + index}`}
          style={{ display: 'none' }}
          onChange={(e) => upload(e, index)}
        />
        {opcao.imagem !== undefined && opcao.imagem ? (
          <label
            htmlFor={`image-upload-opcao_${opcao.id + '_' + index}`}
            className="btn pt-0 pb-0 m-0"
          >
            <img
              style={{
                maxWidth: 45,
              }}
              className="img-fluid"
              src={opcao.imagem}
            />
          </label>
        ) : (
          <label
            htmlFor={`image-upload-opcao_${opcao.id + '_' + index}`}
            className="btn"
          >
            <BiImageAdd size={24} color={'#666666'} />
          </label>
        )}
      </div>

      <input
        type="text"
        className="form-control form-default"
        placeholder="Opção de resposta"
        value={opcao.texto}
        onChange={(e) =>
          dispatch(setOpcaoTexto({ index, texto: e.target.value }))
        }
        onBlur={() => saveOpcao(index, opcao.id)}
      />

      {natureza === 'PONTUACAO' && (
        <OverlayTrigger
          placement={'top'}
          overlay={<Tooltip>Valor da opção</Tooltip>}
        >
          <input
            type="number"
            className="form-control form-default valor-opcao"
            value={opcao.valor !== undefined && opcao.valor && opcao.valor}
            onChange={(e) =>
              dispatch(setOpcaoValor({ index, valor: e.target.value }))
            }
            max="9999.99"
            style={{
              width: '90px',
              padding: '5px',
              textAlign: 'center',
            }}
            onBlur={() => saveOpcao(index, opcao.id)}
          />
        </OverlayTrigger>
      )}

      <button
        className="btn pr-0 flex-center-default"
        onClick={() => excluirOpcao(index, opcao.id)}
        title="Excluir opção"
      >
        <FaTrash color="#FC6D5D" size={17} />
      </button>
    </Wrapper>
  );
}

export default UnicaEscolha;
