import styled from 'styled-components';
import { Container as Toolbar } from '../components/Toolbar/styles';

export const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 50px 1fr;

  grid-template-areas: 'header header' 'aside main';
  @media (max-width: 991px) {
    grid-template-areas: 'header header' 'main main';
    .showMenu991Width {
      position: fixed;
      width: 100%;
      height: 50px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 3;
      background: #ffffff;
      grid-area: header;
    }
  }
`;

export const Header = styled.div`
  position: fixed;
  width: 100%;
  height: 50px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 3;
  background: #ffffff;
  grid-area: header;
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 68px;
  .btn-voltar {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
export const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;

  button.btn-device {
    display: flex;
    align-items: center;
  }
`;

export const Main = styled.div`
  grid-area: main;
  background: var(--offWhite);
  ${Toolbar} {
    position: fixed;
    z-index: 2;
    width: ${({ widthContent }) => widthContent + 'px'} !important;
  }
`;

export const Content = styled.div`
  height: 100%;
  padding: ${(props) => (props.showToolbar ? '75px 30px 15px' : '15px 30px')};
`;

export const NomeQuiz = styled.input`
  border: none;
  font-weight: 700;
  resize: horizontal;
  width: 250px;
`;
