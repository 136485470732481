import React from 'react';
import Resultados from './Resultados';
import Questoes from './Questoes';
import { Container } from './styles';
import { HiHome, HiOutlineShare } from 'react-icons/hi';
import { FaCog, FaExchangeAlt, FaStar } from 'react-icons/fa';
import { NavLink, useParams } from 'react-router-dom';
import { GiFlyingFlag } from 'react-icons/gi';
import { useSelector } from 'react-redux';
import {
  selectNaturezaQuiz,
  selectQuizShowApresentacao,
} from '../../store/quizSlice';
import BlocoLogico from './BlocoLogico';

function Aside({ className = null, desktop = true }) {
  const { quizId } = useParams();
  const naturezaQuiz = useSelector(selectNaturezaQuiz);
  const showApresentacao = useSelector(selectQuizShowApresentacao);

  return (
    <Container className={className || ''}>
      {naturezaQuiz === 'FLUXO' ? (
        <NavLink
          to={`/app/quizzes/${quizId}/editor-fluxo`}
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          <HiHome /> Criação{' '}
        </NavLink>
      ) : (
        <NavLink
          to={`/app/quizzes/${quizId}/apresentacao`}
          className={({ isActive }) => (isActive ? 'active' : '')}
          style={{
            textDecoration: !showApresentacao ? 'line-through' : '',
          }}
          // disabled={!showApresentacao}
          // onClick={(e) => {
          //   if (!showApresentacao) e.preventDefault();
          // }}
        >
          <HiHome /> Apresentação{' '}
        </NavLink>
      )}

      <Questoes desktop={desktop} />
      {naturezaQuiz === 'FLUXO' && <BlocoLogico desktop={desktop} />}

      <Resultados desktop={desktop} />

      <NavLink
        to={`/app/quizzes/${quizId}/configuracao`}
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        <FaCog /> Configurações{' '}
      </NavLink>
      <NavLink
        to={`/app/quizzes/${quizId}/integracoes`}
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        <FaExchangeAlt /> Integrações{' '}
      </NavLink>
      {/* <NavLink
    to="email"
    className={({ isActive }) => (isActive ? 'active' : '')}
  >
    <FaRegEnvelope /> E-mail{' '}
  </NavLink> */}
      <NavLink
        to={`/app/quizzes/${quizId}/compartilhar`}
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        <HiOutlineShare /> Compartilhar{' '}
      </NavLink>
      <NavLink
        to={`/app/quizzes/${quizId}/qualificacao`}
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        <FaStar /> Qualificação{' '}
      </NavLink>

      <NavLink
        to={`/app/quizzes/${quizId}/fim`}
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        <GiFlyingFlag /> Fim
      </NavLink>
    </Container>
  );
}

export default Aside;
