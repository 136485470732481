export default function getUrl(url) {
  const domains = ['quizclassus1.s3.amazonaws.com', 'assets.quizclass.com.br'];

  let newUrl = url;

  for (const domain of domains) {
    if (url.includes(domain)) {
      const regex = new RegExp(domain, 'g');
      newUrl = url.replace(regex, 'assetsus1.quizclass.com.br');
    }
  }

  return newUrl;
}
