export default class BlocoLogicoTune {
  static get isTune() {
    return true;
  }

  constructor({ api, data, config, block }) {
    this.block = block;
    this.settings = config;
    this.api = api;
    this.data = data || { checked: false, questaoId: '', opcaoId: '' };
  }

  toggleWrapperStyle() {
    if (this.data.checked && this.data.questaoId && this.data.opcaoId) {
      this.wrapper.style.border = '2px solid blue';
      this.wrapper.classList.remove('animate-pulse');
    } else if (this.data.checked) {
      this.wrapper.style.border = '2px solid red';
      this.wrapper.classList.add('animate-pulse');
    } else {
      this.wrapper.style.border = 'none';
      this.wrapper.classList.remove('animate-pulse');
    }
  }

  render() {
    const wrapper = document.createElement('div');

    if (this.settings.isPageResultado) {
      const formCheck = document.createElement('div');
      const wrapperEditBlocoLogico = document.createElement('div');
      wrapper.appendChild(formCheck);
      wrapper.appendChild(wrapperEditBlocoLogico);

      const buttonEditar = document.createElement('button');
      buttonEditar.className = 'btn btn-sm col-12 btn-editar-logica';
      buttonEditar.innerHTML = 'Editar lógica';
      buttonEditar.style.fontSize = '0.8rem';

      wrapperEditBlocoLogico.appendChild(buttonEditar);

      if (this.data.checked) {
        buttonEditar.style.display = '';
      } else {
        buttonEditar.style.display = 'none';
      }

      formCheck.className = 'form-check';
      formCheck.style.marginLeft = '7px';

      const checkInput = document.createElement('input');
      checkInput.setAttribute('type', 'checkbox');
      checkInput.className = 'form-check-input';
      checkInput.checked = this.data.checked;
      checkInput.classList.add(this.api.styles.settingsButton);
      checkInput.onchange = (e) => {
        this.data = {
          ...this.data,
          checked: e.target.checked,
        };

        this.toggleWrapperStyle();

        if (e.target.checked) {
          buttonEditar.style.display = '';
        } else {
          buttonEditar.style.display = 'none';
        }
      };

      const label = document.createElement('label');
      label.className = 'form-check-label align-self-center';
      label.innerHTML = 'Bloco lógico';
      label.style.fontSize = '0.8rem';
      label.style.margin = '0';
      label.onclick = (e) => {
        const checked = !this.data.checked;
        this.data = {
          ...this.data,
          checked: checked,
        };
        checkInput.checked = checked;

        this.toggleWrapperStyle();

        if (checked) {
          buttonEditar.style.display = '';
        } else {
          buttonEditar.style.display = 'none';
        }
      };

      const inputQuestaoId = document.createElement('input');
      inputQuestaoId.setAttribute('type', 'hidden');
      inputQuestaoId.setAttribute('value', this.data.questaoId || '');
      inputQuestaoId.onchange = (e) => {
        this.data = {
          ...this.data,
          questaoId: e.target.value,
        };
      };

      const inputOpcaoId = document.createElement('input');
      inputOpcaoId.setAttribute('type', 'hidden');
      inputOpcaoId.setAttribute('value', this.data.opcaoId || '');
      inputOpcaoId.onchange = (e) => {
        this.data = {
          ...this.data,
          opcaoId: e.target.value,
        };
      };

      wrapper.appendChild(inputQuestaoId);
      wrapper.appendChild(inputOpcaoId);

      formCheck.appendChild(checkInput);
      formCheck.appendChild(label);
    }
    return wrapper;
  }

  save() {
    return this.data;
  }

  wrap(blockContent) {
    this.wrapper = document.createElement('div');
    this.wrapper.style.marginBottom = '5px';
    this.wrapper.append(blockContent);
    this.toggleWrapperStyle();

    return this.wrapper;
  }
}
