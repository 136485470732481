import { Field, Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Form as FormBootstrap, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  deleteActiveCampaignIntegracao,
  getActiveCampaignCampos,
  postActiveCampaignIntegracao,
} from '../../../services/apiActiveCampaign';
import {
  contaActiveCampaignListaId,
  contaActiveCampaignSelecionada,
  selectContasActiveCampaign,
  setActiveCampaignContaSelected,
  setActiveCampaignListaId,
} from '../../../store/activeCampaignSlice';
import {
  selectIdQuiz,
  setIntegracaoActiveCampaignConfigurada,
} from '../../../store/quizSlice';
import { ContainerEnunciado, ContainerForm } from '../styles';
import ListasDeContatos from './components/ListasDeContatos';
import { Container } from './styles';

function ActiveCampaign() {
  const dispatch = useDispatch();
  const quizId = useSelector(selectIdQuiz);
  const contas = useSelector(selectContasActiveCampaign);
  const conta_selecionada = useSelector(contaActiveCampaignSelecionada);
  const active_campaign_lista_id = useSelector(contaActiveCampaignListaId);
  const [isLoading, setIsloading] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      await postActiveCampaignIntegracao(
        quizId,
        values.conta_selecionada,
        values.active_campaign_lista_id
      );

      dispatch(setActiveCampaignContaSelected(values.conta_selecionada));
      dispatch(setActiveCampaignListaId(values.active_campaign_lista_id));
      dispatch(setIntegracaoActiveCampaignConfigurada(true));

      navigate(`/app/quizzes/${quizId}/integracoes`);
      toast.success('Configuração com a ActiveCampaign realizada com sucesso!');
    } catch (e) {
      toast.error('Falha ao salvar configuração com a ActiveCampaign.');
    }
  };

  const handleRemoveIntegracao = async () => {
    try {
      await deleteActiveCampaignIntegracao(quizId);

      dispatch(setActiveCampaignContaSelected(''));
      dispatch(setActiveCampaignListaId(''));
      dispatch(setIntegracaoActiveCampaignConfigurada(false));

      navigate(`/app/quizzes/${quizId}/integracoes`);
      toast.success('Configuração com a ActiveCampaign removida deste quiz!');
    } catch (e) {
      console.error(e);
      toast.error('Falha ao remover integração com a ActiveCampaign.');
    }
  };

  useEffect(() => {
    const getInitialState = async () => {
      try {
        await dispatch(getActiveCampaignCampos(quizId));
      } catch (error) {}
      setIsloading(false);
    };

    if (quizId) getInitialState();
    return () => {
      setIsloading(true);
    };
  }, [dispatch, quizId]);

  return isLoading ? (
    <div>Carregando...</div>
  ) : (
    <Container>
      <Link to={-1}>voltar</Link>

      <br />
      <br />

      <ContainerEnunciado>
        <div>
          <img
            src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/integracao/active_icon.webp"
            alt="ActiveCampaign icon"
          />
        </div>
        <div>
          <h2>Configurar integração com a ActiveCampaign</h2>
          <p>
            Envie os leads gerados pelo seu quiz para sua conta da
            ActiveCampaign e assim alimentar suas campanhas de marketing.
          </p>
        </div>
      </ContainerEnunciado>

      <ContainerForm>
        <Formik
          enableReinitialize={true}
          initialValues={{
            contas,
            conta_selecionada,
            active_campaign_lista_id,
          }}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <Row>
                <Col xs="auto">
                  <FormBootstrap.Group className="mb-3">
                    <Field
                      as="select"
                      aria-label="Default select example"
                      name="conta_selecionada"
                      className="form-default form-select"
                      required
                    >
                      <option>Selecione uma conta para vincular ao Quiz</option>
                      {values.contas.map((conta) => (
                        <option value={conta.id} key={conta.id}>
                          {conta.nome}
                        </option>
                      ))}
                    </Field>

                    <FormBootstrap.Text>
                      Caso ainda não tenha uma conta, clique no botão "Cadastrar
                      nova conta"
                    </FormBootstrap.Text>
                  </FormBootstrap.Group>

                  <ListasDeContatos
                    active_campaign_lista_id={values.active_campaign_lista_id}
                    contaActiveCampaignSelecionadaId={values.conta_selecionada}
                  />
                </Col>

                <Col xs="auto">
                  <a
                    href={
                      process.env.REACT_APP_BASE_URL_BACKEND +
                      '/app/minha-conta/integracao/active-campaign/new'
                    }
                  >
                    <button
                      className="btn btn-primary shadow-default col-12"
                      type="button"
                    >
                      Cadastrar nova conta
                    </button>
                  </a>
                </Col>
              </Row>

              <br />
              <br />

              <button
                type="submit"
                className="btn btn-default shadow-default"
                disabled={isSubmitting}
              >
                Configurar integração
              </button>
            </Form>
          )}
        </Formik>

        <br></br>
        <hr></hr>
        <br></br>

        {conta_selecionada ? (
          <button
            className="btn btn-sm btn-secondary"
            onClick={handleRemoveIntegracao}
          >
            Remover integração
          </button>
        ) : (
          ''
        )}
      </ContainerForm>
    </Container>
  );
}

export default ActiveCampaign;
