export default class BlocoCompartilharResultado {
  static get toolbox() {
    return {
      title: 'Compartilhar resultado',
      icon: '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M352 320c-22.608 0-43.387 7.819-59.79 20.895l-102.486-64.054a96.551 96.551 0 0 0 0-41.683l102.486-64.054C308.613 184.181 329.392 192 352 192c53.019 0 96-42.981 96-96S405.019 0 352 0s-96 42.981-96 96c0 7.158.79 14.13 2.276 20.841L155.79 180.895C139.387 167.819 118.608 160 96 160c-53.019 0-96 42.981-96 96s42.981 96 96 96c22.608 0 43.387-7.819 59.79-20.895l102.486 64.054A96.301 96.301 0 0 0 256 416c0 53.019 42.981 96 96 96s96-42.981 96-96-42.981-96-96-96z"></path></svg>',
    };
  }

  constructor({ api, data }) {
    this.api = api;
    this.data = Object.keys(data).length
      ? data
      : { shareOnFacebook: true, shareOnTwitter: true };
  }

  render() {
    const container = document.createElement('div');
    container.className = 'cdx-block';
    container.innerHTML = `
      <h4>Compartilhar resultado</h4>      
    `;
    container.style.zIndex = '10';

    const btnFacebook = document.createElement('button');
    btnFacebook.style.backgroundColor = '#0B5CAB';
    btnFacebook.className = 'btn btn-md me-2';
    btnFacebook.style.color = 'white';
    btnFacebook.style.position = 'relative';
    btnFacebook.textContent = 'Facebook';
    btnFacebook.style.opacity = this.data.shareOnFacebook ? '1' : '0.4';

    const checkInputFacebook = document.createElement('input');
    checkInputFacebook.setAttribute('type', 'checkbox');
    checkInputFacebook.setAttribute('name', 'shareOnFacebook');
    checkInputFacebook.className = 'form-check-input';
    checkInputFacebook.style.position = 'absolute';
    checkInputFacebook.style.top = '0';
    checkInputFacebook.style.right = '0';
    checkInputFacebook.style.margin = '0';
    checkInputFacebook.checked = this.data.shareOnFacebook;
    checkInputFacebook.classList.add(this.api.styles.settingsButton);

    checkInputFacebook.onchange = (e) => {
      this.data = {
        ...this.data,
        shareOnFacebook: e.target.checked,
      };

      btnFacebook.style.opacity = this.data.shareOnFacebook ? '1' : '0.4';
    };
    btnFacebook.onclick = () => {
      checkInputFacebook.checked = !this.data.shareOnFacebook;

      this.data = {
        ...this.data,
        shareOnFacebook: !this.data.shareOnFacebook,
      };

      btnFacebook.style.opacity = this.data.shareOnFacebook ? '1' : '0.4';
    };
    btnFacebook.appendChild(checkInputFacebook);

    const btnTwitter = document.createElement('button');
    btnTwitter.style.backgroundColor = '#17AFD7';
    btnTwitter.className = 'btn btn-md';
    btnTwitter.style.color = 'white';
    btnTwitter.style.position = 'relative';
    btnTwitter.textContent = 'Twitter';
    btnTwitter.style.opacity = this.data.shareOnTwitter ? '1' : '0.4';

    const checkInputTwitter = document.createElement('input');
    checkInputTwitter.setAttribute('type', 'checkbox');
    checkInputTwitter.setAttribute('name', 'shareOnTwitter');
    checkInputTwitter.className = 'form-check-input';
    checkInputTwitter.style.position = 'absolute';
    checkInputTwitter.style.top = '0';
    checkInputTwitter.style.right = '0';
    checkInputTwitter.style.margin = '0';
    checkInputTwitter.checked = this.data.shareOnTwitter;
    checkInputTwitter.classList.add(this.api.styles.settingsButton);

    checkInputTwitter.onchange = (e) => {
      this.data = {
        ...this.data,
        shareOnTwitter: e.target.checked,
      };

      btnTwitter.style.opacity = this.data.shareOnTwitter ? '1' : '0.4';
    };
    btnTwitter.onclick = () => {
      checkInputTwitter.checked = !this.data.shareOnTwitter;
      this.data = {
        ...this.data,
        shareOnTwitter: !this.data.shareOnTwitter,
      };
      btnTwitter.style.opacity = this.data.shareOnTwitter ? '1' : '0.4';
    };
    btnTwitter.appendChild(checkInputTwitter);

    container.appendChild(btnFacebook);
    container.appendChild(btnTwitter);
    return container;
  }

  save(blockContent) {
    const inputs = blockContent.getElementsByTagName('input');

    const data = {
      [inputs[0].name]: inputs[0].checked,
      [inputs[1].name]: inputs[1].checked,
    };

    return data;
  }
}
