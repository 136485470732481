import { setContas, setContaSelected, setEvento } from './../store/RDStationSlice'
import apiQuizClass from './apiQuizClass';

export const getContas = (quizId) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(
        `/integracao/${quizId}/contas-rd`
      );
      dispatch(setContas(response.data.contas));
      dispatch(setContaSelected(response.data.selected))
      dispatch(setEvento(response.data.evento))
    } catch (error) {
      console.error(error);
    }
  };
};

export const saveConfigRd = async (quizId, data) => {
  try {
    await apiQuizClass.post(`/integracao/${quizId}/save-config-rd-integration`, data)
    return true
  } catch(error) {
    console.error(error)
    return false
  }
}

export const removeConfigRd = async (quizId) => {
  
    try {
      await apiQuizClass.delete(`/integracao/${quizId}/remove-integracao-rd`)
      return true
    } catch(error) {
      console.error(error)
      return false
    }
  
}