import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { putQuizShowLogo } from '../../../services/quizService';
import {
  selectIdQuiz,
  selectQuizShowLogo,
  setQuizShowLogo,
} from '../../../store/quizSlice';
import { LogoQuiz } from './styles';

export default function LogoQuizClass() {
  const dispatch = useDispatch();
  const showLogo = useSelector(selectQuizShowLogo);
  const quizId = useSelector(selectIdQuiz);

  const hadnleRemoveLogo = async () => {
    try {
      await putQuizShowLogo(quizId, false);
      dispatch(setQuizShowLogo(false));
    } catch (e) {
      console.error(e);
    }
  };

  return showLogo ? (
    <LogoQuiz>
      <OverlayTrigger
        trigger="click"
        placement={'top'}
        overlay={
          <Popover id={`popover-positioned-${'top'}`}>
            <Popover.Header as="h3">Logo QuizClass</Popover.Header>
            <Popover.Body>
              <button
                className="btn btn-danger btn-md shadow-default"
                onClick={hadnleRemoveLogo}
              >
                REMOVER
              </button>
            </Popover.Body>
          </Popover>
        }
      >
        <img
          src="https://assetsus1.quizclass.com.br/assets/images/logo-white-quizclass.svg"
          id="logo"
          height="30"
          alt="Logo QuizClass"
          style={{ opacity: 0.4, cursor: 'pointer' }}
        ></img>
      </OverlayTrigger>
    </LogoQuiz>
  ) : (
    ''
  );
}
