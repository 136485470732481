import React, { useState, useId } from 'react';
import Draggable from 'react-draggable';
import { useEditor } from '../../Context';
import { Container } from './styles';
import {
  putApresentacaoProximoBloco,
  putBlocoLogicoProximoBloco,
  putOpcaoProximoBloco,
} from '../../../../services/editorFluxo';
import { useDispatch } from 'react-redux';

export default function ConnectPointsWrapper({
  boxId,
  dropBoxRef,
  dragBoxRef,
  itemRef,
  itemId,
}) {
  const dispatch = useDispatch();
  const {
    updateXarrow,
    wrapperPosition,
    setDragConnectPoint,
    addArrow,
    arrows,
  } = useEditor();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const connectPointId = useId();
  const disabled = Boolean(arrows.find((arrow) => arrow.start === itemId));

  const handleDragStop = (event, draggableData) => {
    const draggableElement = event.target;
    const targetElements = document.querySelectorAll(
      '.target-box-editor-fluxos'
    );

    const draggableRect = draggableElement.getBoundingClientRect();

    targetElements.forEach((targetElement) => {
      const targetRect = targetElement.getBoundingClientRect();

      const blocoType = document
        .getElementById(boxId)
        .getAttribute('data-draggable-type');

      const targeType = targetElement.getAttribute('data-draggable-type');

      if (blocoType === 'APRESENTACAO' && targeType === 'RESULTADO') return;
      if (blocoType === 'APRESENTACAO' && targeType === 'BLOCO_LOGICO') return;
      if (blocoType === 'BLOCO_LOGICO') {
        const opcaoType = itemRef.current.getAttribute('data-opcao-type');
        if (opcaoType === 'SIM' && targeType === 'BLOCO_LOGICO') return;
      }

      if (
        targeType !== 'APRESENTACAO' &&
        draggableRect.left >= targetRect.left &&
        draggableRect.right <= targetRect.right &&
        draggableRect.top >= targetRect.top &&
        draggableRect.bottom <= targetRect.bottom &&
        targetElement.id !== boxId
      ) {
        const refs = {
          start: itemId,
          end: targetElement.id,
          typeStart: blocoType,
        };

        addArrow(refs);

        if (blocoType === 'APRESENTACAO') {
          dispatch(putApresentacaoProximoBloco(targetElement.id));
        } else if (blocoType === 'QUESTAO') {
          dispatch(
            putOpcaoProximoBloco(boxId, itemId, targetElement.id, targeType)
          );
        } else if (blocoType === 'BLOCO_LOGICO') {
          const opcaoType = itemRef.current.getAttribute('data-opcao-type');
          dispatch(
            putBlocoLogicoProximoBloco(
              boxId,
              opcaoType,
              targetElement.id,
              targeType
            )
          );
        }
      }
    });
  };

  return (
    <React.Fragment>
      <Container connectPointDisabled={disabled}>
        <Draggable
          defaultPosition={position}
          position={position}
          onStop={(e, data) => {
            setDragConnectPoint({
              isDragging: false,
              start: '',
              end: '',
            });
            setPosition({ x: 0, y: 0 });
            dropBoxRef.current.style.zIndex = 0;
            handleDragStop(e, data);
          }}
          onStart={(e) => {
            setDragConnectPoint({
              isDragging: true,
              start: itemId,
              end: connectPointId,
            });
            dropBoxRef.current.style.zIndex = 1;
            // e.dataTransfer.setData('arrow', itemId);
          }}
          onDrag={(e, data) => {
            updateXarrow();
            setPosition({ x: data.x, y: data.y });
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          scale={wrapperPosition.scale}
          disabled={disabled}
        >
          <div
            id={connectPointId}
            className="connectPoint"
            disabled={disabled}
          />
        </Draggable>
      </Container>
    </React.Fragment>
  );
}
