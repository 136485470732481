import React from 'react';
import Questao from '../../components/Questao';
import { useParams } from 'react-router-dom';

function Index() {
  const { questaoId } = useParams();
  return (
    <div>
      <Questao page="QUESTAO" questaoId={questaoId} />
    </div>
  );
}

export default Index;
