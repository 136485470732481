import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useXarrow } from 'react-xarrows';
import {
  putApresentacaoProximoBloco,
  putBlocoLogicoProximoBloco,
  putOpcaoProximoBloco,
} from '../../../services/editorFluxo';
import { useDispatch } from 'react-redux';

const EditorContext = createContext();

export function EditorProvider({ children }) {
  const dispatch = useDispatch();
  const [arrows, setArrows] = useState([]);

  const [dragConnectPoint, setDragConnectPoint] = useState({
    isDragging: false,
    start: '',
    end: '',
  });

  const [wrapperPosition, setWrapperPosition] = useState({
    x: 0,
    y: 0,
    scale: 1,
  });

  const isMoveable = useRef(false);
  const updateXarrow = useXarrow();

  const onDrag = () => {
    isMoveable.current = true;
    updateXarrow();
  };
  const onStop = () => {
    isMoveable.current = false;
    updateXarrow();
  };

  const setNewArrows = useCallback((data) => {
    setArrows(data);
  }, []);

  const addArrow = useCallback(({ start, end, typeStart }) => {
    if (!arrows.find((arrow) => arrow.start === start && arrow.end === end))
      setArrows((state) => [...state, { start, end, typeStart }]);
  }, []);

  const deleteArrow = useCallback((start, type) => {
    setArrows((state) => state.filter((arrow) => arrow.start !== start));

    if (type === 'APRESENTACAO') {
      dispatch(putApresentacaoProximoBloco(null));
    } else if (type === 'QUESTAO') {
      dispatch(putOpcaoProximoBloco(null, start, null));
    } else if (type === 'BLOCO_LOGICO') {
      if (start.includes('BLOCO_LOGICO_SIM_')) {
        dispatch(
          putBlocoLogicoProximoBloco(
            start.replace(/^BLOCO_LOGICO_SIM_/, ''),
            'SIM',
            null,
            null
          )
        );
      } else if (start.includes('BLOCO_LOGICO_NAO_')) {
        dispatch(
          putBlocoLogicoProximoBloco(
            start.replace(/^BLOCO_LOGICO_NAO_/, ''),
            'NAO',
            null,
            null
          )
        );
      }
    }
  }, []);

  return (
    <EditorContext.Provider
      value={{
        addArrow,
        onStop,
        onDrag,
        wrapperPosition,
        setWrapperPosition,
        dragConnectPoint,
        setDragConnectPoint,
        arrows,
        updateXarrow,
        isMoveable,
        setArrows,
        deleteArrow,
        setNewArrows,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
}

export function useEditor() {
  const context = useContext(EditorContext);

  return context;
}
