import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;

  position: relative;
`;

export const Respostas = styled.div``;

export const Resultados = styled.div``;

export const OpcaoResposta = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid var(--cinza);
  width: 278px;

  padding: 15px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  /* position: relative; */

  svg {
    position: absolute;
    right: 60%;
    z-index: 1;
    circle {
      fill: var(--cinza);
      /* &:hover {
        fill: var(--preto);
      } */
    }
  }

  &:hover,
  &.active {
    background-color: var(--amarelo);
    path {
      stroke: var(--amarelo);
    }
  }

  &.active {
    circle {
      fill: var(--amarelo);
    }
  }
`;

export const OpcaoResultado = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 278px;

  border-bottom: 1px solid var(--cinza);
  padding: 15px;
  cursor: pointer;
  transition: all 0.2s ease-out;

  svg {
    position: absolute;
    right: 36%;
    z-index: 1;

    circle {
      fill: var(--cinza);
    }
  }

  &:hover,
  &.active {
    background-color: var(--amarelo);
  }

  &.active {
    circle {
      fill: var(--amarelo);
    }
  }
`;
