import React, { useEffect, useRef } from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Container } from './styles';
import { uploadFile, uploadVideo } from '../../services/uploadService';
import { useSelector } from 'react-redux';
import { selectIdQuiz } from '../../store/quizSlice';
import { toast } from 'react-toastify';
import getCacheUrl from '../../utils/getCacheUrl';

function CKEditorComponent({
  content,
  onChange = (text) => {},
  onBlur = () => {},
  mentions = [],
}) {
  const idQuiz = useSelector(selectIdQuiz);
  const editorInstance = useRef();

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            uploadFile(idQuiz, file).then((url) => {
              resolve({
                default: getCacheUrl(url),
              });
            });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const insertVideo = (url) => {
    if (editorInstance.current) {
      editorInstance.current.model.change((writer) => {
        const videoElement = writer.createElement('media', {
          url: url,
        });
        editorInstance.current.model.insertContent(
          videoElement,
          editorInstance.current.model.document.selection.getFirstPosition()
        );
      });
    }
  };

  const insertAudio = (url) => {
    if (editorInstance.current) {
      const viewFragment = editorInstance.current.data.processor.toView(
        `<audio controls="controls" controlslist="nodownload" src="${url}">&nbsp;</audio>`
      );
      const modelFragment = editorInstance.current.data.toModel(viewFragment);

      editorInstance.current.model.insertContent(
        modelFragment,
        editorInstance.current.model.document.selection.getFirstPosition()
      );
    }
  };

  const sendVideo = () => {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = 'video/*';
    input.click();
    input.onchange = (e) => {
      const file = e.target.files[0];
      toast.promise(
        uploadVideo(file).then((data) => insertVideo(data)),
        {
          pending: 'Carregando video...',
          success: {
            render({ data }) {
              return 'Caregado com sucesso!';
            },
          },
          error: {
            render({ data: message }) {
              return message;
            },
          },
        }
      );
    };
  };

  const sendAudio = () => {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = 'audio/*';
    input.click();
    input.onchange = (e) => {
      const file = e.target.files[0];
      toast.promise(
        uploadFile(idQuiz, file, 'audio').then((url) => insertAudio(url)),
        {
          pending: 'Carregando áudio...',
          success: 'Caregado com sucesso!',
          error: {
            render({ data: message }) {
              return message;
            },
          },
        }
      );
    };
  };

  return (
    <Container>
      <CKEditor
        config={{
          extraPlugins: [uploadPlugin],
          mention: {
            feeds: [
              {
                marker: '@',
                feed: mentions,
                minimumCharacters: 0,
              },
            ],
          },
        }}
        editor={Editor}
        data={content ?? ''}
        onReady={(editor) => {
          editorInstance.current = editor;

          const uploadVideoClicked = editor.commands.get('videoUpload');
          uploadVideoClicked.on('execute', () => {
            sendVideo();
          });

          const uploadAudioClicked = editor.commands.get('audioUpload');
          uploadAudioClicked.on('execute', () => {
            sendAudio();
          });
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data);
        }}
        onBlur={(event, editor) => {
          onBlur();
        }}
        onFocus={(event, editor) => {
          // console.log('Focus.', editor);
        }}
      />
    </Container>
  );
}

export default CKEditorComponent;
