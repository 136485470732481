import { Field, Formik } from 'formik';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { putQuestao } from '../../../../../services/questaoService';
import {
  selectQuestaoFeedback,
  setQuestaoFeedback,
} from '../../../../../store/questaoSlice';
import {
  selectShowModalFeedbackQuestao,
  toggleShowModalFeedbackQuestao,
} from '../../../../../store/showSlice';
import { FaVolumeUp } from 'react-icons/fa';

function Feedback() {
  const dispatch = useDispatch();
  const show = useSelector(selectShowModalFeedbackQuestao);
  const feedbackConfig = useSelector(selectQuestaoFeedback);
  const questao = useSelector((state) => state.questao);

  return (
    <Modal
      show={show}
      onHide={() => dispatch(toggleShowModalFeedbackQuestao())}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Configurar Feedback</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={feedbackConfig}
        onSubmit={(values, { setSubmitting }) => {
          putQuestao(questao.id, { ...questao, ...values }).then(() => {
            dispatch(setQuestaoFeedback(values));
            setSubmitting(false);
            toast.success('Configuração salva!');
            dispatch(toggleShowModalFeedbackQuestao());
          });
        }}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="container d-flex flex-column">
              <div className="form-check mb-3">
                <label
                  className="form-check-label"
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <Field
                    className="form-check-input me-1"
                    type="checkbox"
                    name="som_resposta_correta"
                    checked={values.som_resposta_correta}
                    onChange={(e) =>
                      setFieldValue('som_resposta_correta', e.target.checked)
                    }
                  />
                  Emitir som de resposta correta
                </label>{' '}
                <span
                  className="btn btn-sm"
                  onClick={() => {
                    const correct = new Audio(
                      'https://s3-sa-east-1.amazonaws.com/quizx/assets/media/correct.mp3'
                    );
                    correct.play();
                  }}
                >
                  <FaVolumeUp />
                </span>
              </div>

              <div className="form-check mb-3">
                <label
                  className="form-check-label"
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <Field
                    className="form-check-input me-1"
                    type="checkbox"
                    name="som_resposta_errada"
                    checked={values.som_resposta_errada}
                    onChange={(e) =>
                      setFieldValue('som_resposta_errada', e.target.checked)
                    }
                  />
                  Emitir som de resposta errada
                </label>{' '}
                <span
                  className="btn btn-sm"
                  onClick={() => {
                    const wrong = new Audio(
                      'https://s3-sa-east-1.amazonaws.com/quizx/assets/media/wrong.mp3'
                    );
                    wrong.play();
                  }}
                >
                  <FaVolumeUp />
                </span>
              </div>

              <div className="form-group mb-3">
                <label>Mensagem mostrada quando acerta questão</label>
                <Field
                  className="form-control form-default"
                  name="mensagem_resposta_correta"
                  maxLength="255"
                />
              </div>

              <div className="form-group mb-3">
                <label>Mensagem mostrada quando erra questão</label>
                <Field
                  className="form-control form-default"
                  name="mensagem_resposta_errada"
                  maxLength="255"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => dispatch(toggleShowModalFeedbackQuestao())}
              >
                Fechar
              </Button>
              <button
                className="btn btn-default shadow-default ps-4 pe-4"
                type="submit"
                disabled={isSubmitting}
              >
                Salvar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default Feedback;
