import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardForm, Actions, TextoBotaoInput } from './styles';
import {
  selectTextBotaoIniciar,
  setTextBotaoIniciar,
} from '../../../store/apresentacaoSlice';
import CamposPersonalizados from '../CamposPersonalizados';
import CloseButton from '../../Buttons/Close';
import EditButton from '../../Buttons/Edit';
import {
  toggleShowModalConfiguracaoDadosUsuario,
  toggleShowModalConfirmDeletion,
} from '../../../store/showSlice';
import ModalConfiguracaoDadosUsuario from '../../Modal/ConfiguracaoDadosUsuario';
import ModalConfirmDeletion from '../../Modal/ConfirmDeletion';
import { removeForm } from '../../../store/formDadosUsuarioSlice';
import { putConfiguracaoFormulario } from '../../../services/configFormDadosUsuarioService';
import { selectIdQuiz } from '../../../store/quizSlice';
import { FaInfoCircle } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function DadosUsuario() {
  const dispatch = useDispatch();
  const configs = useSelector((state) => state.formDadosUsuario);
  const personalizacao = useSelector((state) => state.personalizacao);
  const textBotaoIniciar = useSelector(selectTextBotaoIniciar);

  const [camposPersonalizados, setCamposPersonalizados] = useState(
    configs.campos_personalizados_selecionados?.map((campoPersonalizado) => {
      return {
        key: campoPersonalizado.id,
        value: '',
      };
    })
  );

  const idQuiz = useSelector(selectIdQuiz);

  if (Object.keys(configs).length)
    return (
      <>
        <CardForm
          className="card border-radius"
          quandoObterDados={configs.quando_obter_dados}
          style={{
            background: personalizacao.cor_fundo_secundaria,
            color: personalizacao.cor_texto_secundaria,
          }}
        >
          <Actions>
            <OverlayTrigger
              placement={'top'}
              overlay={
                <Tooltip>
                  Este formulario é apenas uma representação, as interações com
                  o mesmo não sutirão efeitos.
                </Tooltip>
              }
            >
              <div
              // className="ms-3 me-auto"
              >
                <FaInfoCircle size={20}/>
              </div>
            </OverlayTrigger>
            <EditButton
              onClick={() =>
                dispatch(toggleShowModalConfiguracaoDadosUsuario())
              }
            />
            <CloseButton
              onClick={() => dispatch(toggleShowModalConfirmDeletion())}
            />
          </Actions>

          <div className="card-body">
            <h2 className="card-title">
              Antes de iniciar, informe alguns dados:
            </h2>
            {configs.ordem_campos?.map((ordem, index) =>
              ordem.campo === 'Nome'
                ? configs.obter_nome && (
                    <div key={index}>
                      <label className="label-nome-campos">Seu nome:</label>
                      <input
                        name="nome"
                        type="text"
                        className="form-control form-default mb-2"
                        placeholder={
                          configs.quando_obter_dados === 'FIM'
                            ? 'Seu nome'
                            : 'Nome'
                        }
                      />
                    </div>
                  )
                : ordem.campo === 'E-mail'
                ? configs.obter_email && (
                    <div key={index}>
                      <label className="label-nome-campos">Seu e-mail:</label>
                      <input
                        name="email"
                        type="email"
                        className="form-control form-default mb-2"
                        placeholder={
                          configs.quando_obter_dados === 'FIM'
                            ? 'Seu e-mail'
                            : 'E-mail'
                        }
                      />
                    </div>
                  )
                : ordem.campo === 'Telefone'
                ? configs.obter_telefone && (
                    <div key={index}>
                      <label className="label-nome-campos">Telefone:</label>
                      <input
                        name="telefone"
                        type="text"
                        className="form-control form-default mb-2"
                        placeholder={
                          configs.quando_obter_dados === 'FIM'
                            ? 'Telefone'
                            : '(99) 99999-9999'
                        }
                      />
                    </div>
                  )
                : ordem.campo === 'Empresa'
                ? configs.obter_empresa && (
                    <div key={index}>
                      <label className="label-nome-campos">Empresa:</label>
                      <input
                        name="empresa"
                        type="text"
                        className="form-control form-default mb-2"
                        placeholder="Empresa"
                      />
                    </div>
                  )
                : ordem.campo === 'CNPJ'
                ? configs.obter_cnpj && (
                    <div key={index}>
                      <label className="label-nome-campos">CNPJ:</label>
                      <input
                        name="cnpj"
                        type="text"
                        className="form-control form-default mb-2"
                        placeholder="CNPJ"
                      />
                    </div>
                  )
                : ordem.campo === 'CPF'
                ? configs.obter_cpf && (
                    <div key={index}>
                      <label className="label-nome-campos">CPF:</label>
                      <input
                        name="cpf"
                        type="text"
                        className="form-control form-default mb-2"
                        placeholder="CPF"
                      />
                    </div>
                  )
                : ordem.campo === 'Cidade'
                ? configs.obter_cidade && (
                    <div key={index}>
                      <label className="label-nome-campos">Cidade:</label>
                      <input
                        name="cidade"
                        type="text"
                        className="form-control form-default mb-2"
                        placeholder="Cidade"
                      />
                    </div>
                  )
                : configs.campos_personalizados_selecionados.map(
                    (campoPersonalizado, index) =>
                      ordem.campo === campoPersonalizado.nome && (
                        <CamposPersonalizados
                          key={index}
                          index={index}
                          campo={campoPersonalizado}
                          camposValue={camposPersonalizados}
                          quandoObterDados={configs.quando_obter_dados}
                        ></CamposPersonalizados>
                      )
                  )
            )}
            <div className="form-group check-contato-email text-left mb-2 mt-3">
              <div className="form-check">
                <input
                  id="communications"
                  className="form-check-input"
                  type="checkbox"
                  data-privacy="true"
                  name="communications"
                  defaultChecked
                />
                <label htmlFor="communications" className="form-check-label">
                  <small>Eu concordo em receber comunicações.</small>
                </label>
              </div>
            </div>
            <div className="text-left">
              <small>
                <input
                  type="hidden"
                  data-privacy="true"
                  name="privacy_policy"
                  value="1"
                />
                <input
                  type="hidden"
                  data-privacy="true"
                  name="terms_of_use"
                  value="1"
                />
                Ao informar meus dados, eu concordo com a
                <a
                  href="https://quizclass.com.br/politica-privacidade"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  <strong>Política de Privacidade</strong>
                </a>{' '}
                e com os
                <a
                  href="https://quizclass.com.br/termos-de-uso"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  <strong>Termos de Uso</strong>
                </a>
                .
              </small>
            </div>
          </div>
          <div className="card-footer">
            <button
              type="submit"
              className="btn btn-default btn-primary col-12"
              style={{
                background: personalizacao.cor_fundo_primaria,
              }}
            >
              <TextoBotaoInput
                className="border-radius"
                textoSecundario={personalizacao.cor_texto_secundaria}
                textoPrimario={personalizacao.cor_texto_primaria}
                value={textBotaoIniciar || 'Iniciar'}
                onChange={(e) => dispatch(setTextBotaoIniciar(e.target.value))}
                maxLength="50"
              />
            </button>
          </div>
        </CardForm>
        <ModalConfiguracaoDadosUsuario />
        <ModalConfirmDeletion
          title="Remover formulário"
          onClick={() =>
            dispatch(
              putConfiguracaoFormulario(idQuiz, {
                ...configs,
                obter_dados_respondente: false,
              })
            )
          }
          textBtnDelete="Remover formulário"
        />
      </>
    );
  return '';
}
