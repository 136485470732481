import { toast } from 'react-toastify';
import { putQuizPublicado } from '../services/quizService';

export const toggleStatusPublicarQuiz = (quizId, publicado, dispatch) => {
  const newStatusPublicado = !publicado;

  toast.promise(dispatch(putQuizPublicado(quizId, newStatusPublicado)), {
    pending: newStatusPublicado ? 'Publicando quiz...' : 'Salvando...',
    success: newStatusPublicado
      ? 'Quiz publicado com sucesso!'
      : 'Salvo com sucesso!',
    error: {
      render({ data: { message, details } }) {
        if (typeof details !== 'undefined' && Array.isArray(details))
          details.map((detailMessage) =>
            toast.warn(
              <div dangerouslySetInnerHTML={{ __html: detailMessage }} />,
              {
                autoClose: false,
                // closeOnClick: false,
              }
            )
          );
        return message;
      },
    },
  });
};
