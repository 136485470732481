import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PreviewApresentacao,
  Actions,
  TextoBotaoInput,
  LoadingApresentacao,
  ContainerButton,
} from './styles';
import {
  selectBlocksApresentacao,
  selectTextBotaoIniciar,
  setBlocks,
  setTextBotaoIniciar,
} from '../../store/apresentacaoSlice';
import { selectIdQuiz, selectNaturezaQuiz } from '../../store/quizSlice';
import {
  getApresentacao,
  putApresentacao,
} from './../../services/apresentacaoService';
import { toggleShowToolbar } from '../../store/showSlice';
import Editor from '../../components/Editor';
import CloseButton from '../../components/Buttons/Close';
import DadosUsuario from '../../components/Form/DadosUsuario';
import { showFormApresentacao } from '../../utils/showForm';
import { getConfiguracaoFormulario } from '../../services/configFormDadosUsuarioService';
import LogoQuizClass from '../../components/Editor/LogoQuizClass';
import { Toolbar } from '../Toolbar';
function Apresentacao() {
  const dispatch = useDispatch();

  const idQuiz = useSelector(selectIdQuiz);
  const personalizacao = useSelector((state) => state.personalizacao);
  const blocks = useSelector(selectBlocksApresentacao);
  const configsForm = useSelector((state) => state.formDadosUsuario);
  const apresentacaoState = useSelector((state) => state.apresentacao);

  const showForm = showFormApresentacao(
    configsForm.obter_dados_respondente,
    configsForm.quando_obter_dados
  );
  const textBotaoIniciar = useSelector(selectTextBotaoIniciar);
  const [isLoading, setIsLoading] = useState(true);
  const natureza = useSelector(selectNaturezaQuiz);

  useEffect(() => {
    if (idQuiz) {
      dispatch(getApresentacao(idQuiz)).then(() =>
        dispatch(getConfiguracaoFormulario(idQuiz)).then(() =>
          setIsLoading(false)
        )
      );
    }
  }, [dispatch, idQuiz]);

  useEffect(() => {
    dispatch(toggleShowToolbar({ show: true, type: 'CONFIGURACAO_VISUAL' }));
    return () => {
      dispatch(toggleShowToolbar({ show: false, type: '' }));
    };
  }, []);

  const handleBlocks = (newBlocks) => {
    dispatch(setBlocks(newBlocks));

    const data = { ...apresentacaoState, blocks: newBlocks };
    putApresentacao(idQuiz, data);
  };

  const save = useCallback(() => {
    putApresentacao(idQuiz, apresentacaoState);
  }, [idQuiz, apresentacaoState]);

  return (
    <>
      {natureza === 'FLUXO' && (
        <>
          <Toolbar />
          <br />
        </>
      )}

      <h4
        style={{
          paddingBottom: 5,
        }}
      >
        Personalize a tela de apresentação do seu Quiz
      </h4>
      <p>
        Você pode: alterar a cor e imagem do plano de fundo, inserir e formatar
        os textos, imagens e botões do seu conteúdo de apresentação. Veja um
        tutorial{' '}
        <a href="https://youtu.be/IiMJppsVR4s" target="_blank">
          clicando aqui
        </a>
        .
      </p>
      <PreviewApresentacao
        className="border-radius pt-5"
        style={{
          background: personalizacao.cor_fundo_padrao,
          color: personalizacao.cor_texto_padrao,
        }}
        backgroundImage={personalizacao.background_image_apresentacao}
        backgroundSize={personalizacao.background_size_apresentacao}
        isLoading={isLoading}
        onBlur={save}
      >
        {/* <LoadingApresentacao
          className="animate-pulse border-radius"
          isLoading={isLoading}
        /> */}

        <Actions>{/* <CloseButton /> */}</Actions>

        <div className="container">
          <div className="row">
            <div
              className={
                showForm
                  ? 'col-12 col-lg-5 col-xl-5 offset-lg-1 offset-xl-1'
                  : 'col-12 col-lg-6 offset-lg-3'
              }
            >
              {Object.keys(blocks).length ? (
                <Editor
                  id="apresentacao"
                  blocks={blocks}
                  handleBlocks={handleBlocks}
                />
              ) : (
                ''
              )}

              <br />
              {!showForm && (
                <ContainerButton>
                  <button
                    className="btn btn-default btn-primary"
                    style={{
                      background: personalizacao.cor_fundo_primaria,
                    }}
                  >
                    <TextoBotaoInput
                      className="border-radius"
                      textoSecundario={personalizacao.cor_texto_secundaria}
                      textoPrimario={personalizacao.cor_texto_primaria}
                      value={textBotaoIniciar || 'Iniciar'}
                      onChange={(e) =>
                        dispatch(setTextBotaoIniciar(e.target.value))
                      }
                      maxLength="50"
                    />
                  </button>
                </ContainerButton>
              )}
            </div>
            {showForm && (
              <div className="col-12 col-lg-5 col-xl-5">
                <DadosUsuario />
              </div>
            )}
          </div>
        </div>
        <LogoQuizClass />
      </PreviewApresentacao>
    </>
  );
}

export default Apresentacao;
