import React, { useEffect, useRef } from 'react';
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import Aside from '../Aside';

function ShowMenu991Width() {
  const offCanvasRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const closeOffCanvas = () => offCanvasRef.current?.backdrop?.click();
    closeOffCanvas();
  }, [location.pathname]);

  return (
    <Navbar
      bg="light"
      expand="lg"
      className="showMenu991Width d-lg-none position-fixed"
    >
      <Container fluid>
        <Navbar.Brand
          href={`${process.env.REACT_APP_BASE_URL_BACKEND}/app/admin/quiz`}
          className="btn-voltar"
        >
          <FaChevronLeft className="me-3" />
          <img
            src="https://assetsus1.quizclass.com.br/assets/images/logoquizclass.svg"
            alt="Logo QuizClass"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-lg`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="end"
          ref={offCanvasRef}
          style={{ width: '100%' }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
              Menu
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end align-items-center flex-grow-1 pe-lg-3">
              <Aside desktop={false} />
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default ShowMenu991Width;
