import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 0.8rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 5px;
  
  input {
    visibility: hidden;
    position: absolute;
    top: -115px;
  }
  /* p {
    font-size: 0.93rem !important;
  } */
`;

export const EllipseColor = styled.div`
  width: 26px;
  height: 26px;
`;
