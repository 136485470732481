export default function validateUrl(url) {
  if (!url || typeof url === 'undefined') return true;

  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
}
