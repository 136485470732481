import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'quiz',
  initialState: {
    id: null,
    slug: null,
    codigo: null,
    nome: '',
    natureza: 'CERTO_ERRADO',
    nivel: 'NONE',
    assuntos: [],
    questoes: [],
    resultados: [],
    tipo_resultado: 'SOMATORIO_QUESTOES',
    integracao_rd_configurado: false,
    integracao_ac_configurado: false,
    integracao_zapier_configurado: false,
    subdomain: 'quiz',
    publicado: false,
    google_tag_manager_id: null,
    facebook_pixel_id: null,
    google_analytics_id: null,
    fonte: '',
    show_logo_quizclass: true,
    integracao_active_campaign_configurada: false,
    popover: {
      cor_botao_popover: '',
      imagem_icone_popover: '',
    },
    webhook: '',
    show_apresentacao: true,
  },
  reducers: {
    setQuiz(state, { payload }) {
      return payload;
    },
    setQuizNome(state, { payload: nome }) {
      return { ...state, nome };
    },
    setQuizPublicado(state, { payload: publicado }) {
      return { ...state, publicado };
    },
    setQuizQuestoes(state, { payload: questoes }) {
      return { ...state, questoes };
    },
    setQuizQuestaoNome(state, { payload: { questaId, pergunta } }) {
      let questaoSetNome = [...state.questoes];
      questaoSetNome = questaoSetNome.map((questao) => {
        return questao.id === questaId
          ? {
              ...questao,
              pergunta,
            }
          : questao;
      });

      return { ...state, questoes: questaoSetNome };
    },
    setQuizResultadoNome(state, { payload: { resultadoId, nome } }) {
      let resultadoSetNome = [...state.resultados];
      resultadoSetNome = resultadoSetNome.map((resultado) => {
        return resultado.id === resultadoId
          ? {
              ...resultado,
              nome,
            }
          : resultado;
      });

      return { ...state, resultados: resultadoSetNome };
    },
    setQuizResultadoFaixa(
      state,
      { payload: { resultadoId, valor_inicial, valor_final } }
    ) {
      let resultadoSetFaixa = [...state.resultados];
      resultadoSetFaixa = resultadoSetFaixa.map((resultado) => {
        return resultado.id === resultadoId
          ? {
              ...resultado,
              valor_inicial,
              valor_final,
            }
          : resultado;
      });

      return { ...state, resultados: resultadoSetFaixa };
    },
    setQuizResultados(state, { payload: resultados }) {
      return { ...state, resultados };
    },
    addQuestao(state, { payload }) {
      return { ...state, questoes: [...state.questoes, payload] };
    },
    setResultados(state, { payload: resultados }) {
      return { ...state, resultados };
    },
    addResultado(state, { payload }) {
      return { ...state, resultados: [...state.resultados, payload] };
    },
    setIntegracaoRDConfigurado(state, { payload }) {
      return { ...state, integracao_rd_configurado: payload };
    },
    setIntegracaoActiveCampaignConfigurada(
      state,
      { payload: integracao_active_campaign_configurada }
    ) {
      return { ...state, integracao_active_campaign_configurada };
    },
    setQuizTipoResultado(state, { payload: tipo_resultado }) {
      return { ...state, tipo_resultado };
    },
    setQuizSubdomain(state, { payload: subdomain }) {
      return { ...state, subdomain };
    },
    setQuizGoogleTagManagerId(state, { payload: google_tag_manager_id }) {
      return { ...state, google_tag_manager_id };
    },
    setQuizFacebookPixelId(state, { payload: facebook_pixel_id }) {
      return { ...state, facebook_pixel_id };
    },
    setQuizGoogleAnalyticsId(state, { payload: google_analytics_id }) {
      return { ...state, google_analytics_id };
    },
    setQuizFonte(state, { payload: fonte }) {
      return { ...state, fonte };
    },
    setQuizShowLogo(state, { payload: show_logo_quizclass }) {
      return { ...state, show_logo_quizclass };
    },
    setQuizPopoverConfig(
      state,
      { payload: { cor_botao_popover, imagem_icone_popover } }
    ) {
      return { ...state, popover: { cor_botao_popover, imagem_icone_popover } };
    },
    setQuizWebhook(state, { payload: webhook }) {
      return { ...state, webhook };
    },
    toggleQuizShowApresentacao(state) {
      return { ...state, show_apresentacao: !state.show_apresentacao };
    },
  },
});

export const {
  setQuiz,
  setQuizNome,
  setQuizQuestoes,
  addQuestao,
  setResultados,
  addResultado,
  setIntegracaoRDConfigurado,
  setQuizResultados,
  setQuizTipoResultado,
  setQuizQuestaoNome,
  setQuizPublicado,
  setQuizResultadoNome,
  setQuizResultadoFaixa,
  setQuizSubdomain,
  setQuizGoogleTagManagerId,
  setQuizFacebookPixelId,
  setQuizGoogleAnalyticsId,
  setQuizFonte,
  setQuizShowLogo,
  setIntegracaoActiveCampaignConfigurada,
  setQuizPopoverConfig,
  setQuizWebhook,
  toggleQuizShowApresentacao,
} = slice.actions;

export const selectIdQuiz = (state) => state.quiz.id;
export const selectSlugQuiz = (state) => state.quiz.slug;
export const selectCodigoQuiz = (state) => state.quiz.codigo;
export const selectNomeQuiz = (state) => state.quiz.nome;
export const selectQuestoesQuiz = (state) => state.quiz.questoes;
export const selectNaturezaQuiz = (state) => state.quiz.natureza;
export const hasIntegracaoRdConfigurado = (state) =>
  state.quiz.integracao_rd_configurado;
export const hasIntegracaoActiveCampaignConfigurada = (state) =>
  state.quiz.integracao_active_campaign_configurada;
export const selectResultadosQuiz = (state) => state.quiz.resultados;
export const selectTipoResultadoQuiz = (state) => state.quiz.tipo_resultado;
export const selectSubdomainQuiz = (state) => state.quiz.subdomain;
export const selectQuizPublicado = (state) => state.quiz.publicado;

export const selectQuizGoogleTagManagerId = (state) =>
  state.quiz.google_tag_manager_id;
export const selectQuizFacebookPixelId = (state) =>
  state.quiz.facebook_pixel_id;
export const selectQuizGoogleAnalyticsId = (state) =>
  state.quiz.google_analytics_id;
export const selectQuizFonte = (state) => state.quiz.fonte;
export const selectQuizShowLogo = (state) => state.quiz.show_logo_quizclass;
export const selectQuizPopoverConfig = (state) => state.quiz.popover;
export const selectQuizWebhook = (state) => state.quiz.webhook;
export const selectQuizShowApresentacao = (state) =>
  state.quiz.show_apresentacao;

export default slice.reducer;
