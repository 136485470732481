import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOpcaoTexto,
  setTrueOrFalseCaracteres,
} from '../../../../../store/questaoSlice';
import { FaTrash } from 'react-icons/fa';
import { Wrapper } from '../styles';
import SwitchCheckBox from '../../../../../components/SwitchCheckbox';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { putQuestao } from '../../../../../services/questaoService';

function VF({
  opcao,
  index,
  saveOpcao,
  excluirOpcao,
  saveOpcaoRespostaCorreta,
}) {
  const questao = useSelector((state) => state.questao);
  const dispatch = useDispatch();

  const saveQuestaoTrueOrFalseCaracteres = (trueOrFalse, texto) => {
    let true_or_false_caracteres = [];
    if (trueOrFalse) {
      true_or_false_caracteres = [texto, questao.true_or_false_caracteres[1]];
    } else {
      true_or_false_caracteres = [questao.true_or_false_caracteres[0], texto];
    }

    const data = {
      ...questao,
      true_or_false_caracteres,
    };

    putQuestao(questao.id, data);
  };

  return (
    <Wrapper className="bg-default border-radius">
      <div className="text-nowrap">
        <SwitchCheckBox
          id="true_or_false_input"
          name="check-opcao"
          checked={opcao.resposta_correta ? true : false}
          onChange={() => saveOpcaoRespostaCorreta(index, true)}
        />
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip>Insira uma letra para sinalizar verdadeiro</Tooltip>
          }
        >
          <input
            className="true-input me-2 ms-1"
            type="text"
            value={
              questao.true_or_false_caracteres
                ? questao.true_or_false_caracteres[0]
                : 'V'
            }
            onChange={(e) => {
              dispatch(
                setTrueOrFalseCaracteres({
                  trueOrFalse: true,
                  texto: e.target.value,
                })
              );
              saveQuestaoTrueOrFalseCaracteres(true, e.target.value);
            }}
            maxLength={1}
          />
        </OverlayTrigger>
        <SwitchCheckBox
          id="true_or_false_input"
          name="check-opcao"
          checked={!opcao.resposta_correta ? true : false}
          onChange={() => saveOpcaoRespostaCorreta(index, false)}
        />
        <OverlayTrigger
          placement={'top'}
          overlay={<Tooltip>Insira uma letra para sinalizar falso</Tooltip>}
        >
          <input
            type="text"
            className="false-input me-2 ms-1"
            value={
              questao.true_or_false_caracteres
                ? questao.true_or_false_caracteres[1]
                : 'F'
            }
            onChange={(e) => {
              dispatch(
                setTrueOrFalseCaracteres({
                  trueOrFalse: false,
                  texto: e.target.value,
                })
              );
              saveQuestaoTrueOrFalseCaracteres(false, e.target.value);
            }}
            maxLength={1}
          />
        </OverlayTrigger>
      </div>
      <input
        type="text"
        className="form-control form-default"
        placeholder="Opção de resposta"
        value={opcao.texto}
        onChange={(e) =>
          dispatch(setOpcaoTexto({ index, texto: e.target.value }))
        }
        onBlur={() => saveOpcao(index, opcao.id)}
      ></input>
      <button
        className="btn flex-center-default"
        onClick={() => excluirOpcao(index, opcao.id)}
        title="Excluir opção"
      >
        <FaTrash color="#FC6D5D" size={17} />
      </button>
    </Wrapper>
  );
}

export default VF;
