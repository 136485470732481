export const showFormApresentacao = (
  obterDadosRespondente,
  quandoObterDados
) => {
  if (obterDadosRespondente && quandoObterDados === 'INICIO') {
    return true;
  }

  return false;
};


export const showFormResultado = (
  obterDadosRespondente,
  quandoObterDados
) => {
  if (obterDadosRespondente && quandoObterDados === 'FIM') {
    return true;
  }

  return false;
};
