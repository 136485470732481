import React, { useId } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  deleteQuestao,
  deleteQuestaoMatrizPerguntas,
  getQuestao,
  postQuestaoMatrizPerguntas,
  putQuestao,
} from '../../services/questaoService';
// import ExplicacaoTipoResposta from './components/ExplicacaoTipoResposta';
import Opcoes from './components/Opcoes';
import { BsPlayCircle } from 'react-icons/bs';
import { Container } from './styles';
import {
  removePergunta,
  setExplicacaoResposta,
  setPergunta,
  setTextoMatrizPergunta,
} from '../../store/questaoSlice';
import CKEditor from '../../components/CKEditor';
import {
  toggleShowModalConfirmDeletion,
  toggleShowModalTiposQuestao,
  toggleShowToolbar,
} from '../../store/showSlice';
import ModalConfirmDeletion from '../../components/Modal/ConfirmDeletion';
import {
  selectIdQuiz,
  selectNaturezaQuiz,
  selectQuestoesQuiz,
  selectResultadosQuiz,
  selectSlugQuiz,
  selectSubdomainQuiz,
  setQuizQuestaoNome,
  setQuizQuestoes,
  setQuizResultadoFaixa,
} from '../../store/quizSlice';
import { useNavigate } from 'react-router-dom';
import ModalFeedback from './components/Modal/Feedback';
import ModalRegrasLogicaValor from './components/Modal/RegrasLogicaValor';
import { getTextTipoQuestao } from '../../utils/tipoQuestao';
import { getUrl } from '../../utils/urlResponderQuiz';
import { postOpcao } from '../../services/opcaoService';
import {
  selectDominioStatusCname,
  selectDominioUrlVisualizarQuiz,
} from '../../store/dominioSlice';
import { FaTrash } from 'react-icons/fa';
import { getConfiguracaoQuiz } from '../../services/quizService';
import { Toolbar } from '../Toolbar';
import {
  selectEditorFluxoQuestoes,
  setQuestoesEditorFluxo,
  setSelectedEditableContentEditorFluxo,
} from '../../store/editorFluxosSlice';

function Questao({ questaoId }) {
  const dispatch = useDispatch();
  const questao = useSelector((state) => state.questao);
  const quizId = useSelector(selectIdQuiz);
  const [isLoading, setIsloading] = useState(true);
  const navigate = useNavigate();
  const questoesMenu = useSelector(selectQuestoesQuiz);
  const quizSlug = useSelector(selectSlugQuiz);
  const subdomain = useSelector(selectSubdomainQuiz).toLowerCase();
  const urlVisualizar = getUrl(subdomain, quizSlug);
  const [adicionandoOpcao, setAdicionandoOpcao] = useState(false);
  const [adicionandoPergunta, setAdicionandoPergunta] = useState(false);
  const resultados = useSelector(selectResultadosQuiz);
  const dominioStatusCname = useSelector(selectDominioStatusCname);
  const dominioUrlVisualizarQuiz =
    useSelector(selectDominioUrlVisualizarQuiz) + '/' + quizSlug;
  const natureza = useSelector(selectNaturezaQuiz);
  const configuracao = useSelector((state) => state.configuracao);
  const questoesEditorFluxo = useSelector(selectEditorFluxoQuestoes);

  const campoChecked = (campo) => {
    if (campo == 'Nome' && configuracao.obter_nome) {
      return true;
    }
    if (campo == 'E-mail' && configuracao.obter_email) {
      return true;
    }
    if (campo == 'Telefone' && configuracao.obter_telefone) {
      return true;
    }
    if (campo == 'Empresa' && configuracao.obter_empresa) {
      return true;
    }
    if (campo == 'CNPJ' && configuracao.obter_cnpj) {
      return true;
    }
    if (campo == 'CPF' && configuracao.obter_cpf) {
      return true;
    }
    if (campo == 'Cidade' && configuracao.obter_cidade) {
      return true;
    }
    for (const campoSelecionado of configuracao.campos_personalizados_selecionados) {
      if (campoSelecionado.nome === campo) return true;
    }
    return false;
  };

  const mentions =
    configuracao.obter_dados_respondente &&
    configuracao.quando_obter_dados === 'INICIO'
      ? configuracao?.ordem_campos
          .map((ordemCampo) => {
            if (campoChecked(ordemCampo.campo)) {
              return '@' + ordemCampo.campo;
            }
            return '';
          })
          .filter((elemento) => elemento)
      : [];

  useEffect(() => {
    async function requests() {
      try {
        await Promise.all([
          dispatch(getConfiguracaoQuiz(quizId)),
          dispatch(getQuestao(questaoId)),
        ]);
      } catch (error) {}
      setIsloading(false);
    }

    requests();
    return () => {
      setIsloading(true);
    };
  }, [questaoId, quizId]);

  const saveQuestao = () => {
    putQuestao(questao.id, questao);
    dispatch(
      setQuizQuestaoNome({ questaId: questao.id, pergunta: questao.pergunta })
    );
  };

  const excluirQuestao = () => {
    deleteQuestao(questaoId, quizId).then((data) => {
      dispatch(toggleShowModalConfirmDeletion());
      if (natureza === 'FLUXO') {
        const newQuestoesEditorFluxo = questoesEditorFluxo.filter(
          (questaoEditorFluxo) => questaoEditorFluxo.id !== questaoId
        );
        dispatch(setQuestoesEditorFluxo(newQuestoesEditorFluxo));
        dispatch(
          setSelectedEditableContentEditorFluxo({
            id: null,
            type: null,
            show: false,
          })
        );
      } else {
        const { questoes: newQuestoes } = data;

        let newQuestaoSelected = 0;
        const questaoSelected = questoesMenu.findIndex(
          (questaoMenu) => questaoMenu.id === questaoId
        );

        if (questoesMenu.length === 1 || questoesMenu.length === 2) {
          newQuestaoSelected = 0;
        } else if (questaoSelected === 0) {
          newQuestaoSelected = 0;
        } else if (questaoSelected === questoesMenu.length - 1) {
          newQuestaoSelected = questaoSelected - 1;
        } else {
          newQuestaoSelected = questaoSelected - 1;
        }

        dispatch(setQuizQuestoes(newQuestoes));

        if (resultados.length === 1 && data.resultado)
          dispatch(
            setQuizResultadoFaixa({
              resultadoId: data.resultado.id,
              valor_inicial: data.resultado.valor_inicial,
              valor_final: data.resultado.valor_final,
            })
          );

        if (newQuestoes.length > 0) {
          navigate(
            `/app/quizzes/${quizId}/questoes/${newQuestoes[newQuestaoSelected].id}`
          );
        } else navigate(`/app/quizzes/${quizId}/apresentacao`);
      }
    });
  };

  const handleTipoQuestao = () => {
    dispatch(toggleShowModalTiposQuestao({ show: true, type: 'EDIT' }));
  };

  const handleAddOpcao = async () => {
    try {
      setAdicionandoOpcao(true);
      await dispatch(postOpcao(questaoId));
      setAdicionandoOpcao(false);
    } catch (e) {
      setAdicionandoOpcao(false);
      console.error(e);
    }
  };

  const handleNovaPergunta = async () => {
    try {
      setAdicionandoPergunta(true);
      await dispatch(postQuestaoMatrizPerguntas(questao.id));
      setAdicionandoPergunta(false);
    } catch (e) {
      setAdicionandoPergunta(false);
    }
  };

  const handleRemovePerguntaMatriz = async (idPerguntaMatriz) => {
    try {
      await deleteQuestaoMatrizPerguntas(questao.id, idPerguntaMatriz);
      dispatch(removePergunta(idPerguntaMatriz));
    } catch (e) {}
  };

  const showOPtions = () => {
    if (
      (natureza === 'PESQUISA' &&
        ['TEXTO_CURTO', 'TEXTO_LONGO', 'ABERTA'].includes(questao.tipo)) ||
      ['NPS'].includes(questao.tipo)
    )
      return false;

    return true;
  };

  useEffect(() => {
    dispatch(toggleShowToolbar({ show: true, type: 'CONFIGURACAO_QUESTAO' }));
    return () => {
      dispatch(toggleShowToolbar({ show: false, type: '' }));
    };
  }, []);

  return isLoading ? (
    <div>Carregando...</div>
  ) : (
    <>
      {natureza === 'FLUXO' && <Toolbar />}
      <Container
        className={natureza === 'FLUXO' ? 'col-12 mt-3' : 'col-12 col-lg-11'}
        style={{ display: isLoading && 'none' }}
      >
        <div className="d-flex flex-row gap-3 align-items-center mb-4 mt-2">
          <h2>
            Questão {questao.numero_questao} -{' '}
            {getTextTipoQuestao(questao.tipo)}
          </h2>
          <button
            className="btn btn-secondary btn-sm"
            onClick={handleTipoQuestao}
            title="Editar tipo da questão"
          >
            Editar
          </button>
        </div>

        <div className="row">
          <div className="col-12 col-lg-7">
            <div className="mb-4">
              <label className="mb-3">Pergunta</label>

              <br />
              {['MATRIZ_SELECAO_UNICA', 'MATRIZ_SELECAO_MULTIPLA'].includes(
                questao.tipo
              ) ? (
                <>
                  {questao.matriz_perguntas.map((matrizPergunta) => (
                    <div
                      className="position-relative mb-3"
                      key={matrizPergunta.id}
                    >
                      <CKEditor
                        content={matrizPergunta.pergunta}
                        onChange={(text) =>
                          dispatch(
                            setTextoMatrizPergunta({
                              id: matrizPergunta.id,
                              pergunta: text,
                            })
                          )
                        }
                        onBlur={saveQuestao}
                        mentions={mentions}
                      />
                      <button
                        className="btn btn-sm shadow-none position-absolute d-none d-sm-block"
                        style={{ top: 0, right: 0 }}
                        title="Remover pergunta"
                        onClick={() =>
                          handleRemovePerguntaMatriz(matrizPergunta.id)
                        }
                      >
                        <FaTrash color="#FC6D5D" size={17} />
                      </button>
                    </div>
                  ))}
                  <button
                    className="btn btn-secondary btn-sm mt-3"
                    onClick={handleNovaPergunta}
                    disabled={adicionandoPergunta}
                  >
                    {adicionandoPergunta
                      ? 'Crianda pergunta...'
                      : 'Adicionar pergunta'}
                  </button>
                </>
              ) : (
                <CKEditor
                  content={questao.pergunta}
                  onChange={(text) => dispatch(setPergunta(text))}
                  onBlur={saveQuestao}
                  mentions={mentions}
                />
              )}
            </div>

            {!['MATRIZ_SELECAO_UNICA', 'MATRIZ_SELECAO_MULTIPLA'].includes(
              questao.tipo
            ) && (
              <div>
                <label className="mb-3">Explicação da resposta</label>
                <br />
                <CKEditor
                  content={questao.explicacao_resposta}
                  onChange={(text) => dispatch(setExplicacaoResposta(text))}
                  onBlur={saveQuestao}
                  mentions={mentions}
                />
              </div>
            )}
          </div>

          <div
            className="col-12 col-lg-5 ps-4 d-flex flex-column gap-3 justify-content-start"
            style={{ marginTop: 37 }}
          >
            {showOPtions() ? (
              <>
                <Opcoes tipoQuestao={questao.tipo} />

                <div className="d-flex justify-content-left">
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={handleAddOpcao}
                    disabled={adicionandoOpcao}
                  >
                    {adicionandoOpcao
                      ? 'Criando opção...'
                      : 'Adicionar opção de resposta'}
                  </button>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-center">
          <a
            href={`${
              dominioStatusCname ? dominioUrlVisualizarQuiz : urlVisualizar
            }/questoes?questaoId=${questaoId}`}
            target="_blank"
            rel="noreferrer"
            className="btn btn-secondary btn-lg d-flex align-items-center"
          >
            <span>
              Testar a questão <BsPlayCircle className="ms-3" size={30} />
            </span>
          </a>
        </div>
        <ModalConfirmDeletion
          title="Excluir questão"
          onClick={excluirQuestao}
          textBtnDelete="Excluir questão"
        />
        <ModalFeedback />
        <ModalRegrasLogicaValor />
      </Container>
    </>
  );
}

export default Questao;
