import React from 'react';
import { Field, Formik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  selectShowModalConfiguracaoResultado,
  toggleShowModalConfiguracaoResultado,
} from '../../../../../store/showSlice';
import {
  selectQuestoesQuiz,
  selectTipoResultadoQuiz,
  setQuizResultadoFaixa,
} from '../../../../../store/quizSlice';
import {
  postResultado,
  putResultado,
} from '../../../../../services/resultadoService';
import { setResultado } from '../../../../../store/resultadoSlice';

function ConfiguracaoResultado() {
  const dispatch = useDispatch();
  const show = useSelector(selectShowModalConfiguracaoResultado);
  const resultado = useSelector((state) => state.resultado);
  const tipoResultado = useSelector(selectTipoResultadoQuiz);
  const questoes = useSelector(selectQuestoesQuiz);

  return (
    <Modal
      show={show}
      onHide={() => dispatch(toggleShowModalConfiguracaoResultado())}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Configurar faixas de valores para este resultado</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={resultado}
        onSubmit={(values, { setSubmitting }) => {
          const data = { ...resultado, ...values };
          putResultado(resultado.id, data).then(() => {
            dispatch(setResultado(data));
            dispatch(
              setQuizResultadoFaixa({
                resultadoId: resultado.id,
                valor_inicial: data.valor_inicial,
                valor_final: data.valor_final,
              })
            );
            setSubmitting(false);
            toast.success('Configuração salva!');
            dispatch(toggleShowModalConfiguracaoResultado());
          });
        }}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="container d-flex flex-column">
              {tipoResultado === 'SOMATORIO_QUESTOES' ? (
                <div className="row">
                  <div className="col-6">
                    <div className="form-group mb-3">
                      <label>De</label>
                      <Field
                        type="number"
                        className="form-control form-default"
                        name="valor_inicial"
                        value={values.valor_inicial}
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group mb-3">
                      <label>Até</label>
                      <Field
                        type="number"
                        className="form-control form-default"
                        name="valor_final"
                        value={values.valor_final}
                      />
                    </div>
                  </div>
                </div>
              ) : tipoResultado === 'SOMATORIO_GRUPO_QUESTOES' ? (
                <div className="row">
                  <div className="col-6">
                    <div className="form-group mb-3">
                      <label>Da questão</label>
                      <Field
                        as="select"
                        className="form-control form-select form-default"
                        name="questao_inicial_id"
                      >
                        <option value="" disabled>
                          Selecione uma questão
                        </option>
                        {questoes.map((questao, index) => (
                          <option key={questao.id} value={questao.id}>
                            {questao.pergunta
                              ? `${index + 1} - ${questao.pergunta
                                  .replace(/<[^>]+>/g, '')
                                  .substring(0, 50)}...`
                              : `${index + 1} - Nova questão`}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group mb-3">
                      <label>Até questão</label>
                      <Field
                        as="select"
                        className="form-control form-select form-default"
                        name="questao_final_id"
                      >
                        <option value="" disabled>
                          Selecione uma questão
                        </option>
                        {questoes.map((questao, index) => (
                          <option key={questao.id} value={questao.id}>
                            {questao.pergunta
                              ? `${index + 1} - ${questao.pergunta
                                  .replace(/<[^>]+>/g, '')
                                  .substring(0, 50)}...`
                              : `${index + 1} - Nova questão`}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group mb-3">
                      <label>Tipo de validação do valor</label>
                      <Field
                        as="select"
                        className="form-control form-select form-default"
                        name="tipo_validacao_grupo_questao"
                      >
                        <option value="" disabled>
                          Selecione um tipo
                        </option>
                        <option value="MAIOR_IGUAL">Maior ou igual ≥</option>
                        <option value="MAIOR">Maior{' >'}</option>
                        <option value="MENOR_IGUAL">Menor ou igual ≤</option>
                        <option value="MENOR">Menor{' <'}</option>
                        <option value="IGUAL">Igual =</option>
                      </Field>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group mb-3">
                      <label>Valor</label>
                      <Field
                        type="number"
                        className="form-control form-default"
                        name="valor_grupo_questao"
                        value={values.valor_grupo_questao || 0}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => dispatch(toggleShowModalConfiguracaoResultado())}
              >
                Fechar
              </Button>
              <button
                className="btn btn-default shadow-default ps-4 pe-4"
                type="submit"
                disabled={isSubmitting}
              >
                Salvar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ConfiguracaoResultado;
