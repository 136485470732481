import { Field, Formik } from 'formik';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { putQuestao } from '../../../../../services/questaoService';
import { setQuestao } from '../../../../../store/questaoSlice';
import {
  selectNaturezaQuiz,
  selectResultadosQuiz,
  setQuizResultadoFaixa,
} from '../../../../../store/quizSlice';
import { FaInfoCircle } from 'react-icons/fa';
import {
  selectShowModalRegrasLogicaValor,
  toggleShowModalRegrasLogicaValor,
} from '../../../../../store/showSlice';
import ResultadosCorrelacionados from './ResultadosCorrelacionados';

function RegrasLogicaValor() {
  const dispatch = useDispatch();
  const show = useSelector(selectShowModalRegrasLogicaValor);
  const questao = useSelector((state) => state.questao);
  const naturezaQuiz = useSelector(selectNaturezaQuiz);
  const resultados = useSelector(selectResultadosQuiz);

  return (
    <Modal
      show={show}
      onHide={() => dispatch(toggleShowModalRegrasLogicaValor())}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Configurar regras, lógica e valor</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={questao}
        onSubmit={(values, { setSubmitting }) => {
          putQuestao(questao.id, values).then((data) => {
            if (resultados.length === 1 && data.resultado)
              dispatch(
                setQuizResultadoFaixa({
                  resultadoId: data.resultado.id,
                  valor_inicial: data.resultado.valor_inicial,
                  valor_final: data.resultado.valor_final,
                })
              );

            dispatch(setQuestao(values));
            setSubmitting(false);
            toast.success('Configuração salva!');
            dispatch(toggleShowModalRegrasLogicaValor());
          });
        }}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="container d-flex flex-column">
              <div className="form-check mb-3">
                <label
                  className="form-check-label"
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <Field
                    className="form-check-input me-1"
                    type="checkbox"
                    name="obrigatoria"
                    checked={values.obrigatoria}
                    onChange={(e) =>
                      setFieldValue('obrigatoria', e.target.checked)
                    }
                  />
                  Obrigatória
                </label>
              </div>

              <div className="form-check mb-3">
                <label
                  className="form-check-label"
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <Field
                    className="form-check-input me-1"
                    type="checkbox"
                    name="gatilho"
                    checked={values.gatilho || false}
                    onChange={(e) => setFieldValue('gatilho', e.target.checked)}
                  />
                  Esta questão é um gatilho para automação do meu funil de
                  marketing/vendas{' '}
                  <a href="#">
                    <FaInfoCircle />
                  </a>
                </label>
              </div>

              {naturezaQuiz === 'CERTO_ERRADO' ? (
                <div className="form-group mb-3">
                  <label>Valor da questão</label>
                  <Field
                    // style={{ width: 132 }}
                    className="form-control form-default shadow-default"
                    type="number"
                    min="0"
                    max="9999.99"
                    name="valor"
                    value={values.valor ? values.valor : 0}
                  />
                </div>
              ) : (
                ''
              )}

              <div className="form-group mb-3">
                <label> Tempo de resposta da questão</label>
                <Field
                  as="select"
                  name="tempo"
                  className="form-control form-select form-default"
                >
                  <option value="NONE">Sem limite</option>
                  <option value="10">10 segundos</option>
                  <option value="20">20 segundos</option>
                  <option value="30">30 segundos</option>
                  <option value="60">1 minuto</option>
                  <option value="120">2 minutos</option>
                  <option value="300">5 minutos</option>
                </Field>
              </div>

              {naturezaQuiz === 'PERSONALIDADE' ? (
                <div className="form-group mb-3">
                  <label className="mb-3">Resultados correlacionados</label>
                  <ResultadosCorrelacionados
                    opcoes={values.opcoes}
                    setFieldValue={setFieldValue}
                  />
                </div>
              ) : (
                ''
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => dispatch(toggleShowModalRegrasLogicaValor())}
              >
                Fechar
              </Button>
              <button
                className="btn btn-default shadow-default ps-4 pe-4"
                type="submit"
                disabled={isSubmitting}
              >
                Salvar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default RegrasLogicaValor;
