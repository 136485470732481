import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowToolbar } from '../../store/showSlice';
import { ToolbarConfiguracoesQuestoes } from './ConfiguracoesQuestoes';
import { ToolbarConfiguracoesVisuais } from './ConfiguracoesVisuais';
import { ToolbarConfiguracoesResultados } from './ConfiguracoesResultados';
import { ToolbarPersonalizacao } from './Personalizacao';
import { Container } from './styles';
import {
  selectIdQuiz,
  selectQuizFonte,
  setQuizFonte,
} from '../../store/quizSlice';
import { putQuizFonte } from '../../services/quizService';
import BlocoLogico from './BlocoLogico';

export function Toolbar() {
  const dispatch = useDispatch();
  const { show, type } = useSelector(selectShowToolbar);
  const quizId = useSelector(selectIdQuiz);
  const fonte = useSelector(selectQuizFonte);

  const handleFonte = (e) => {
    const fonte = e.target.value;

    dispatch(setQuizFonte(fonte));
    putQuizFonte(quizId, fonte);
  };

  const layoutsTipoToolbar = {
    CONFIGURACAO_VISUAL: (
      <ToolbarConfiguracoesVisuais fonte={fonte} handleFonte={handleFonte} />
    ),
    CONFIGURACAO_QUESTAO: <ToolbarConfiguracoesQuestoes />,
    CONFIGURACAO_RESULTADO: (
      <ToolbarConfiguracoesResultados fonte={fonte} handleFonte={handleFonte} />
    ),
    PERSONALIZACAO: <ToolbarPersonalizacao />,
    BLOCO_LOGICO: <BlocoLogico />,
  };

  return <Container show={show}>{layoutsTipoToolbar[type]}</Container>;
}
