import React, { useId, useRef } from 'react';

import { Container, OpcaoNao, OpcaoSim } from './styles';
import ConnectPointsWrapper from '../ConnectPointsWrapper';

function BlocoLogico({ boxId, dropBoxRef, dragBoxRef, blocoLogico }) {
  const id1 = 'BLOCO_LOGICO_SIM_' + blocoLogico.id;
  const id2 = 'BLOCO_LOGICO_NAO_' + blocoLogico.id;

  const itemRef1 = useRef();
  const itemRef2 = useRef();

  return (
    <Container
      className={`content border-radius ${
        blocoLogico.newBlock ? 'animate-pulse' : ''
      }`}
      style={{ border: blocoLogico.newBlock ? '4px solid red' : 'none' }}
    >
      <OpcaoSim id={id1} ref={itemRef1} data-opcao-type={'SIM'}>
        <ConnectPointsWrapper
          itemId={id1}
          itemRef={itemRef1}
          {...{ boxId, dropBoxRef, dragBoxRef }}
        />
      </OpcaoSim>

      <OpcaoNao id={id2} ref={itemRef2} data-opcao-type={'NAO'}>
        <ConnectPointsWrapper
          itemId={id2}
          itemRef={itemRef2}
          {...{ boxId, dropBoxRef, dragBoxRef }}
        />
      </OpcaoNao>
    </Container>
  );
}

export default BlocoLogico;
