import styled from 'styled-components';

export const Container = styled.div``;

export const ListQuestoes = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 10px;

  max-height: 200px;
  overflow: auto;
  list-style: none;
  padding: 15px 0 0 0;

  a,
  span {
    font-weight: 500;
  }
`;
