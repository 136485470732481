import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;

  position: fixed;
  width: 200px;
  height: 100%;
  top: 50px;

  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 20px;
  padding: 15px 15px 70px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 3;

  grid-area: aside;
  a,
  span {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 0.933rem;
  }

  .active,
  .active span {
    font-weight: 800;
  }

  @media (max-width: 991px) {
    position: relative;
    padding: 0;
    width: 100%;
    box-shadow: none;
    top: 0;
  }
`;
