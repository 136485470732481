import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getValidacaoAcessoIntegracoes } from '../../services/quizService';
import {
  hasIntegracaoActiveCampaignConfigurada,
  hasIntegracaoRdConfigurado,
  selectIdQuiz,
  selectQuizFacebookPixelId,
  selectQuizGoogleAnalyticsId,
  selectQuizGoogleTagManagerId,
} from './../../store/quizSlice';
import {
  ContainerEnunciado,
  IntegracoesDisponiveis,
  IntegracaoItem,
} from './styles';
import Webhook from './components/Webhook';

function Integracao() {
  const quizId = useSelector(selectIdQuiz);
  const integracaoRDConfigurado = useSelector(hasIntegracaoRdConfigurado);
  const integracaoActiveCampaignConfigurada = useSelector(hasIntegracaoActiveCampaignConfigurada);
  const integracaoGoogleAnalyticsConfigurada = Boolean(
    useSelector(selectQuizGoogleAnalyticsId)?.trim()
  );
  const integracaoGoogleTagManagerConfigurada = Boolean(
    useSelector(selectQuizGoogleTagManagerId)?.trim()
  );
  const integracaoFacebookPixelConfigurada = Boolean(
    useSelector(selectQuizFacebookPixelId)?.trim()
  );
  const [isLoading, setIsloading] = useState(true);
  const [podeRealizarIntegracoesNativas, setPodeRealizarIntegracoesNativas] =
    useState(true);
  const [podeRealizarIntegracoesZapier, setPodeRealizarIntegracoesZapier] =
    useState(true);

  useEffect(() => {
    const getValidacao = async () => {
      try {
        const data = await getValidacaoAcessoIntegracoes(quizId);
        if (!data.possuiAcessoIntegracaoZapier)
          setPodeRealizarIntegracoesZapier(false);
      } catch (error) {
        if (error.response.status === 403) {
          setPodeRealizarIntegracoesZapier(false);
          setPodeRealizarIntegracoesNativas(false);
        }
      }
      setIsloading(false);
    };

    getValidacao();

    return () => {
      setIsloading(true);
    };
  }, []);

  return isLoading ? (
    <div>Carregando...</div>
  ) : (
    <>
      <h2>Integrações do seu Quiz</h2>
      <p className="pb-3">
        Quando um Lead é gerado ele pode ser enviado para um outro app (como os
        de automação de marketing) ou você pode captar métricas do seu Quiz por
        meio das nossas integrações. Escolha uma ou mais opções abaixo para
        configurar:
      </p>

      <h4>Integrações nativas</h4>
      <p className="pb-2">
        Integrações nativas são aquelas que o Quiz Class está conectado
        diretamente, sem a necessidade da intermediação.
        {!podeRealizarIntegracoesNativas && <span className='text-danger'> Seu plano não possui acesso a este recurso.</span>}
      </p>
      <IntegracoesDisponiveis
        disabled={podeRealizarIntegracoesNativas ? false : true}
      >
        <IntegracaoItem selected={integracaoRDConfigurado}>
          <Link to={`/app/quizzes/${quizId}/integracoes/rdstation/configura`}>
            <img
              src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/integracao/rd_icon.webp"
              alt="RD Station icon"
            />
            <h6>RD Station Marketing</h6>
          </Link>
        </IntegracaoItem>

        <IntegracaoItem selected={integracaoActiveCampaignConfigurada}>
          <Link to={`/app/quizzes/${quizId}/integracoes/active-campaign`}>
            <img
              src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/integracao/active_icon.webp"
              alt="ActiveCampaign icon"
            />
            <h6>ActiveCampaign</h6>
          </Link>
        </IntegracaoItem>

        <IntegracaoItem selected={integracaoGoogleTagManagerConfigurada}>
          <Link to={`/app/quizzes/${quizId}/integracoes/google-tag-manager`}>
            <img
              src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/integracao/gtm_icon.webp"
              alt="GTM icon"
            />
            <h6>Google TAG Manager</h6>
          </Link>
        </IntegracaoItem>
        <IntegracaoItem selected={integracaoFacebookPixelConfigurada}>
          <Link to={`/app/quizzes/${quizId}/integracoes/facebook-pixel`}>
            <img
              src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/integracao/fb_pixel.webp"
              alt="Facebook Pixel icon"
            />
            <h6>Facebook Pixel</h6>
          </Link>
        </IntegracaoItem>
        <IntegracaoItem selected={integracaoGoogleAnalyticsConfigurada}>
          <Link to={`/app/quizzes/${quizId}/integracoes/google-analytics`}>
            <img
              src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/integracao/ic_analytics.svg"
              alt="Google Analytics icon"
              style={{
                height: 63,
                width: 93,
              }}
            />
            <h6>Google Analytics 4</h6>
          </Link>
        </IntegracaoItem>
      </IntegracoesDisponiveis>

      <ContainerEnunciado>
        <div>
          <img
            src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/integracao/zapier_icon.webp"
            alt="Zapier icon"
          />
        </div>
        <div>
          <h4>Integrações por meio do Zapier</h4>          
          <p className="pb-2">
            O Quiz Class está integrado com o Zapier e o Zapier está integrado
            com mais de 3000 outros aplicativos. Logo, o Quiz Class pode se
            integrar com todos eles. Escolha um template pré-configurado e faça
            sua integração.
            {!podeRealizarIntegracoesZapier && <span className='text-danger'> Seu plano não possui acesso a este recurso.</span>}
          </p>
          
        </div>
      </ContainerEnunciado>
      <IntegracoesDisponiveis
        disabled={podeRealizarIntegracoesZapier ? false : true}
      >
        {/* <IntegracaoItem>
          <a
            href="https://zapier.com/apps/quiz-class/integrations/activecampaign"
            target="_blank"
          >
            <img
              src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/integracao/active_icon.webp"
              alt="Active Campaign icon"
            />
            <h6>Active Campaign</h6>
          </a>
        </IntegracaoItem> */}
        <IntegracaoItem>
          <a
            href="https://zapier.com/apps/quiz-class/integrations/mailchimp"
            target="_blank"
          >
            <img
              src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/integracao/mailchimp.webp"
              alt="Mailchimp icon"
            />
            <h6>Mailchimp</h6>
          </a>
        </IntegracaoItem>
        <IntegracaoItem>
          <a
            href="https://zapier.com/apps/quiz-class/integrations/gmail"
            target="_blank"
          >
            <img
              src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/integracao/gmail-icon.png"
              alt="Gmail"
            />
            <h6>Gmail</h6>
          </a>
        </IntegracaoItem>
        <IntegracaoItem>
          <a
            href="https://zapier.com/apps/quiz-class/integrations/google-sheets"
            target="_blank"
          >
            <img
              src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/integracao/excel-icon.png"
              alt="Planilhas Google"
            />
            <h6>Planilhas Google</h6>
          </a>
        </IntegracaoItem>
        <IntegracaoItem>
          <Link to={`/app/quizzes/${quizId}/integracoes/zapier`}>
            <img
              src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/integracao/zapier_icon.webp"
              alt="Zapier icon"
            />
            <h6>Zapier</h6>
          </Link>
        </IntegracaoItem>
      </IntegracoesDisponiveis>

      <Webhook />
    </>
  );
}

export default Integracao;
