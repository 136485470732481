import React from 'react';
import { useParams } from 'react-router-dom';
import Resultado from '../../components/Resultado';

function Index() {
  const { resultadoId } = useParams();

  return (
    <div>
      <Resultado resultadoId={resultadoId} />
    </div>
  );
}

export default Index;
