import React from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export function ToolbarPersonalizacao() {
  return (
    <>
      <Link to={-1}>
        <button className="btn btn-sm btn-secondary toolbar-item">
          <FaChevronLeft /> Voltar
        </button>
      </Link>
    </>
  );
}
