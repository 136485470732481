import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  .connectPoint {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${({ connectPointDisabled }) =>
      connectPointDisabled ? '#6395EC' : 'black'};
    opacity: ${({ connectPointDisabled }) => (connectPointDisabled ? 1 : 0.2)};
  }

  .connectPoint:hover {
    opacity: 0.6;
  }
`;
