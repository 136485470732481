import { setQuizResultadoNome, setResultados } from '../store/quizSlice';
import { setResultadosEditorFluxo } from '../store/editorFluxosSlice';
import { setResultado } from '../store/resultadoSlice';
import apiQuizClass from './apiQuizClass';

export const getResultados = () => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(`/resultados`);

      dispatch(setResultadosEditorFluxo(response.data));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

export const getResultado = (resultadoId, quizId = '') => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(
        `/resultados/${resultadoId}?quiz_id=${quizId}`
      );

      dispatch(setResultado(response.data));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

export const postResultado = (quiz_id, type = 'BLOCKS') => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.post(`/resultados`, {
        quiz_id,
        type,
      });
      dispatch(setResultados(response.data.resultados));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

export const putResultado = async (resultadoId, data, quizId = '') => {
  try {
    await apiQuizClass.put(
      `/resultados/${resultadoId}?quiz_id=${quizId}`,
      data
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const putResultadoNome = (resultadoId, nome) => {
  return async (dispatch) => {
    try {
      await apiQuizClass.put(`/resultados/${resultadoId}/nome`, { nome });
      dispatch(setQuizResultadoNome({ resultadoId, nome }));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

export const putTipoResultados = async (quizId, tipo_resultado) => {
  try {
    await apiQuizClass.put(`/resultados/${quizId}/tipo-resultado`, {
      tipo_resultado,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteResultado = async (resultadoId, quizId) => {
  try {
    const response = await apiQuizClass.delete(
      `/resultados/${resultadoId}?quiz_id=${quizId}`
    );
    const { resultados } = response.data;
    return resultados;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
