import React from 'react'

export default function Telefone({campo, value, setValue, quandoObterDados}) {
    const mask = (value) => {
        return value
                .replace(/\D/g, '')
                .replace(/(\d{2})(\d)/, '($1) $2')
                .replace(/(\d{4})(\d)/, '$1-$2')
                .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
                .replace(/(-\d{4})\d+?$/, '$1')
    }
    return (
        <div>
            <label className="label-nome-campos">{campo.label}: </label>
            <input 
                type="text"  
                name={campo.nome}
                className="form-control form-default mb-2"
                placeholder={quandoObterDados === 'FIM' ? campo.label : "(99) 99999-9999"}
                value={value}
                onChange={e => setValue(mask(e.target.value))}
                required
            />
        </div>
    )
}
