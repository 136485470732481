import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  width: 100%;
  height: 50px;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  
  overflow: hidden; /* Hide the element content, while height = 0 */
  overflow-x: auto;
  height: ${props => (props.show ? 'auto': '0')};
  opacity: ${props => (props.show ? '1': '0')};
  transition: ${props => (props.show ? 'height 0ms 0ms, opacity 600ms 0ms': 'height 0ms 400ms, opacity 400ms 0ms')};;
  padding: ${props => (props.show ? '15px 30px': '0')};
  
  button,
  .toolbar-item {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
  }
`;
