import { setDominio } from '../store/dominioSlice';
import apiQuizClass from './apiQuizClass';

export const getDominio = () => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(`/dominio`);

      dispatch(setDominio(response.data));

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};
