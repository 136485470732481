import styled from 'styled-components';

export const Container = styled.div`
  font-family: '${({ fonte }) => fonte}', sans-serif;

  ul,
  ol {
    padding-left: 0px;
  }
  ul li {
    list-style: initial;
    list-style-position: inside;
  }
  ol li {
    list-style: decimal;
    list-style-position: inside;
  }

  p {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }

  b,
  span,
  i,
  u {
    font-size: inherit;
  }

  font {
    font-size: inherit !important;
  }
  font[size='1'] {
    font-size: 10px !important;
  }
  font[size='2'] {
    font-size: 13px !important;
  }
  font[size='3'] {
    font-size: 16px !important;
  }
  font[size='4'] {
    font-size: 18px !important;
  }
  font[size='5'] {
    font-size: 24px !important;
  }
  font[size='6'] {
    font-size: 32px !important;
  }
  font[size='7'] {
    font-size: 48px !important;
  }

  .codex-editor__redactor {
    margin: 0 !important;
    padding-bottom: 0 !important;
  }
  .ce-rawtool__textarea {
    background-color: ${(props) => props.backgroundSecundario} !important;
    color: ${(props) => props.textoSecundario} !important;
  }

  .ce-toolbar__actions {
    /* right: -60px; */
    * {
      color: ${(props) => props.textoPadrao};
    }
    .ce-toolbar__plus,
    .ce-toolbar__settings-btn {
      &:hover {
        background-color: ${(props) => props.backgroundSecundario};
      }
      * {
        color: ${(props) => props.textoSecundario};
        background-color: ${(props) => props.backgroundSecundario} !important;
        border-radius: 3px;
      }
    }
  }

  .ce-toolbox {
    .ce-popover {
      border: 2px solid ${(props) => props.textoPadrao};
    }
    * {
      background: ${(props) => props.backgroundPadrao};
    }
  }

  .ce-settings {
    border: 2px solid ${(props) => props.textoPadrao};
    background: ${(props) => props.backgroundPadrao};
  }

  .ce-inline-toolbar {
    * {
      color: ${(props) => props.textoPadrao};
      background: ${(props) => props.backgroundPadrao};
    }
    border: 2px solid ${(props) => props.textoPadrao};
  }
  .image-tool__caption {
    display: none;
  }

  .ce-block__content {
    margin-left: 0 !important;
  }

  .image-tool__image-picture {
    display: inline !important;
  }

  .image-tool__tune {
    display: none !important;
  }

  /* .tribute-mention {
    background: rgba(153, 0, 48, 0.1);
    color: #990030;
  } */
`;
