import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'resultado',
  initialState: {
    id: '',
    valor_inicial: null,
    valor_final: null,
    resultado: '',
    questao_inicial_id: '',
    questao_final_id: '',
    valor_grupo_questao: null,
    tipo_validacao_grupo_questao: '',
    blocks: {},
  },
  reducers: {
    setResultado(state, { payload }) {
      return { ...state, ...payload };
    },
    setResultadoBlocks(state, { payload: newBlocks }) {
      const blocks = JSON.parse(JSON.stringify(newBlocks));

      return { ...state, blocks };
    },
  },
});

export const { setResultado, setResultadoBlocks } = slice.actions;

export default slice.reducer;
