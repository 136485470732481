import React from 'react';
import { AiOutlineBgColors } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  selectIdQuiz,
  selectNaturezaQuiz,
  selectResultadosQuiz,
} from '../../../store/quizSlice';
import {
  toggleShowModalConfirmDeletion,
  toggleShowModalConfiguracaoResultado,
  toggleShowModalEditNomeResultado,
} from '../../../store/showSlice';
import { FaCog, FaRegSave } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { putResultado } from '../../../services/resultadoService';

export function ToolbarConfiguracoesResultados({ fonte, handleFonte }) {
  const dispatch = useDispatch();
  const { resultadoId } = useParams();
  const naturezaQuiz = useSelector(selectNaturezaQuiz);
  const resultado = useSelector((state) => state.resultado);
  const quizId = useSelector(selectIdQuiz);
  const qtdResultados = useSelector(selectResultadosQuiz).length;

  const showConfigurarFaixasResultados = () => {
    if (
      (naturezaQuiz === 'PONTUACAO' || naturezaQuiz === 'CERTO_ERRADO') &&
      resultadoId !== 'default'
    ) {
      return true;
    }
    return false;
  };

  const saveResultado = async () => {
    toast.promise(putResultado(resultado.id, resultado, quizId), {
      pending: 'Salvando...',
      success: 'Salvo com sucesso!',
      error: 'Erro ao Salvar.',
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  return (
    <>
      <span>Configurações do resultado: </span>
      <Link
        to={`/app/quizzes/${quizId}/template/personalizacao`}
        className="btn btn-sm btn-secondary toolbar-item text-nowrap"
      >
        <AiOutlineBgColors color="var(--preto)" size={20} /> Personalizar cores
      </Link>

      {naturezaQuiz === 'FLUXO' && (
        <button
          className="btn btn-sm btn-secondary toolbar-item"
          onClick={() =>
            dispatch(
              toggleShowModalEditNomeResultado({
                show: true,
                resultadoId: resultado.id,
              })
            )
          }
        >
          <FaCog /> Editar resultado
        </button>
      )}

      {showConfigurarFaixasResultados() && (
        <button
          className="btn btn-sm btn-secondary toolbar-item text-nowrap"
          onClick={() => dispatch(toggleShowModalConfiguracaoResultado())}
        >
          Configurar faixas de resultados
        </button>
      )}

      {resultadoId !== 'default' && qtdResultados > 1 && (
        <button
          className="btn btn-sm btn-secondary toolbar-item"
          onClick={() => dispatch(toggleShowModalConfirmDeletion())}
        >
          Deletar
        </button>
      )}

      <button
        className="btn btn-sm btn-secondary toolbar-item"
        onClick={() => saveResultado()}
      >
        <FaRegSave /> Salvar
      </button>

      <div style={{ minWidth: 150 }}>
        <select
          className="form-control form-select"
          value={fonte}
          onChange={handleFonte}
        >
          <option value="" disabled>
            Fontes
          </option>
          <option value="Montserrat">Montserrat</option>
          <option value="Source Sans Pro">Source Sans Pro</option>
          <option value="Roboto Condensed">Roboto Condensed</option>
          <option value="Oswald">Oswald</option>
          <option value="Slabo 27px">Slabo 27px</option>
          <option value="Lato">Lato</option>
          <option value="Open sans">Open sans</option>
          <option value="Roboto">Roboto</option>
        </select>
      </div>
    </>
  );
}
