import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  toggleShowModalConfirmDeletion,
  toggleShowToolbar,
} from '../../store/showSlice';
import { Container, PreviewResultado, Actions } from './styles';
import {
  deleteResultado,
  getResultado,
  putResultado,
} from '../../services/resultadoService';
import Editor from '../../components/Editor';
import { setResultadoBlocks } from '../../store/resultadoSlice';
import ModalConfirmDeletion from '../../components/Modal/ConfirmDeletion';
import ModalConfiguracaoResultado from './components/Modal/ConfiguracaoResultado';
import {
  selectIdQuiz,
  selectNaturezaQuiz,
  selectResultadosQuiz,
  selectSlugQuiz,
  selectSubdomainQuiz,
  setQuizResultados,
} from '../../store/quizSlice';
import LogoQuizClass from '../../components/Editor/LogoQuizClass';
import { BsPlayCircle } from 'react-icons/bs';
import { getUrl } from '../../utils/urlResponderQuiz';
import {
  selectDominioStatusCname,
  selectDominioUrlVisualizarQuiz,
} from '../../store/dominioSlice';
import { getConfiguracaoQuiz } from '../../services/quizService';
import { Toolbar } from '../Toolbar';
import {
  selectEditorFluxoResultados,
  setResultadosEditorFluxo,
  setSelectedEditableContentEditorFluxo,
} from '../../store/editorFluxosSlice';
import FormResultadoLink from './components/FormResultadoLink';

function Resultado({ resultadoId }) {
  const dispatch = useDispatch();
  const personalizacao = useSelector((state) => state.personalizacao);
  const resultado = useSelector((state) => state.resultado);
  const [isLoading, setIsLoading] = useState(true);
  const quizId = useSelector(selectIdQuiz);
  const navigate = useNavigate();
  const resultadosMenu = useSelector(selectResultadosQuiz);
  const quizSlug = useSelector(selectSlugQuiz);
  const subdomain = useSelector(selectSubdomainQuiz).toLowerCase();
  const urlVisualizar = getUrl(subdomain, quizSlug);
  const dominioStatusCname = useSelector(selectDominioStatusCname);
  const dominioUrlVisualizarQuiz =
    useSelector(selectDominioUrlVisualizarQuiz) + '/' + quizSlug;
  const configuracao = useSelector((state) => state.configuracao);
  const natureza = useSelector(selectNaturezaQuiz);
  const resultadosEditorFluxo = useSelector(selectEditorFluxoResultados);

  const campoChecked = (campo) => {
    if (campo == 'Nome' && configuracao.obter_nome) {
      return true;
    }
    if (campo == 'E-mail' && configuracao.obter_email) {
      return true;
    }
    if (campo == 'Telefone' && configuracao.obter_telefone) {
      return true;
    }
    if (campo == 'Empresa' && configuracao.obter_empresa) {
      return true;
    }
    if (campo == 'CNPJ' && configuracao.obter_cnpj) {
      return true;
    }
    if (campo == 'CPF' && configuracao.obter_cpf) {
      return true;
    }
    if (campo == 'Cidade' && configuracao.obter_cidade) {
      return true;
    }
    for (const campoSelecionado of configuracao.campos_personalizados_selecionados) {
      if (campoSelecionado.nome === campo) return true;
    }
    return false;
  };
  const mentions = configuracao.obter_dados_respondente
    ? configuracao?.ordem_campos
        .map((ordemCampo) => {
          if (campoChecked(ordemCampo.campo)) {
            return ordemCampo.campo;
          }
          return '';
        })
        .filter((elemento) => elemento)
    : [];

  const save = (data = null) => {
    putResultado(resultadoId, data || resultado, quizId);
  };

  const handleBlocks = (newBlocks) => {
    dispatch(setResultadoBlocks(newBlocks));

    const data = { ...resultado, blocks: newBlocks };
    save(data);
  };

  const excluirResultado = () => {
    deleteResultado(resultadoId, quizId).then((resultados) => {
      dispatch(toggleShowModalConfirmDeletion());
      if (natureza === 'FLUXO') {
        const newResultadosEditorFluxo = resultadosEditorFluxo.filter(
          (resultadoEditorFluxo) => resultadoEditorFluxo.id !== resultadoId
        );
        dispatch(setResultadosEditorFluxo(newResultadosEditorFluxo));
        dispatch(
          setSelectedEditableContentEditorFluxo({
            id: null,
            type: null,
            show: false,
          })
        );
      } else {
        let newResultadoSelected = 0;

        const resultadoSelected = resultadosMenu.findIndex(
          (resultadoMenu) => resultadoMenu.id === resultadoId
        );

        if (resultadosMenu.length === 1 || resultadosMenu.length === 2) {
          newResultadoSelected = 0;
        } else if (resultadoSelected === 0) {
          newResultadoSelected = 0;
        } else if (resultadoSelected === resultadosMenu.length - 1) {
          newResultadoSelected = resultadoSelected - 1;
        } else {
          newResultadoSelected = resultadoSelected - 1;
        }

        dispatch(setQuizResultados(resultados));

        if (resultados.length > 0) {
          navigate(
            `/app/quizzes/${quizId}/resultados/${resultados[newResultadoSelected].id}`
          );
        } else navigate(`/app/quizzes/${quizId}/apresentacao`);
      }
    });
  };

  useEffect(() => {
    async function requests() {
      try {
        await Promise.all([
          dispatch(getConfiguracaoQuiz(quizId)),
          dispatch(getResultado(resultadoId, quizId)),
        ]);
      } catch (error) {}
      setIsLoading(false);
    }
    if (quizId) requests();

    return () => {
      setIsLoading(true);
    };
  }, [dispatch, quizId, resultadoId]);

  useEffect(() => {
    dispatch(toggleShowToolbar({ show: true, type: 'CONFIGURACAO_RESULTADO' }));
    return () => {
      dispatch(toggleShowToolbar({ show: false, type: '' }));
    };
  }, []);

  return (
    <>
      {natureza === 'FLUXO' && <Toolbar />}

      {natureza === 'FLUXO' && resultado.type === 'LINK' ? (
        <Container className="mt-5">
          <FormResultadoLink resultado={resultado} />
        </Container>
      ) : (
        <Container>
          <PreviewResultado
            className="border-radius pt-5"
            style={{
              background: personalizacao.cor_fundo_padrao,
              color: personalizacao.cor_texto_padrao,
            }}
            backgroundImage={personalizacao.background_image_resultado}
            backgroundSize={personalizacao.background_size_resultado}
            isLoading={isLoading}
            onBlur={() => save()}
          >
            <Actions>{/* <CloseButton /> */}</Actions>

            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-6 offset-lg-3">
                  {Object.keys(resultado.blocks).length && !isLoading ? (
                    <Editor
                      id={resultadoId}
                      blocks={resultado.blocks}
                      handleBlocks={handleBlocks}
                      mentions={mentions}
                      isPageResultado={true}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <LogoQuizClass />
          </PreviewResultado>

          <hr />
          <div className="d-flex justify-content-center">
            <a
              href={`${
                dominioStatusCname ? dominioUrlVisualizarQuiz : urlVisualizar
              }/resultado/${resultadoId}?preview=true&preview_resultado=true`}
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-secondary btn-lg">
                Ver resultado <BsPlayCircle className="ms-3" size={30} />
              </button>
            </a>
          </div>

          <ModalConfiguracaoResultado />
        </Container>
      )}
      <ModalConfirmDeletion
        title="Excluir resultado"
        onClick={excluirResultado}
        textBtnDelete="Excluir resultado"
      />
    </>
  );
}

export default Resultado;
