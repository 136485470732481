import styled from 'styled-components';

export const Container = styled.div``;

export const BtnFacebook = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #597ecf;
  border-radius: 5px;
  color: #ffffff;
  max-width: 300px;
`;

export const BtnWhatsapp = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #55e065;
  border-radius: 5px;
  color: #3e3e3e;
  max-width: 300px;
`;

export const SubDomain = styled.input`
  /* border: none; */
`;
