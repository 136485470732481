import styled from 'styled-components';

export const Container = styled.div`
  & + & {
    margin-top: 15px;
  }
  .ck-editor__main,
  .ck-content {
    min-height: 90px;
    p {
      font-size: 16px !important;
    }
    strong,
    u {
      font-size: inherit;
    }
  }
  .ck-content:focus {
    background: #ffffff !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;
    border: 1px solid var(--amarelo) !important;
    box-sizing: border-box !important;
  }
`;
