import React from 'react';
import Apresentacao from '../../components/Apresentacao';

function Index() {
  return (
    <div>
      <Apresentacao />
    </div>
  );
}

export default Index;
