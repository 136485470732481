import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleShowModalConfirmDeletion,
  toggleShowModalFeedbackQuestao,
  toggleShowModalRegrasLogicaValor,
} from '../../../store/showSlice';
import { FaRegSave } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { putQuestao } from '../../../services/questaoService';
import { selectNaturezaQuiz } from '../../../store/quizSlice';

export function ToolbarConfiguracoesQuestoes() {
  const dispatch = useDispatch();
  const questao = useSelector((state) => state.questao);
  const naturezaQuiz = useSelector(selectNaturezaQuiz);

  const saveQuestao = async () => {
    toast.promise(putQuestao(questao.id, questao), {
      pending: 'Salvando...',
      success: 'Salvo com sucesso!',
      error: 'Erro ao Salvar.',
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  return (
    <>
      <span>Configurações da questão: </span>
      {naturezaQuiz === 'CERTO_ERRADO' && (
        <button
          className="btn btn-sm btn-secondary toolbar-item"
          onClick={() => dispatch(toggleShowModalFeedbackQuestao())}
        >
          Feedback
        </button>
      )}
      <button
        className="btn btn-sm btn-secondary toolbar-item text-nowrap"
        onClick={() => dispatch(toggleShowModalRegrasLogicaValor())}
      >
        Regras, lógica e valor
      </button>
      <button
        className="btn btn-sm btn-secondary toolbar-item"
        onClick={() => dispatch(toggleShowModalConfirmDeletion())}
      >
        Deletar
      </button>
      <button
        className="btn btn-sm btn-secondary toolbar-item"
        onClick={() => saveQuestao()}
      >
        <FaRegSave /> Salvar
      </button>
    </>
  );
}
