import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .main {
    position: absolute;
  }
`;
export const BtnDeleteRow = styled.span`
  border-radius: 50%;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  scale: ${({ scale }) => scale};
  svg {
    path {
      fill: var(--danger);
    }
  }
`;
