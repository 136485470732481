import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEditorFluxoBlocosLogicos,
  selectEditorFluxoQuestoes,
  setBlocosLogicosEditorFluxo,
  setSelectedEditableContentEditorFluxo,
} from '../../../../store/editorFluxosSlice';
import { FaPlus } from 'react-icons/fa';
import FormCondicao from './FormCondicao';
import { Toolbar } from '../../../Toolbar';
import {
  toggleShowModalConfirmDeletion,
  toggleShowToolbar,
} from '../../../../store/showSlice';
import ModalConfirmDeletion from '../../../../components/Modal/ConfirmDeletion';
import {
  deleteBlocoLogico,
  getBlocosAnterioresBlocoLogico,
  putBlocoLogicoCondicoes,
} from '../../../../services/editorFluxo';
import { toast } from 'react-toastify';

function BlocoLogico({ blocoLogicoId }) {
  const dispatch = useDispatch();
  const blocosLogicos = useSelector(selectEditorFluxoBlocosLogicos);
  const blocoLogico = blocosLogicos.find((bloco) => bloco.id === blocoLogicoId);
  const [questoesAnteriores, setQuestoesAnteriores] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [condicoes, setCondicoes] = useState(() => {
    if (blocoLogico.condicoes.length) return blocoLogico.condicoes;

    return [{ questao_id: '', opcao_id: '', conector: '' }];
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await toast.promise(putBlocoLogicoCondicoes(blocoLogicoId, condicoes), {
        pending: 'Salvando...',
        success: {
          render({ data }) {
            return 'Salvo com sucesso!';
          },
        },
        error: {
          render({ data: message }) {
            return 'Erro ao salvar.';
          },
        },
      });
      const newBlocosLogicos = blocosLogicos.map((bloco) =>
        bloco.id === blocoLogicoId ? { ...bloco, condicoes } : bloco
      );

      dispatch(setBlocosLogicosEditorFluxo(newBlocosLogicos));
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  const addCondicao = () => {
    setCondicoes((state) => {
      const newCondicoes = [...state];
      newCondicoes.push({ questao_id: '', opcao_id: '', conector: 'OU' });
      return newCondicoes;
    });
  };

  const removeCondicao = (indexRemove) => {
    setCondicoes((state) => {
      const newCondicoes = [...state].filter(
        (condicao, index) => index !== indexRemove
      );
      return newCondicoes;
    });
  };

  const handleChange = (e, condicaoIndex) => {
    setCondicoes((state) => {
      const newCondicoes = [...state].map((condicao, index) =>
        condicaoIndex === index
          ? {
              ...condicao,
              opcao_id: e.target.name === 'questao_id' ? '' : condicao.opcao_id,
              [e.target.name]: e.target.value,
            }
          : condicao
      );

      return newCondicoes;
    });
  };
  const excluirBlocoLogico = () => {
    deleteBlocoLogico(blocoLogicoId).then(() => {
      dispatch(toggleShowModalConfirmDeletion());

      const newBlocosLogicos = blocosLogicos.filter(
        (bloco) => bloco.id !== blocoLogicoId
      );

      dispatch(setBlocosLogicosEditorFluxo(newBlocosLogicos));
      dispatch(
        setSelectedEditableContentEditorFluxo({
          id: null,
          type: null,
          show: false,
        })
      );
    });
  };

  useEffect(() => {
    async function getBlocosAnteriores() {
      try {
        const data = await getBlocosAnterioresBlocoLogico(blocoLogicoId);
        setQuestoesAnteriores(data);
      } catch (error) {
      } finally {
        setIsloading(false);
      }
    }
    getBlocosAnteriores();

    dispatch(toggleShowToolbar({ show: true, type: 'BLOCO_LOGICO' }));

    return () => {
      dispatch(toggleShowToolbar({ show: false, type: '' }));
    };
  }, []);

  return isLoading ? (
    <div>Carregando...</div>
  ) : (
    <>
      <Toolbar />

      <Container className="mt-4">
        <form onSubmit={handleSubmit}>
          <div>
            {condicoes.map((condicao, index) => (
              <FormCondicao
                key={index}
                index={index}
                questoesAnteriores={questoesAnteriores}
                removeCondicao={removeCondicao}
                handleChange={handleChange}
                condicao={condicao}
              />
            ))}
          </div>
          <button
            className="btn btn-secondary btn-sm shadow-default ps-4 pe-4 mt-3"
            onClick={addCondicao}
            type="button"
          >
            Nova condição <FaPlus />
          </button>
          <br />
          <button
            className="btn btn-default shadow-default ps-4 pe-4 mt-3"
            type="submit"
            disabled={isSubmitting}
          >
            Salvar
          </button>
        </form>
      </Container>
      <ModalConfirmDeletion
        title="Excluir blocol lógico"
        onClick={excluirBlocoLogico}
        textBtnDelete="Excluir blocol lógico"
      />
    </>
  );
}

export default BlocoLogico;
