import React from 'react';
import { FaPlus } from 'react-icons/fa';

import { Container } from './styles';

function Plus({ title = 'Adicionar', onClick = () => {} }) {
  return (
    <Container className="btn btn-secondary" onClick={onClick} title={title}>
      <FaPlus size={13} />
    </Container>
  );
}

export default Plus;
