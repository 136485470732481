import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleShowModalConfirmDeletion } from '../../../store/showSlice';

// import { Container } from './styles';

function BlocoLogico() {
  const dispatch = useDispatch();
  return (
    <>
      <span>Bloco lógico: </span>

      <button
        className="btn btn-sm btn-secondary toolbar-item"
        onClick={() => dispatch(toggleShowModalConfirmDeletion())}
      >
        Deletar
      </button>
    </>
  );
}

export default BlocoLogico;
