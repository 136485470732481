import React from 'react'
import Email from './Email';
import Select from './Select';
import Number from './Number';
import Telefone from './Telefone';
import Text from './Text';
import TextArea from './TextArea';
import Url from './Url';
import RadioButton from './RadioButton';
import MultipleChoice from './MultipleChoice';
import CheckBox from './CheckBox';

export default function CamposPersonalizados({campo, index, camposValue, quandoObterDados}) {

  const setValue = (value) => {
    if(campo.tipo === 'MULTIPLE_CHOICE'){
      let valuesAux = camposValue[index].value;
      if(Array.isArray(valuesAux) && valuesAux.includes(value)){
        let indexValue = valuesAux.indexOf(value);
        if (indexValue > -1) {
          valuesAux.splice(indexValue, 1);
        }
      }else{
        if(!Array.isArray(valuesAux))
          valuesAux = [];
        valuesAux.push(value);
      }

      value = valuesAux;
    }
  };

  const acceptedTypes = {
    EMAIL_INPUT: (
      <Email 
        campo={campo} 
        value={camposValue && camposValue[index] ? camposValue[index].value : ''}
        setValue={setValue}
        quandoObterDados={quandoObterDados}
      />
    ),
    NUMBER_INPUT: (
      <Number 
        campo={campo} 
        value={camposValue && camposValue[index] ? camposValue[index].value : ''}
        setValue={setValue}
        quandoObterDados={quandoObterDados}
      />
    ),
    PHONE_INPUT: (
      <Telefone 
        campo={campo} 
        value={camposValue && camposValue[index] ? camposValue[index].value : ''}
        setValue={setValue}
        quandoObterDados={quandoObterDados}
      />
    ),
    TEXT_INPUT: (
      <Text 
        campo={campo} 
        value={camposValue && camposValue[index] ? camposValue[index].value : ''}
        setValue={setValue}
        quandoObterDados={quandoObterDados}
      />
    ),
    TEXT_AREA: (
      <TextArea 
        campo={campo} 
        value={camposValue && camposValue[index] ? camposValue[index].value : ''}
        setValue={setValue}
        quandoObterDados={quandoObterDados}
      />
    ),
    URL_INPUT: (
      <Url 
        campo={campo} 
        value={camposValue && camposValue[index] ? camposValue[index].value : ''}
        setValue={setValue}
        quandoObterDados={quandoObterDados}
      />
    ),
    RADIO_BUTTON: (
      <RadioButton 
        campo={campo} 
        value={camposValue && camposValue[index] ? camposValue[index].value : ''}
        setValue={setValue}
      />
    ),
    COMBO_BOX: (
      <Select 
        campo={campo} 
        value={camposValue && camposValue[index] ? camposValue[index].value : ''}
        setValue={setValue}
        camposValue={camposValue}
        quandoObterDados={quandoObterDados}
      />
    ),
    MULTIPLE_CHOICE: (
      <MultipleChoice 
        campo={campo} 
        value={camposValue && camposValue[index] ? camposValue[index].value : ''}
        setValue={setValue}
      />
    ),
    CHECK_BOX: (
      <CheckBox 
        campo={campo} 
        value={camposValue && camposValue[index] ? camposValue[index].value : ''}
        setValue={setValue}
      />
    )
  };

  if (acceptedTypes[campo.tipo]) return acceptedTypes[campo.tipo]  

  return '';
}