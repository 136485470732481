import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  postResultado,
  putTipoResultados,
} from '../../../services/resultadoService';
import {
  selectIdQuiz,
  selectTipoResultadoQuiz,
  setQuizTipoResultado,
} from '../../../store/quizSlice';
import {
  selectShowModalTiposResultado,
  toggleShowModalTiposResultado,
} from '../../../store/showSlice';
import { Info, Tipo } from './styles';

function TiposQuestao() {
  const dispatch = useDispatch();
  const show = useSelector(selectShowModalTiposResultado);
  const tipoResultado = useSelector(selectTipoResultadoQuiz);
  const quizId = useSelector(selectIdQuiz);

  const handleTipoResultado = (tipo) => {
    dispatch(setQuizTipoResultado(tipo));

    putTipoResultados(quizId, tipo);
  };

  return (
    <Modal
      show={show}
      onHide={() => dispatch(toggleShowModalTiposResultado())}
      size="lg"
      centered
    >
      <Modal.Header style={{ border: 'none' }} closeButton>
        <Modal.Title>
          Tipos de resultados disponíveis
          <br />
          <Info>Selecione um tipo de resultado</Info>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          <div className="col-6">
            <Tipo
              className="btn btn-secondary mb-3 col-12"
              onClick={() => handleTipoResultado('SOMATORIO_QUESTOES')}
              style={{
                background: tipoResultado === 'SOMATORIO_QUESTOES' && '#565e64',
                color: tipoResultado === 'SOMATORIO_QUESTOES' && 'white',
              }}
            >
              Baseado exclusivamente no somatório de todas as questões
            </Tipo>
          </div>
          <div className="col-6">
            <Tipo
              className="btn btn-secondary col-12"
              onClick={() => handleTipoResultado('SOMATORIO_GRUPO_QUESTOES')}
              style={{
                background:
                  tipoResultado === 'SOMATORIO_GRUPO_QUESTOES' && '#565e64',
                color: tipoResultado === 'SOMATORIO_GRUPO_QUESTOES' && 'white',
              }}
            >
              Baseado no somatório de grupo de questões
            </Tipo>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default TiposQuestao;
