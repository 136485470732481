import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShowModalRestricaoPlano,
  setShowModalRestricaoPlano,
} from '../../../store/showSlice';

function RestricaoPlano() {
  const dispatch = useDispatch();
  const { show, data } = useSelector(selectShowModalRestricaoPlano);

  return (
    <Modal
      show={show}
      onHide={() =>
        dispatch(setShowModalRestricaoPlano({ show: false, data: {} }))
      }
      dialogClassName="modal-90w"
      // backdrop="static"
      // keyboard={false}
    >
      <Modal.Header className="border-0" closeButton />
      <Modal.Body>
        <div className="text-center">
          <img
            src="https://assetsus1.quizclass.com.br/assets/images/restricao-icon.svg"
            alt="Icone restrição plano"
          />
        </div>

        <br />

        <h2
          className="text-center text-danger"
          dangerouslySetInnerHTML={{ __html: data.message }}
        />

        <br />
        <br />

        <h2
          className="text-center"
          dangerouslySetInnerHTML={{ __html: data.message_acesso_recurso }}
        />

        <br />
        <br />

        <div className="d-flex justify-content-center">
          <a href={`${process.env.REACT_APP_BASE_URL_BACKEND}/planos`} target='_blank'>
            <button type='button' className="btn btn-default d-flex align-items-center pe-4 ps-4">
              <span className="fw-bold">Contratar agora</span>
            </button>
          </a>
        </div>

        <br />
        <br />

        <p className="text-center" style={{fontSize: '1rem'}}>
          Se ficar com qualquer dúvida, fico à disposição para te ajudar
          <br /> pelo nosso chat ou ticket de atendimento.
        </p>

        <br />
        <br />
      </Modal.Body>
    </Modal>
  );
}

export default RestricaoPlano;
