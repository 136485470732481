import styled from 'styled-components';

export const Content = styled.div`
  .btnDuplicarQuestao {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s;
    cursor: pointer;
    z-index: 100;
  }

  &:hover {
    .btnDuplicarQuestao {
      opacity: 1;
    }
  }
`;
