import React, {useEffect, useRef, useState} from 'react';
import {BtnFacebook, BtnWhatsapp, Container, SubDomain} from './styles';
import {FaArrowCircleRight, FaCopy, FaFacebook, FaWhatsapp,} from 'react-icons/fa';
import {Form} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {selectIdQuiz, selectSlugQuiz, selectSubdomainQuiz, setQuizSubdomain,} from '../../store/quizSlice';
import {toast} from 'react-toastify';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {getUrl} from '../../utils/urlResponderQuiz';
import {putQuizSubdominio} from '../../services/quizService';
import 'react-image-crop/dist/ReactCrop.css';
import ModalCropImage from '../../components/Modal/CropImage';
import {toggleShowModalCropImage} from '../../store/showSlice';
import {getMetaConfig, putMetaConfig} from '../../services/metaConfigService';
import {setMetaConfigDescricao, setMetaConfigTitulo,} from '../../store/metaConfigSlice';
import {selectDominioStatusCname, selectDominioUrlVisualizarQuiz,} from '../../store/dominioSlice';
import Incorporar from './components/Incorporar';
import {QRCodeCanvas} from "qrcode.react";

function Compartilhar() {
  const qrCodeRef = useRef(null);
  const dispatch = useDispatch();
  const quizSlug = useSelector(selectSlugQuiz);
  const subdomain = useSelector(selectSubdomainQuiz).toLowerCase();
  const metaConfig = useSelector((state) => state.metaConfig);
  const urlVisualizar = getUrl(subdomain, quizSlug);
  const quizId = useSelector(selectIdQuiz);
  const [isLoading, setIsLoading] = useState(true);
  const dominioStatusCname = useSelector(selectDominioStatusCname);
  const dominioUrlVisualizarQuiz =
    useSelector(selectDominioUrlVisualizarQuiz) + '/' + quizSlug;
  const showNotification = (text) => {
    toast.dark(text, {
      autoClose: 2000,
      hideProgressBar: true,
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const saveSubdomain = () => {
    putQuizSubdominio(quizId, subdomain);
  };

  useEffect(() => {
    if (quizId) {
      dispatch(getMetaConfig(quizId)).then(() => setIsLoading(false));
    }
  }, [dispatch, quizId]);

  const copyQRCode = () => {
    const canvas = qrCodeRef.current.querySelector('canvas');
    if (canvas) {
      canvas.toBlob((blob) => {
        const item = new ClipboardItem({ 'image/png': blob });
        navigator.clipboard.write([item]).then(() => {
          showNotification('QR Code copiado!');
        }).catch((error) => {
          console.error('Erro ao copiar a imagem: ', error);
        });
      });
    }
  };

  return isLoading ? (
    <div>Carregando...</div>
  ) : (
    <div className="row justify-content-center pb-5">
      <Container className="col-12 col-lg-6 d-flex flex-column justify-content-center">
        <div className="row justify-content-center mb-5">
          <div className="col-12 col-lg-6">
            <div
              className="d-flex flex-column justify-content-between"
              style={{ height: '100%' }}
            >
              <h2>Compartilhe seu Quiz</h2>

              <div>
                <BtnFacebook
                  className="btn col-12 btn-md mb-3"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${urlVisualizar}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="d-flex align-items-center">
                    <FaFacebook size={20} className="me-2" />
                    Facebook
                  </span>
                  <FaArrowCircleRight size={20} />
                </BtnFacebook>

                <BtnWhatsapp
                  className="btn col-12 btn-md"
                  href={`https://api.whatsapp.com/send?text=${urlVisualizar}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="d-flex align-items-center">
                    <FaWhatsapp size={20} className="me-2" />
                    Whatsapp
                  </span>
                  <FaArrowCircleRight size={20} />
                </BtnWhatsapp>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-5 offset-1">
            <img
              src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/compartilhar/imagem-compartilhar-pessoa.svg"
              alt="Ilustração de compartilhamento"
            />
          </div>
        </div>

        <Form.Group className="mb-3">
          <Form.Label className="d-flex align-items-center gap-1">
            <span>Link do quiz</span>
          </Form.Label>

          <div
            className="d-flex flex-row align-items-center form-control form-default pt-0 pb-0 pe-5"
            style={{ position: 'relative', backgroundColor: '#E9ECEF' }}
          >
            {dominioStatusCname ? (
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                title={String(dominioUrlVisualizarQuiz)}
              >
                {String(dominioUrlVisualizarQuiz)}
              </span>
            ) : (
              <>
                <label htmlFor="input-subdomain" className="fw-normal pe-1 m-0">
                  https://
                </label>

                <SubDomain
                  id="input-subdomain"
                  className="shadow-none form-control"
                  type="text"
                  onBlur={saveSubdomain}
                  onChange={(e) =>
                    dispatch(
                      setQuizSubdomain(
                        e.target.value.toLowerCase().replace(/\s/g, '')
                      )
                    )
                  }
                  value={subdomain}
                  style={{ height: '100%', width: 100 }}
                />

                <label
                  htmlFor="input-subdomain"
                  className="fw-normal m-0"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  .quizclass.net/{quizSlug}
                </label>
              </>
            )}

            <CopyToClipboard
              text={
                dominioStatusCname ? dominioUrlVisualizarQuiz : urlVisualizar
              }
              onCopy={() => showNotification('Link copiado!')}
            >
              <div
                className="p-2 border-radius btn"
                title="Copiar"
                style={{
                  background: '#D9D9D9',
                  position: 'absolute',
                  top: 5,
                  right: 5,
                }}
              >
                <FaCopy size={24} color={'#FFFFFF'} />
              </div>
            </CopyToClipboard>
          </div>
        </Form.Group>
        <a
            style={{
              fontSize: "0.8rem",
              color: '#0066cc'
            }}
            href={`${process.env.REACT_APP_BASE_URL_BACKEND}/app/minha-conta/dominio`}
        >
          Configurar meu domínio
        </a>

        <Form.Group className="mb-3 mt-4 pt-1">
          <Form.Label className="d-flex align-items-center gap-1">
            <span>QR code do seu quiz</span>
          </Form.Label>
          <div  style={{ cursor: 'pointer' }} ref={qrCodeRef} onClick={copyQRCode}>
            <QRCodeCanvas
                value={dominioStatusCname ? dominioUrlVisualizarQuiz : urlVisualizar}
            />
          </div>
        </Form.Group>

        <Incorporar urlVisualizar={urlVisualizar} />

        <h2 className="mt-4">Configurar descrição (meta description)</h2>
        <p>
          Nesta seção você poderá informar um título, descrição e imagem do seu
          Quiz para que aparecem quando compartilhar nas redes sociais ou enviar
          o link para alguém.
        </p>

        <Form.Group className="mb-3">
          <Form.Label>Título da página</Form.Label>
          <Form.Control
            className="form-control form-default"
            type="text"
            maxLength={59}
            value={metaConfig.titulo}
            onChange={(e) => dispatch(setMetaConfigTitulo(e.target.value))}
            onBlur={() => putMetaConfig(quizId, metaConfig)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Meta descrição</Form.Label>
          <Form.Control
            className="form-control form-default"
            type="text"
            maxLength={120}
            value={metaConfig.descricao}
            onChange={(e) => dispatch(setMetaConfigDescricao(e.target.value))}
            onBlur={() => putMetaConfig(quizId, metaConfig)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Imagem para Redes Sociais (meta)</Form.Label>
          <br />
          <button
            className="btn btn-secondary btn-md col-12 position-relative"
            onClick={() => dispatch(toggleShowModalCropImage())}
          >
            {metaConfig.image ? 'Alterar' : 'Upload'}
            {metaConfig.image && (
              <img
                src={metaConfig.image}
                alt="Meta image"
                style={{
                  position: 'absolute',
                  right: 5,
                  top: 4,
                  maxWidth: '80%',
                  maxHeight: '80%',
                }}
              />
            )}
          </button>
        </Form.Group>
      </Container>

      <ModalCropImage />
    </div>
  );
}

export default Compartilhar;
