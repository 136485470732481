import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.8rem;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const LabelUpload = styled.label`
  font-weight: normal;
  margin: 0;
`;
