import styled from 'styled-components';

export const Container = styled.div`
  /* height: 100%; */
  /* overflow: auto; */
  /* ::-webkit-scrollbar {
    width: 7px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */

  .btn {
    box-shadow: none !important;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 52px;
  padding: 0 0 0 15px;
  width: 100%;
  input,
  textarea {
    border: 1px solid #dadada;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
  }
  input[type='number'].valor-opcao::-webkit-inner-spin-button,
  input[type='number'].valor-opcao::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
  .true-input,
  .false-input {
    height: 18px;
    width: 18px;
    font-weight: bold;
    border: 1px solid #dadada;
    box-shadow: none;
    outline: none;
  }
  .true-input {
    color: green;
  }
  .false-input {
    color: red;
  }
  .true-input:focus,
  .false-input:focus {
    border: 1px solid var(--primary);
  }
`;
