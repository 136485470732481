import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  setCorFundoPadrao,
  setCorFundoPrimaria,
  setCorFundoSecundaria,
  setCortextoPadrao,
  setCortextoPrimaria,
  setCortextoSecundaria,
} from '../../../../store/personalizacaoSlice';
import { Container, EllipseColor } from './styles';
import { FaChevronDown } from 'react-icons/fa';
function ColorWrapper({ cor = 'base', corFundo = '#fff', corTexto = '#fff' }) {
  const dispatch = useDispatch();

  const handleColor = (newColor, fundoOuTexto) => {
    switch (cor) {
      case 'base':
        if (fundoOuTexto === 'fundo') {
          dispatch(setCorFundoPadrao(newColor));
        } else if (fundoOuTexto === 'texto') {
          dispatch(setCortextoPadrao(newColor));
        }
        break;
      case 'principal':
        if (fundoOuTexto === 'fundo') {
          dispatch(setCorFundoPrimaria(newColor));
        } else if (fundoOuTexto === 'texto') {
          dispatch(setCortextoPrimaria(newColor));
        }
        break;
      case 'secundaria':
        if (fundoOuTexto === 'fundo') {
          dispatch(setCorFundoSecundaria(newColor));
        } else if (fundoOuTexto === 'texto') {
          dispatch(setCortextoSecundaria(newColor));
        }
        break;
    }
  };

  useEffect(() => {
    const inputCorFundo = document.getElementById(`input-cor-fundo-${cor}`);
    let timer = null;

    const colorChange = (e) => {
      const newColor = e.target.value;
      clearTimeout(timer);
      timer = setTimeout(() => {
        handleColor(newColor, 'fundo');
      }, 50);
    };

    inputCorFundo.addEventListener('input', colorChange);

    return () => {
      inputCorFundo.removeEventListener('input', colorChange);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const inputCorTexto = document.getElementById(`input-cor-texto-${cor}`);
    let timer = null;

    const colorChange = (e) => {
      const newColor = e.target.value;
      clearTimeout(timer);
      timer = setTimeout(() => {
        handleColor(newColor, 'texto');
      }, 50);
    };

    inputCorTexto.addEventListener('input', colorChange);

    return () => {
      inputCorTexto.removeEventListener('input', colorChange);
      clearTimeout(timer);
    };
  }, []);

  return (
    <Container className="shadow-default">
      <div className="d-flex align-items-center gap-2">
        <div
          className="border-gray border-radius-4 shadow-default p-1 d-flex align-items-center gap-1"
          onClick={() =>
            document.getElementById(`input-cor-fundo-${cor}`).click()
          }
          style={{ cursor: 'pointer' }}
        >
          <EllipseColor
            className="border-gray border-radius-4 shadow-default"
            style={{
              background: corFundo,
            }}
          />
          <FaChevronDown />
        </div>

        <input
          id={`input-cor-fundo-${cor}`}
          type={'color'}
          value={corFundo}
          onChange={() => {}}
        />
        <p className="m-0">Fundo</p>
      </div>

      <div className="d-flex align-items-center gap-2">
        <div
          className="border-gray border-radius-4 shadow-default p-1 d-flex align-items-center gap-1"
          onClick={() =>
            document.getElementById(`input-cor-texto-${cor}`).click()
          }
          style={{ cursor: 'pointer' }}
        >
          <EllipseColor
            className="border-gray border-radius-4 shadow-default"
            style={{
              background: corTexto,
            }}
          />
          <FaChevronDown />
        </div>

        <input
          id={`input-cor-texto-${cor}`}
          type={'color'}
          value={corTexto}
          onChange={(e) => {}}
        />
        <p className="m-0">Texto</p>
      </div>
    </Container>
  );
}

export default ColorWrapper;
