import { setMetaConfig } from '../store/metaConfigSlice';
import apiQuizClass from './apiQuizClass';

export const getMetaConfig = (quizId) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(`/quizzes/${quizId}/meta-config`);
      dispatch(setMetaConfig(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const putMetaConfig = async (quizId, data) => {
  try {
    await apiQuizClass.put(`/quizzes/${quizId}/meta-config`, data);
  } catch (error) {
    console.error(error);
  }
};
