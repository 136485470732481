import React, { useRef } from 'react';
import ConnectPointsWrapper from '../ConnectPointsWrapper';
import { useSelector } from 'react-redux';
import { selectIdQuiz } from '../../../../store/quizSlice';

// import { Container } from './styles';

function Apresentacao({ boxId, dropBoxRef, dragBoxRef }) {
  const itemRef = useRef();
  const idQuiz = useSelector(selectIdQuiz);

  return (
    <div id={idQuiz} className="position-relative">
      <div className="content">
        <h1 className="title">Apresentação</h1>
      </div>

      <ConnectPointsWrapper
        itemId={idQuiz}
        {...{ boxId, dropBoxRef, dragBoxRef, itemRef }}
      />
    </div>
  );
}

export default Apresentacao;
