import React, { useState, useEffect } from 'react'
import consultaExterna from '../consultaExterna';

export default function Select({campo, value, setValue, camposValue, quandoObterDados}) {
    const [response, setResponse] = useState([]);

    const getValueCampoDependente = ()=> {
        if(campo.depende_outro_campo){
          for (const campoDependente of camposValue) {
            if(campoDependente.key === campo.campo_dependente.id)
              return campoDependente.value;
          }
        }
        return null;
    };

    const campoDependente = getValueCampoDependente();

    
    useEffect(()=>{
        setValue('');
        setResponse([]);
        
        if(campo.fonte_externa && campoDependente){
            consultaExterna(campo, setResponse, campoDependente);
        }  
    },[campoDependente]);

    useEffect(()=>{
        if(campo.fonte_externa && !campo.depende_outro_campo){
            consultaExterna(campo, setResponse, '');
        }    
    },[]);

    return (
        <div>
            <label className="label-nome-campos">{campo.label}: </label>
            <select
                className="form-control form-default form-select mb-2"
                onChange={e => setValue(e.target.value)}
                name={campo.nome}
                value={value ? value : ''}
                required
            >
                    <option value="" disabled>
                        {quandoObterDados === 'FIM' ? campo.label : 'Selecione uma opção' }
                    </option>
                    {campo.fonte_externa ?
                        response.map((opcao, index) => (
                            <option key={index} value={opcao.key}>
                                {opcao.value}
                            </option>
                        ))
                    :
                        campo.opcoes.map((opcao, index) => (
                            <option key={index} value={opcao.opcoes}>
                                {opcao.opcoes}
                            </option>
                        ))
                    }
                    
            </select>
        </div> 
    )
}
