import React from 'react';
import { FaPen } from 'react-icons/fa';

import { Container } from './styles';

function Edit({ title = 'Editar', onClick = () => {} }) {
  return (
    <Container className="btn btn-secondary" onClick={onClick} title={title}>
      <FaPen size={13} />
    </Container>
  );
}

export default Edit;
