import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Container } from './styles';
import { MdDragIndicator } from 'react-icons/md';

function Item({ children, moveItem, index, item, left = 0 }) {
  const drag = useRef();
  const drop = useRef();

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: 'ordemItem',
    item: {
      index,
      ...item,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: 'ordemItem',
      drop: (itemDragged) => {
        const draggedIndex = itemDragged.index;
        const targetIndex = index;

        if (draggedIndex !== targetIndex) {
          moveItem(draggedIndex, targetIndex);
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [moveItem]
  );

  dragRef(drag);
  dropRef(drop);
  return (
    <Container
      ref={drop}
      className="border-radius"
      style={{
        border: isOver ? '1px var(--preto) solid' : 'none',
        opacity: isDragging ? 0.5 : 1,
      }}
      left={left}
    >
      <div
        className="drag-and-drop-item border-radius"
        title="Mover item"
        ref={drag}
      >
        <MdDragIndicator />
      </div>
      {children}
    </Container>
  );
}

export default Item;
