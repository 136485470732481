import apiQuizClass from './apiQuizClass';

export const postCampoPersonalizado = async (data) => {
  try {
    const response = await apiQuizClass.post(`/campos-personalizados`, data);    
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
