import styled from 'styled-components';

export const Container = styled.div``;

export const PreviewResultado = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  min-height: 67vh;
  margin-top: 25px;
  position: relative;
  padding-bottom: 60px;

  background-image: ${(props) => `url(${props.backgroundImage}) !important`};
  background-size: ${(props) => `${props.backgroundSize} !important`};
  background-repeat: no-repeat !important;
  background-position: center !important;

  & * {
    display: ${(props) => props.isLoading && 'none'};
  }
`;

export const Actions = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
`;
