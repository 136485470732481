import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'editorFluxos',
  initialState: {
    apresentacao: {},
    questoes: [],
    resultados: [],
    blocos_logicos: [],
    selectedEditableContent: {
      id: null,
      type: null,
      show: false,
    },
  },
  reducers: {
    setBlocosEditorFluxo(state, { payload }) {
      return {
        ...payload,
        selectedEditableContent: state.selectedEditableContent,
      };
    },
    addBlocosResultadoEditorFluxo(state, { payload: resultado }) {
      const newResultados = [...state.resultados, resultado];
      return { ...state, resultados: newResultados };
    },
    addBlocosQuestaoEditorFluxo(state, { payload: questao }) {
      const newQuestoes = [...state.questoes, { ...questao, newBlock: true }];
      return { ...state, questoes: newQuestoes };
    },
    setQuestoesEditorFluxo(state, { payload: questoes }) {
      return { ...state, questoes };
    },
    setQuestaoEditorFluxos(state, { payload: questao }) {
      let newQuestoes = state.questoes.map((stateQuestao) =>
        stateQuestao.id === questao.id ? questao : stateQuestao
      );
      return { ...state, questoes: newQuestoes };
    },
    attContentQuestaoEditorFluxos(
      state,
      { payload: { id, pergunta, opcoes, newBlock = false } }
    ) {
      let newQuestoes = state.questoes.map((stateQuestao) => {
        if (stateQuestao.id === id) {
          let newOpcoes = [];
          opcoes.forEach((opcao) => {
            const itemOpcao = stateQuestao.opcoes.find(
              (item) => opcao.id === item.id
            );

            newOpcoes.push({
              id: opcao.id,
              texto: opcao.texto,
              questao_proximo_bloco: itemOpcao?.questao_proximo_bloco,
              resultado_proximo_bloco: itemOpcao?.resultado_proximo_bloco,
              bloco_logico_proximo_bloco: itemOpcao?.bloco_logico_proximo_bloco,
            });
          });

          return { ...stateQuestao, pergunta, opcoes: newOpcoes, newBlock };
        }
        return stateQuestao;
      });

      return { ...state, questoes: newQuestoes };
    },
    setProximoBlocoOpcao(
      state,
      { payload: { questaoId, opcaoId, proximo_bloco, proximo_bloco_type } }
    ) {
      let newQuestoes = state.questoes.map((stateQuestao) => {
        if (stateQuestao.id === questaoId) {
          let newOpcoes = stateQuestao.opcoes.map((opcao) =>
            opcao.id === opcaoId
              ? {
                  ...opcao,
                  questao_proximo_bloco:
                    proximo_bloco_type === 'QUESTAO' ? proximo_bloco : null,
                  resultado_proximo_bloco:
                    proximo_bloco_type === 'RESULTADO' ? proximo_bloco : null,
                  bloco_logico_proximo_bloco:
                    proximo_bloco_type === 'BLOCO_LOGICO'
                      ? proximo_bloco
                      : null,
                }
              : opcao
          );

          return { ...stateQuestao, opcoes: newOpcoes };
        }
        return stateQuestao;
      });

      return { ...state, questoes: newQuestoes };
    },
    setResultadosEditorFluxo(state, { payload: resultados }) {
      return { ...state, resultados };
    },
    setBlocosLogicosEditorFluxo(state, { payload: blocos_logicos }) {
      return { ...state, blocos_logicos };
    },
    setResultadoNewBlock(state, { payload: { id, newBlock } }) {
      let newResultados = state.resultados.map((resultado) =>
        resultado.id === id ? { ...resultado, newBlock } : resultado
      );
      return { ...state, resultados: newResultados };
    },
    setSelectedEditableContentEditorFluxo(
      state,
      { payload: { id, type, show } }
    ) {
      return { ...state, selectedEditableContent: { id, type, show } };
    },
    addBlocosLogicoEditorFluxo(state, { payload: blocoLogico }) {
      const newBlocosLogicos = [...state.blocos_logicos, blocoLogico];
      return { ...state, blocos_logicos: newBlocosLogicos };
    },
    handleBlocosLogicoEditorFluxo(state, { payload: blocoLogico }) {
      const newBlocosLogicos = [...state.blocos_logicos].map(
        (stateBlocoLogico) =>
          stateBlocoLogico.id === blocoLogico.id
            ? blocoLogico
            : stateBlocoLogico
      );
      return { ...state, blocos_logicos: newBlocosLogicos };
    },
    setProximoBlocoBlocoLogico(
      state,
      {
        payload: {
          bloco_logico_id,
          opcao_type,
          proximo_bloco_id,
          proximo_bloco_type,
        },
      }
    ) {
      let newBlocosLogicos = state.blocos_logicos.map((blocoLogico) => {
        if (blocoLogico.id === bloco_logico_id) {
          let newBlocoLogico = blocoLogico;

          if (opcao_type === 'SIM') {
            newBlocoLogico = {
              ...blocoLogico,
              opcao_sim_questao_proximo_bloco:
                proximo_bloco_type === 'QUESTAO' ? proximo_bloco_id : null,
              opcao_sim_resultado_proximo_bloco:
                proximo_bloco_type === 'RESULTADO' ? proximo_bloco_id : null,
            };
          } else if (opcao_type === 'NAO') {
            newBlocoLogico = {
              ...blocoLogico,
              opcao_nao_questao_proximo_bloco:
                proximo_bloco_type === 'QUESTAO' ? proximo_bloco_id : null,
              opcao_nao_resultado_proximo_bloco:
                proximo_bloco_type === 'RESULTADO' ? proximo_bloco_id : null,
              opcao_nao_bloco_logico_proximo_bloco_fluxo:
                proximo_bloco_type === 'BLOCO_LOGICO' ? proximo_bloco_id : null,
            };
          }

          return newBlocoLogico;
        }
        return blocoLogico;
      });

      return { ...state, blocos_logicos: newBlocosLogicos };
    },
    setProximoBlocoApresentacao(state, { payload: { proximo_bloco } }) {
      let newApresentacao = {
        ...state.apresentacao,
        proximo_bloco,
      };

      return { ...state, apresentacao: newApresentacao };
    },
    attResultadoEditorFluxos(state, { payload: { id, newState } }) {
      let newResultados = state.resultados.map((resultado) =>
        resultado.id === id ? { ...resultado, ...newState } : resultado
      );
      return { ...state, resultados: newResultados };
    },
  },
});

export const {
  setBlocosEditorFluxo,
  setQuestoesEditorFluxo,
  setResultadosEditorFluxo,
  setQuestaoEditorFluxos,
  attContentQuestaoEditorFluxos,
  setProximoBlocoOpcao,
  addBlocosResultadoEditorFluxo,
  addBlocosQuestaoEditorFluxo,
  setResultadoNewBlock,
  setSelectedEditableContentEditorFluxo,
  addBlocosLogicoEditorFluxo,
  handleBlocosLogicoEditorFluxo,
  setProximoBlocoBlocoLogico,
  setProximoBlocoApresentacao,
  attResultadoEditorFluxos,
  setBlocosLogicosEditorFluxo,
} = slice.actions;

export const selectEditorFluxoResultados = (state) =>
  state.editorFluxos.resultados;
export const selectEditorFluxoQuestoes = (state) => state.editorFluxos.questoes;
export const selectEditorFluxoBlocosLogicos = (state) =>
  state.editorFluxos.blocos_logicos;
export const selectEditorFluxoSelectedEditableContent = (state) =>
  state.editorFluxos.selectedEditableContent;

export default slice.reducer;
