import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'configuracao',
  initialState: {
    campos_personalizados: [],
    campos_personalizados_selecionados: [],
    obter_dados_respondente: true,
    obter_cidade: true,
    obter_cnpj: false,
    obter_cpf: false,
    obter_email: true,
    obter_empresa: false,
    obter_nome: true,
    obter_telefone: true,
    ordem_campos: [],
    quando_obter_dados: 'INICIO',
    mostrar_nota: '',
    definir_tempo_resposta: true,
    tempo: '',
    redirecionar_usuario: true,
    url_callback: '',
    mostrar_correcao: '',
    pode_responder_apenas_uma_vez: false,
    passar_para_proxima_questao_automaticamente: false,
    url_politica_de_privacidade: '',
    pode_voltar_questao: false,
    mostra_aleatoria: false,
  },
  reducers: {
    setConfiguracao(state, { payload }) {
      return payload;
    },
    addCampoPersonalizado(state, { payload: campo }) {
      let campos_personalizados = [...state.campos_personalizados];
      let ordem_campos = [...state.ordem_campos];

      campos_personalizados.push(campo);
      ordem_campos.push({ campo: campo.nome });

      return {
        ...state,
        campos_personalizados,
        ordem_campos,
      };
    },
  },
});

export const { setConfiguracao, addCampoPersonalizado } = slice.actions;

export const selectCamposPersonalizadosSelecionados = (state) =>
  state.configuracao.campos_personalizados_selecionados;

export default slice.reducer;
