import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 500px;
  padding: 15px;
  border-radius: 10px;
  background-image: ${(props) => `url(${props.backgroundImage}) !important`};
  background-size: ${(props) => `${props.backgroundSize} !important`};
  background-repeat: no-repeat !important;
  background-position: center !important;
`;

export const Gabarito = styled.div`
  border-radius: 20px;
  min-height: 200px;

  .item {
    font-weight: 700;
    span {
      font-weight: 400;
    }
  }
`;
