import styled from 'styled-components';
import { transparentize, cssVar } from 'polished';

export const Info = styled.p`
  font-style: normal;
  color: #9c9c9c;
  margin: 0;
`;

export const Tipo = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: 0.2s;
  background-color: ${({ isActive }) =>
    isActive ? transparentize(0, cssVar('--cinza')) : 'trasnparent'};
  &:hover {
    background-color: ${() => transparentize(0.5, cssVar('--cinza'))};
  }
`;
