import React, { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { selectResultadosQuiz } from '../../../../../../store/quizSlice';
import {
  Container,
  Respostas,
  Resultados,
  OpcaoResposta,
  OpcaoResultado,
} from './styles';
import Xarrow from 'react-xarrows';

function ResultadosCorrelacionados({ opcoes, setFieldValue }) {
  const resultados = useSelector(selectResultadosQuiz);
  const [opcaoSelected, setOpcaoSelected] = useState(null);

  const handleResultado = (resultadoId) => {
    if (!opcaoSelected) return;

    const indexOpcaoSelected = opcoes
      .map((opcao) => opcao.id)
      .indexOf(opcaoSelected);

    let opcoesAux = JSON.parse(JSON.stringify(opcoes));

    if (
      opcoesAux[indexOpcaoSelected].resultados_correlacionados.filter(
        (resultado) => resultado.id === resultadoId
      ).length > 0
    ) {
      const index = opcoesAux[indexOpcaoSelected].resultados_correlacionados
        .map((resultado) => resultado.id)
        .indexOf(resultadoId);

      opcoesAux[indexOpcaoSelected].resultados_correlacionados.splice(index, 1);
    } else {
      opcoesAux[indexOpcaoSelected].resultados_correlacionados.push({
        id: resultadoId,
      });
    }

    setFieldValue(`opcoes`, opcoesAux);
  };

  const resultadoIsActive = (resultadoId) => {
    if (!opcaoSelected) return;

    const indexOpcaoSelected = opcoes
      .map((opcao) => opcao.id)
      .indexOf(opcaoSelected);

    if (
      opcoes[indexOpcaoSelected].resultados_correlacionados.filter(
        (resultado) => resultado.id === resultadoId
      ).length > 0
    ) {
      return true;
    } else return false;
  };

  return (
    <Container>
      <Respostas>
        <h5 className="text-center">Respostas</h5>
        <hr />
        {opcoes.map((opcao, index) => (
          <OpcaoResposta
            key={opcao.id}
            onClick={() => setOpcaoSelected(opcao.id)}
            className={opcaoSelected === opcao.id ? 'active' : ''}
          >
            {opcao.texto || 'Opção  ' + (index + 1)}
            <svg height="32" width="32">
              <circle cx="16" cy="13" r="7" id={`opcaoResposta_${opcao.id}`} />
              Sorry, your browser does not support inline SVG.
            </svg>
            {opcao.resultados_correlacionados.map((resultado) => (
              <Xarrow
                key={`linha_${opcao.id}_${resultado.id}`}
                start={`opcaoResposta_${opcao.id}`} //can be react ref
                end={`opcaoResultado_${resultado.id}`} //or an id
                color="var(--cinza)"
                showHead={false}
                strokeWidth={3}
              />
            ))}
          </OpcaoResposta>
        ))}
      </Respostas>

      <div>
        <p className="text-nowrap mb-0 p-4" style={{ fontSize: '1rem' }}>
          Correlacionado a <FaArrowRight color="var(--cinza)" />
        </p>
      </div>

      <Resultados>
        <h5 className="text-center">Resultados</h5>
        <hr />
        {resultados.map((resultado, index) => (
          <OpcaoResultado
            key={resultado.id}
            onClick={() => handleResultado(resultado.id)}
            className={resultadoIsActive(resultado.id) ? 'active' : ''}
          >
            <span
            // dangerouslySetInnerHTML={{
            //   __html: resultado.resultado.replace(/<[^>]+>/g, ''),
            // }}
            >
              {resultado.nome || 'resultado ' + (index + 1)}
            </span>
            <svg height="32" width="32">
              <circle
                cx="16"
                cy="13"
                r="7"
                id={`opcaoResultado_${resultado.id}`}
              />
              Sorry, your browser does not support inline SVG.
            </svg>
          </OpcaoResultado>
        ))}
      </Resultados>
    </Container>
  );
}

export default ResultadosCorrelacionados;
