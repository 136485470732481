import { setQuestoesEditorFluxo } from '../store/editorFluxosSlice';
import { addPergunta, setQuestao } from '../store/questaoSlice';
import { addQuestao } from '../store/quizSlice';
import apiQuizClass from './apiQuizClass';

export const getQuestoes = () => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(`/questoes`);
      dispatch(setQuestoesEditorFluxo(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getQuestao = (questaoId) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.get(`/questoes/${questaoId}`);
      dispatch(setQuestao(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const postQuestao = (quiz_id, tipo) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.post(`/questoes`, {
        quiz_id,
        tipo,
      });
      dispatch(addQuestao(response.data));

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const postQuestaoMatrizPerguntas = (questaoId) => {
  return async (dispatch) => {
    try {
      const response = await apiQuizClass.post(
        `/questoes/${questaoId}/matriz-perguntas`
      );
      dispatch(addPergunta(response.data));

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

export const deleteQuestaoMatrizPerguntas = async (
  questaoId,
  perguntaMatrizId
) => {
  try {
    const response = await apiQuizClass.delete(
      `/questoes/${questaoId}/matriz-perguntas/${perguntaMatrizId}`
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const putQuestao = async (questaoId, data) => {
  try {
    const response = await apiQuizClass.put(`/questoes/${questaoId}`, data);

    return response.data;
  } catch (error) {
    console.error(error);
    throw 'Erro ao salvar.';
  }
};

export const deleteQuestao = async (questaoId, quizId) => {
  try {
    const response = await apiQuizClass.delete(
      `/questoes/${questaoId}?quiz_id=${quizId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
