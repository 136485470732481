import { Field, FieldArray, Formik } from 'formik';
import React from 'react';
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle, FaPlus, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { postCampoPersonalizado } from '../../../services/apiCamposPersonalizados';
import { addCampoPersonalizado } from '../../../store/configuracaoSlice';
import {
  selectShowModalAdicionarCampoPersonalizado,
  toggleShowModalAdicionarCampoPersonalizado,
} from '../../../store/showSlice';
import { selectNaturezaQuiz } from '../../../store/quizSlice';

function AdicionarCampoPersonalizado() {
  const dispatch = useDispatch();
  const show = useSelector(selectShowModalAdicionarCampoPersonalizado);
  const { campos_personalizados } = useSelector((state) => state.configuracao);
  const initialValues = {
    nome: '',
    label: '',
    tipo: '',
    campo_correspondente: '',
    fonte_externa: false,
    endpoint_fonte_externa: '',
    depende_outro_campo: false,
    campo_dependente: '',
    opcoes: [],
  };
  const naturezaQuiz = useSelector(selectNaturezaQuiz);

  const handleSubmit = (values, { setSubmitting }) => {
    toast.promise(
      postCampoPersonalizado(values).then((data) => {
        dispatch(addCampoPersonalizado(data.campo));
        setSubmitting(false);
        dispatch(toggleShowModalAdicionarCampoPersonalizado());
      }),
      {
        pending: 'Salvando...',
        success: 'Salvo com sucesso!',
        error: {
          render({ data }) {
            setSubmitting(false);
            console.log(data.response.data.message);
            return (
              data.response.data.message || 'Erro ao salvar. Tente novamente'
            );
          },
        },
      }
    );
  };

  const handleAddOpcaoCampo = (setFieldValue, values) => {
    let newOpcoes = [...values.opcoes];
    newOpcoes.push({ opcoes: '' });

    setFieldValue('opcoes', newOpcoes);
  };

  // const handleRemoveOpcaoCampo = (setFieldValue, values, index) => {
  //   let newOpcoes = [...values.opcoes];
  //   newOpcoes.splice(index, 1);

  //   setFieldValue('opcoes', newOpcoes);
  // };

  const handleChangeValueOpcao = (setFieldValue, values, index, e) => {
    let newOpcoes = [...values.opcoes];
    newOpcoes[index].opcoes = e.target.value;

    setFieldValue('opcoes', newOpcoes);
  };

  return (
    <Modal
      show={show}
      onHide={() => dispatch(toggleShowModalAdicionarCampoPersonalizado())}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Novo campo personalizado</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Nome do campo</Form.Label>
                    <Field
                      className="form-control form-default"
                      name="nome"
                      placeholder="Exemplo: Nome"
                      required
                    />
                  </Form.Group>
                </div>

                <div className="col-12 col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Enunciado do campo</Form.Label>
                    <Field
                      className="form-control form-default"
                      name="label"
                      placeholder="Exemplo: Qual seu nome?"
                      required
                    />
                  </Form.Group>
                </div>

                <div className="col-12 col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Tipo do campo</Form.Label>
                    <Field
                      as="select"
                      className="form-control form-default form-select"
                      name="tipo"
                      required
                      disabled={!!values.campo_correspondente}
                    >
                      <option select="true" disabled value="">
                        Escolha uma opção
                      </option>
                      <option value="EMAIL_INPUT">Campo aberto - Email</option>
                      <option value="NUMBER_INPUT">
                        Campo aberto - Número
                      </option>
                      <option value="DATA_INPUT">
                        Campo aberto - Data
                      </option>
                      <option value="PHONE_INPUT">
                        Campo aberto - Telefone
                      </option>
                      <option value="TEXT_INPUT">Campo aberto - Texto</option>
                      <option value="TEXT_AREA">
                        Campo aberto - Texto grande
                      </option>
                      <option value="URL_INPUT">Campo aberto - URL</option>
                      <option value="RADIO_BUTTON">
                        Campo de seleção - Escolha única
                      </option>
                      <option value="COMBO_BOX">
                        Campo de seleção - Caixa de seleção
                      </option>
                      <option value="MULTIPLE_CHOICE">
                        Campo de seleção - Escolha múltipla
                      </option>
                      <option value="CHECK_BOX">
                        Campo de seleção - Verificação
                      </option>
                      <option value="HIDDEN_INPUT">
                        Campo escondido - Texto
                      </option>
                    </Field>
                  </Form.Group>
                </div>

                <div className="col-12 col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Campo corresponde a informação (opcional):
                    </Form.Label>
                    <Field
                      as="select"
                      className="form-control form-default form-select"
                      name="campo_correspondente"
                    >
                      <option select="true" value="">
                        Escolha uma opção
                      </option>
                      <option value="QTD_ACERTOS">
                        Quantidade de acertos no quiz
                      </option>
                      <option value="QTD_QUESTOES">
                        Quantidade de questões do quiz
                      </option>
                      <option value="NOTA">Nota obtida no quiz</option>
                      {naturezaQuiz === 'PERSONALIDADE' && (
                        <option value="NOME_RESULTADO_OBTIDO">
                          Nome resultado obtido (Quiz Personalidade)
                        </option>
                      )}
                    </Field>
                  </Form.Group>
                </div>

                {(values.tipo === 'RADIO_BUTTON' ||
                  values.tipo === 'COMBO_BOX' ||
                  values.tipo === 'MULTIPLE_CHOICE') && (
                  <div className="col-12">
                    <Form.Group className="mb-3">
                      <Form.Label>Opções do campo</Form.Label>
                      <FieldArray
                        name="opcoes"
                        render={(arrayHelpers) => (
                          <div>
                            {values.opcoes.map((opcao, index) => (
                              <div
                                key={index}
                                className="mb-2 d-flex flex-row flex-nowrap gap-2 align-items-center"
                              >
                                <Field
                                  className="form-control"
                                  name={`opcoes.${index}`}
                                  value={opcao.opcoes}
                                  onChange={(e) =>
                                    handleChangeValueOpcao(
                                      setFieldValue,
                                      values,
                                      index,
                                      e
                                    )
                                  }
                                />
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <FaTrash />
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                      />

                      <br />
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        onClick={() =>
                          handleAddOpcaoCampo(setFieldValue, values)
                        }
                      >
                        <FaPlus /> Adicionar opção
                      </button>
                    </Form.Group>
                  </div>
                )}

                <div className="col-12">
                  <Form.Group className="form-check">
                    <Form.Label
                      className="form-check-label"
                      disabled={!!values.campo_correspondente}
                    >
                      <Field
                        className="form-check-input pe-1"
                        type="checkbox"
                        name="fonte_externa"
                        disabled={!!values.campo_correspondente}
                      />
                      Endpoint dados fonte externa
                    </Form.Label>
                  </Form.Group>
                </div>

                {values.fonte_externa && (
                  <>
                    <div className="col-12">
                      <Form.Group className="mb-3">
                        <div className="d-flex align-items-center">
                          <label className="m-0">Endpoint fonte externa</label>

                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>
                                {`
                            Sua api deve retornar um json array seguindo este padrão: 
                            [{
                                "key": "Chave",
                                "value": "Valor"
                            },]
                          `}
                              </Tooltip>
                            }
                          >
                            <span className="btn btn-sm shadow-none">
                              <FaInfoCircle />
                            </span>
                          </OverlayTrigger>
                        </div>
                        <Field
                          className="form-control form-default"
                          name="endpoint_fonte_externa"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-12">
                      <Form.Group className="form-check">
                        <Form.Label className="form-check-label">
                          <Field
                            className="form-check-input pe-1"
                            type="checkbox"
                            name="depende_outro_campo"
                          />
                          Depende de outro campo
                        </Form.Label>
                      </Form.Group>
                    </div>

                    {values.depende_outro_campo && (
                      <div className="col-12">
                        <Form.Group className="mb-3">
                          <div className="d-flex align-items-center">
                            <label className="m-0">Campo dependente</label>

                            <OverlayTrigger
                              placement={'top'}
                              overlay={
                                <Tooltip>
                                  {`Na url da sua api você deve informar onde a key do campo depente deve ir, colocando {value}. Exemplo: https://quizclass.com.br/consulta-campo-dependente/{value}`}
                                </Tooltip>
                              }
                            >
                              <span className="btn btn-sm shadow-none">
                                <FaInfoCircle />
                              </span>
                            </OverlayTrigger>
                          </div>
                          <Field
                            as="select"
                            className="form-control form-default form-select"
                            name="campo_dependente"
                          >
                            <option select="true" value="" disabled>
                              Selecione uma das opções
                            </option>

                            {campos_personalizados.map((campo) => (
                              <option value={campo.id} key={campo.id}>
                                {campo.nome}
                              </option>
                            ))}
                          </Field>
                        </Form.Group>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() =>
                  dispatch(toggleShowModalAdicionarCampoPersonalizado())
                }
              >
                Fechar
              </Button>
              <button
                className="btn btn-default shadow-default ps-4 pe-4"
                type="submit"
                disabled={isSubmitting}
              >
                Salvar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default AdicionarCampoPersonalizado;
