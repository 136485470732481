import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'formDadosUsuario',
  initialState: {
    campos_personalizados: [],
    campos_personalizados_selecionados: [],
    obter_dados_respondente: true,
    obter_cidade: true,
    obter_cnpj: false,
    obter_cpf: false,
    obter_email: true,
    obter_empresa: false,
    obter_nome: true,
    obter_telefone: true,
    ordem_campos: [],
    quando_obter_dados: 'INICIO',
  },
  reducers: {
    setConfiguracaoForm(state, { payload }) {
      return payload;
    },
    removeForm(state) {
      return { ...state, obter_dados_respondente: false };
    },
    setObterDadosRespondente(state, { payload: obter_dados_respondente }) {
      return { ...state, obter_dados_respondente };
    },
  },
});

export const { setConfiguracaoForm, removeForm } = slice.actions;

export default slice.reducer;
