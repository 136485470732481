import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { Form as FormBootstrap } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { ContainerEnunciado, ContainerForm } from './../styles';
import {
  selectIdQuiz,
  selectQuizFacebookPixelId,
  setQuizFacebookPixelId,
} from './../../../store/quizSlice';
import { useDispatch, useSelector } from 'react-redux';
import { putQuizIntegracoes } from './../../../services/quizService';
import { toast } from 'react-toastify';

function FacebookPixel() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const idQuiz = useSelector(selectIdQuiz);
  const facebook_pixel_id = useSelector(selectQuizFacebookPixelId);

  return (
    <>
      <Link to={-1}>voltar</Link>
      <br />
      <br />
      <ContainerEnunciado className="d-flex align-items-center gap-1">
        <img
          className="m-0"
          src="https://quizclassbr.s3.sa-east-1.amazonaws.com/assets/images/editor/integracao/fb_pixel.webp"
          alt="Facebook Pixel icon"
        />
        <h2>Configurar integração com Facebook Pixel Id</h2>
      </ContainerEnunciado>
      <ContainerForm>
        <Formik
          initialValues={{
            facebook_pixel_id,
          }}
          onSubmit={async (values) => {
            toast.promise(
              putQuizIntegracoes(idQuiz, values).then(() => {
                dispatch(setQuizFacebookPixelId(values.facebook_pixel_id));
                navigate(`/app/quizzes/${idQuiz}/integracoes`);
              }),
              {
                pending: 'Salvando...',
                success: 'Salvo com sucesso!',
                error: 'Erro ao salvar. Tente novamente',
              }
            );
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormBootstrap.Group
                className="mb-3 mt-4"
                controlId="formBasicEmail"
              >
                <FormBootstrap.Label>Facebook Pixel Id</FormBootstrap.Label>
                <Field
                  name="facebook_pixel_id"
                  className="form-default text-muted form-control"
                />
              </FormBootstrap.Group>

              <button
                type="submit"
                className="btn btn-default shadow-default"
                disabled={isSubmitting}
              >
                Salvar
              </button>
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </>
  );
}

export default FacebookPixel;
